import type { FC, ReactNode } from 'react';
import { createContext } from 'react';
import { Issuer } from 'utils/auth';
import { HmacIssuerContextProvider, SABER_HMAC_HEADERS } from './issuer/hmac-context';
import { JwtIssuerContextProvider } from './issuer/jwt-context';

const DEFAULT = Issuer.HMAC

export const AuthContext = createContext<Issuer>(DEFAULT);

type AuthContextProviderProps = {
    init: any,
    clientId?: string,
    children: ReactNode,
}

export const AuthContextProvider: FC<AuthContextProviderProps> = (props) => {

    const { children, init, clientId } = props
    const hmacHeaders = window.sessionStorage.getItem(SABER_HMAC_HEADERS)

    const issuer = (init || hmacHeaders) ? Issuer.HMAC : Issuer.JWT

    let issuerContext: ReactNode
    switch (issuer) {
        case Issuer.HMAC:
            issuerContext = (<HmacIssuerContextProvider init={init}>{children}</HmacIssuerContextProvider>)
            break;
        case Issuer.JWT:
            issuerContext = (<JwtIssuerContextProvider clientId={clientId ?? ""}>{children}</JwtIssuerContextProvider>)
            break;
    }

    return (
        <AuthContext.Provider value={issuer} >{issuerContext}</AuthContext.Provider>
    )
};
