import { Typography } from "@mui/material";
import colors from "../../../styles/colors";
import React from "react";

const MHeading = ({ heading, subHeading }) => {
  return (
    <div id="Heading" style={{ marginBottom: 10 }}>
      <Typography
        style={{
          display: "flex",
          justifyContent: "flex-start",
          fontSize: "18px",
          fontWeight: 600,
          color: colors.Dark.title,
        }}
      >
        {heading}
      </Typography>
      {subHeading && (
        <Typography variant="caption" style={{ color: colors?.Grey?.title }}>
          {subHeading}
        </Typography>
      )}
    </div>
  );
};

export default MHeading;
