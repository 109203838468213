import React, { useState, useEffect } from "react";

const Timer = ({ timeInSeconds, onEnd, Reset }) => {
  const [seconds, setSeconds] = useState(timeInSeconds);

  useEffect(() => {
    setSeconds(timeInSeconds);
  }, [Reset]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((lastTimerCount) => {
        if (lastTimerCount === 0) {
          onEnd();
          clearInterval(interval);
          return 0;
        } else {
          return lastTimerCount - 1;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const displaySeconds =
    Math.floor(seconds / 10) === 0 ? `0${seconds}` : `${seconds}`;
  return <div className="timer">00:{displaySeconds}</div>;
};

export default Timer;
