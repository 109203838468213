import LoadingScreen from "components/shared/LoadingScreen";
import KycDetails from "pages/kyc";
import { KycWrapper } from "pages/kyc/KycWrapper";
import { FC, ReactNode, useCallback } from "react";
import { Flow } from "types/flow";
import { KycRequirementForFeature, useConfig } from "./ConfigContext";
import { useUserContext } from "./UserContext";
import { AuthConsumer } from "./auth/use-auth";
import { EventLevel, sendEvent } from "utils/events/handler";

export type KycCheckerProps = {
    flow: Flow
    children: ReactNode
    presetKycCountry?: string
    fixedPresetKycCountry: boolean
    kycRequirements?: KycRequirementForFeature
    externalDigilocker?: boolean
}

export const KycChecker: FC<KycCheckerProps> = ({
    children,
    flow,
    presetKycCountry,
    fixedPresetKycCountry,
    kycRequirements,
    externalDigilocker
}) => {
    const { user, fetching, refreshUser } = useUserContext()
    const config = useConfig().config

    const onStatusChange = useCallback((status: string) => {
        refreshUser()
        sendEvent("KYC_STATUS", EventLevel.INFO, { status });
    }, [])

    kycRequirements = kycRequirements ?? config?.kyc_requirements?.[flow]

    if (!user || !config || fetching) {
        return <LoadingScreen />
    }

    const isAllowedToTransact = user.isKycVerified() && user.isAllowedToTransact(
        kycRequirements?.phone,
        kycRequirements?.email,
        kycRequirements?.bank_account
    )

    if (!isAllowedToTransact) {
        return (
            <AuthConsumer>
                {config.features?.use_old_kyc ?
                    <KycDetails
                        onSuccess={() => {
                            refreshUser()
                        }
                        }
                        onException={() => {
                            window.location.reload();
                        }}
                        exceptionCTA={"Try again"}
                        stepsEnabled={false}
                        ocrEnabled={config.ocr_enabled}
                    />
                    :
                    <KycWrapper
                        presetKycCountry={presetKycCountry}
                        fixedPresetKycCountry={fixedPresetKycCountry}
                        user={user}
                        onStatusChange={onStatusChange}
                        onCompleted={() => { }}
                        externalDigilocker={externalDigilocker}
                    />
                }
            </AuthConsumer>
        )
    }

    return children
}