import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

export default function MToast({ toast }) {
  const defaultWidth = 414;
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var Android = /Android/.test(navigator.userAgent);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isVisible, setIsVisible] = React.useState(false);

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const toastVisibility = async () => {
    await timeout(500);
    setIsVisible(true);
    await timeout(2500);
    setIsVisible(false);
  };
  useEffect(() => {
    toastVisibility();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div
        className={`transition-all duration-1000 p-4 z-10 rounded-xl absolute w-full cursor-pointer bg-white ${
          isVisible ? "translate-y-full" : "translate-y-0"
        }`}
        style={{
          justifyContent: "flex-start",
          width:
            iOS || Android
              ? window.innerWidth
              : width > defaultWidth
              ? "400px"
              : width,
          display: "flex",
          background: "transparent",
          overflow: "hidden",
          flexDirection: "column",
          marginTop: -60,
          padding: "0px 30px 30px 30px",
        }}
      >
        <Alert
          style={{
            background: "#000000",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          sx={{ color: "#FFFFFF" }}
          severity={toast.type}
        >
          {toast.message}
        </Alert>
      </div>
    </div>
  );
}
