import React from "react";
import { Link, CircularProgress } from "@mui/material";
import colors from "styles/colors";
import { useThemeHook } from "styles/themeMudrex";
const MLink = ({
  text,
  onClick,
  bold = true,
  disabled = false,
  loading = false,
  leading = null,
  direction = "center",
  fontSize = 14,
}) => {
  const [theme] = useThemeHook();
  return (
    <Link
      style={{
        marginTop: 5,
        flexDirection: "row",
        flex: 1,
        display: "flex",
        justifyContent: direction,
        fontSize: fontSize,
        cursor: "pointer",
        color: disabled && colors?.Grey.title,
        alignItems: "center",
        ...theme.gradientTextMain,
      }}
      onClick={disabled ? () => {} : onClick}
      underline="none"
    >
      {loading && <CircularProgress size={15} sx={{ marginRight: 1 }} />}
      {leading && leading}
      {bold ? (
        <strong style={{ marginLeft: leading ? 4 : 0 }}>{text}</strong>
      ) : (
        <div style={{ marginLeft: leading ? 4 : 0 }}>{text}</div>
      )}
    </Link>
  );
};

export default MLink;
