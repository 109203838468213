import React, { useEffect, useState } from "react";
import EmailVerification from "components/kyc/EmailVerification";
import { sendEmailOtp, verifyEmailOtp } from "api/kycHooks";

const EmailVerificationContainer = ({ onStepCompleted }) => {
  const [email, setEmail] = useState(null);
  const [isBottomsheetVisible, setIsBottomsheetVisible] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bottomsheetLoading, setBottomsheetLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [otpSuccess, setOtpSucces] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const checkValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    setIsValidEmail(checkValidEmail(email));
  }, [email]);

  const onSubmitEmail = async () => {
    setLoading(true);
    try {
      const validation = checkValidEmail(email);
      if (!validation) {
        setError("You have entered an invalid email address!");
        throw "Invalid Email";
      }
      const result = await sendEmailOtp(email);
      if (result?.success) {
        setError(null);
        setIsBottomsheetVisible(true);
      } else {
        setError(result?.error);
      }
    } catch (e) {}
    setLoading(false);
  };

  const onSubmitEmailOtp = async () => {
    setBottomsheetLoading(true);
    try {
      const result = await verifyEmailOtp(email, otp);
      if (result?.success) {
        setOtpError(null);
        setOtpSucces("Email verified successfully!");
        onStepCompleted();
      } else {
        setOtpSucces(null);
        setOtpError(result?.error);
      }
    } catch (e) {}
    setBottomsheetLoading(false);
  };

  const onResendOtp = async () => {
    setResendLoading(true);
    setOtpError(null);
    setOtp("");
    setOtpSucces(null);
    try {
      const result = await sendEmailOtp(email);
      if (result?.success) {
        setOtpSucces("Resent successfully!");
        setOtpError(null);
      } else {
        setOtpSucces(null);
        setOtpError(result?.error);
      }
    } catch (e) {}
    setResendLoading(false);
    setOtpTimer(true);
  };

  const onReenterEmail = async () => {
    setEmail("");
    setOtp("");
    setBottomsheetLoading(false);
    setLoading(false);
    setIsBottomsheetVisible(false);
    setOtpSucces(null);
    setOtpError(null);
  };

  return (
    <EmailVerification
      email={email}
      setEmail={setEmail}
      isBottomsheetVisible={isBottomsheetVisible}
      setIsBottomsheetVisible={setIsBottomsheetVisible}
      onSubmitEmail={onSubmitEmail}
      error={error}
      setError={setError}
      loading={loading}
      bottomsheetLoading={bottomsheetLoading}
      otp={otp}
      setOtp={setOtp}
      onSubmitEmailOtp={onSubmitEmailOtp}
      onReenterEmail={onReenterEmail}
      onResendOtp={onResendOtp}
      otpError={otpError}
      otpSuccess={otpSuccess}
      setOtpError={setOtpError}
      setOtpSucces={setOtpSucces}
      resendLoading={resendLoading}
      otpTimer={otpTimer}
      setOtpTimer={setOtpTimer}
      isValidEmail={isValidEmail}
    />
  );
};

export default EmailVerificationContainer;
