import { WindowEventHandler } from "./windowEventHandler";

export enum EventLevel {
  INFO = "INFO",
  ERROR = "ERROR",
  // WARNING
  // DEBUG
}

export enum EventReceiver {
  WINDOW = "WINDOW"
}

interface EventHandler {
  SendEvent: (event: Event) => void
}

const EventReceiverMap: Record<EventReceiver, EventHandler> = {
  "WINDOW": WindowEventHandler
};

export type Event = {
  name: string,
  level: EventLevel,
  data: any,
  internalData: any
}

export function sendEvent(name: string, level: EventLevel, data: any = {}, internalData: any = {}, ...receivers: EventReceiver[]) {

  if (receivers.length == 0) {
    receivers = Object.keys(EventReceiver).map(str => EventReceiver[str as keyof typeof EventReceiver])
  }

  for (var r of receivers) {
    EventReceiverMap[r].SendEvent({ name, level, data, internalData })
  }
}