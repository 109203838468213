import { useConfig } from "context/ConfigContext";
import { FC, useEffect, useRef } from "react";
import { EventLevel, sendEvent } from "utils/events/handler";
import { Platform, getPlatform } from "utils/platforms";

export type AsyncLinkHandlerProps = {
    url: string
    postTrigger?: () => void
}

// Hacky fix for opening url from an async call in ios
// https://stackoverflow.com/a/63170977

export const AsyncLinkHandler: FC<AsyncLinkHandlerProps> = (props) => {
    const isIos = getPlatform() == Platform.IOS
    const { url, postTrigger } = props;
    const linkRef = useRef<HTMLAnchorElement>(null)
    const client_side_handling = useConfig().config?.features?.payment_method?.intent?.client_side_handling

    useEffect(() => {
        if (url != "") {
            sendEvent("UPI_INTENT", EventLevel.INFO, { url })

            if (isIos && !client_side_handling?.ios) {
                const timer = setTimeout(() => {
                    linkRef.current?.click();
                    postTrigger?.()
                }, 300);

                return () => clearTimeout(timer);
            } else if (!client_side_handling?.android) {
                window.open(url)?.focus()
                postTrigger?.()
            }
        }
    }, [url])

    if (!isIos) {
        return (<></>)
    }

    return (
        <a href={url} ref={linkRef} />
    );
};