export const userServicesURL = "user_services/v1/";
export const userServicesURLV2 = "user_services/v2/";
export const walletCaasURL = "v1/wallet/";
export const userCaasURL = "v1/user/";
export const walletServicesURL = "wallet_services/";
export const walletServicesURLV2 = "wallet-services/v2/";
export const walletV2CaasURL = "v2/wallet/";
export const kycServiceURLV2 = "kyc-services/v1/";

export const kycStatusToStep = {
  PHONE_EMAIL_UNVERIFIED: 0,
  INITIATED: 2,
  UNINITIATED: 2,
  REJECTED: 2, //sumsub
  KICKED: 2,
  PENDING: 3, //sumsub
  PAN_VERIFIED: 3,
  MIN_KYC_DONE: 4,
  AADHAR_VERIFIED: 5,
  APPROVED: 6, //common for sumsub and hv
};

export const ONRAMP_HOME = "https://app.saber.money/onramp?client_id=18e963ea-39fd-4a1b-b1e6-decbfe791d31"

export const PAYMENT_METHODS = [
  "upi_transfer",
  "gpay_transfer",
  "phonepe_transfer",
  "paytm_transfer",
  "gpay_intent",
  "paytm_intent",
  "phonepe_intent",
  "other_upi_intent",
  "bank_transfer",
  "sepa_transfer",
  "van_transfer",
  "card_transfer"
]