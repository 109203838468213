import { GenericAbortSignal } from "axios";
import axios from "../../utils/axiosInstance";

export async function networkCallWrapper<P, D>(
    url: string,
    method: string,
    service: string,
    params: P,
    data: D,
    signal?: GenericAbortSignal,
    headers = {},
    config = {},
): Promise<any> {
    try {
        let requestObject = {
            url: url,
            method: method,
            service: service,
            headers: headers,
            params: {},
            data: {},
            signal: signal,
            ...config,
        };

        if (params) {
            requestObject.params = params;
        }

        if (data) {
            requestObject.data = data;
        }

        const request = await axios(requestObject);

        if (request?.status === 204) {
            return {
                success: true,
            };
        }
        return request.data;
    } catch (err: any) {
        console.log("networkError=>", err)
        if (err?.response?.status >= 500) {
            return {
                success: false,
                errors: [
                    {
                        code: 500,
                        text: "Something went wrong! Please try again after some time.",
                    },
                ],
            };
        }
        return err?.response?.data;
    }
};

export default networkCallWrapper;
