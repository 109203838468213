import AuthProvider, { LoginCompleteData, LoginInitData } from 'api/auth';
import { Result, Success } from 'hooks/networking/response';
import { FC, ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import axios from "../../../utils/axiosInstance";
import { State as AuthState, Authenticated, Unauthenticated } from '../auth-context';

const SABER_ACCESS_TOKEN = "saber_token"

export type State = AuthState | SignInInit

export type AuthContextType = {
    state: State
    headers: Object
    initSignIn: (phone: string) => Promise<Result<LoginInitData>>
    submitOtp: (otp: string) => Promise<Result<LoginCompleteData>>
    resendOtp: () => Promise<Result<LoginInitData>>
    reset: () => void
    clientId?: string
}

export const JwtIssuerContext = createContext<AuthContextType | undefined>(undefined);

type JwtIssuerContextProviderProps = {
    children: ReactNode
    clientId: string
}

export const initialState: State = new Unauthenticated()

export const JwtIssuerContextProvider: FC<JwtIssuerContextProviderProps> = ({ children, clientId }) => {

    const [accessToken, setAccessToken] = useState("")
    const [state, setState] = useState(initialState);
    const headers = {
        "X-Authentication": accessToken
    }

    useEffect(() => {
        const storedToken = window.sessionStorage.getItem(SABER_ACCESS_TOKEN)
        console.log("useEffect ~ storedToken=>", storedToken)
        if (storedToken && storedToken != "") {
            setAccessToken(storedToken)
        }
    }, [])

    useEffect(() => {
        console.log("useEffect ~ accessToken=>", accessToken)
        if (accessToken != "") {
            const requestInterceptor = axios.interceptors.request.use(async (config) => {
                config.headers["X-Authentication"] = accessToken;
                return config
            });

            setState(new Authenticated())

            return () => {
                axios.interceptors.request.eject(requestInterceptor);
            };
        }

    }, [accessToken]);


    useEffect(() => {
        AuthProvider.init(clientId)
    }, [])

    const initSignIn = useCallback(async (phone: string): Promise<Result<LoginInitData>> => {
        if (!(state instanceof Unauthenticated)) {
            // throw new Error("Invalid State");
        }
        const result = await AuthProvider.login(phone)
        if (result instanceof Success) {
            const data = (result as Success<LoginInitData>).data
            setState(new SignInInit(phone, data.challengeId, new Date(data.nextCodeAvailableAt), data.isNewUser))
        }
        return result

    }, [state])

    const submitOtp = useCallback(async (otp: string): Promise<Result<LoginCompleteData>> => {
        console.log("submitOtp ~ state=>", state)
        if (!(state instanceof SignInInit)) {
            // throw new Error("Invalid State");
            return Promise.reject()
        }

        const { phone, challengeId, isNewUser } = state as SignInInit

        const result = await AuthProvider.submitOtp(phone, challengeId, otp, isNewUser)
        if (result instanceof Success) {
            const data = (result as Success<LoginCompleteData>).data
            sessionStorage.setItem(SABER_ACCESS_TOKEN, data.accessToken as string);
            setAccessToken(data.accessToken)
        }
        return result


    }, [state])

    const resendOtp = useCallback(async (): Promise<Result<LoginInitData>> => {
        if (!(state instanceof SignInInit)) {
            // throw new Error("Invalid State");
            return Promise.reject()
        }

        const { phone, challengeId, isNewUser } = state as SignInInit

        const result = await AuthProvider.resendOtp(phone, challengeId, isNewUser)
        if (result instanceof Success) {
            const data = (result as Success<LoginInitData>).data
            setState((prev: SignInInit) => {
                return new SignInInit(prev.phone, prev.challengeId, new Date(data.nextCodeAvailableAt), prev.isNewUser)
            })
        }
        return result
    }, [state])

    const reset = useCallback(() => {
        setState(new Unauthenticated())
    }, [])

    const contextData = { state, initSignIn, submitOtp, resendOtp, headers, reset, clientId }


    return (
        <JwtIssuerContext.Provider value={contextData} > {children} </JwtIssuerContext.Provider>
    )
};

export class SignInInit {
    phone: string
    challengeId: string
    resendOtpTime: Date
    isNewUser: boolean

    constructor(
        phone: string,
        challengeId: string,
        resendOtpTime: Date,
        isNewUser: boolean
    ) {
        this.phone = phone
        this.challengeId = challengeId
        this.resendOtpTime = resendOtpTime
        this.isNewUser = isNewUser
    }
}