
export enum Platform {
    DESKTOP = 'desktop',
    IOS = 'ios',
    ANDROID = 'android',
}

const MOBILE_PLATFORMS = [Platform.IOS, Platform.ANDROID]

export function getPlatform(): Platform {
    if (/iPad|iPhone|iPod/.test(navigator?.userAgent)) {
        return Platform.IOS
    }
    if (/Android/.test(navigator?.userAgent)) {
        return Platform.ANDROID
    }

    return Platform.DESKTOP
}


export function isEnabledForCurrentPlatform(enabledOnPlatforms?: Array<Platform>) {
    if (!enabledOnPlatforms) return true

    const platform = getPlatform()
    return enabledOnPlatforms.includes(platform)
}

export function isPlatformMobile(): Boolean {
    return MOBILE_PLATFORMS.includes(getPlatform())
}
