import { createUseStyles } from "react-jss";
import colors from "styles/colors";

const styles = createUseStyles({
  container: {
    width: "100%",
    height: "60%",
    flex: 1,
    display: "flex",
    overflow: "scroll",
    flexDirection: "column",
  },
  mainContainer: {
    placeItems: "center",
    justifyContent: "center",
    display: "grid",
    margin: "auto",
    backgroundColor: "#F5F5F5",
    textAlign: "left",
  },
  mainBody: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderColor: "#FBFBFB",
  },
  childPadding: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  steps: {
    color: colors.Grey.title,
    marginBottom: 4,
    textAlign: "start",
  },
});

export default styles;
