import { createUseStyles } from "react-jss";
import colors from "styles/colors";
import { FontStyles } from "styles/fonts";

const styles = createUseStyles({
  container: {
    justifyContent: "flex-start",
    flex: 1,
  },
  metricTitle: {
    marginBottom: 10,
    ...FontStyles.subtitle14SemiBold,
  },
  metricValue: {
    ...FontStyles.subtitle12SemiBold,
  },
  heading: {
    ...FontStyles.subtitle16Medium,
  },
  detailsContainer: {
    padding: "0px 10px 20px 10px",
    borderRadius: 12,
  },
  subheading: {
    marginBottom: 4,
  },
  defaultText: {},
  name: {},
  purpleTextContainer: {
    marginVertical: 32,
  },
  purpleText: {
    textAlign: "center",
    color: colors.Primary.background,
  },
  text: {
    color: colors.Dark.title,
  },
});

export default styles;
