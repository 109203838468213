export type Result<T> = Success<T> | Failure<T>

export class Success<T>{
    data: T

    constructor(data: T) {
        this.data = data
    }
}

export class Failure<T>{
    error?: string

    constructor(error?: string) {
        this.error = error
    }
}