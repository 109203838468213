import MBottomSheet from "components/shared/BottomSheet";
import MButton from "components/shared/MButton";
import UTRTabs from "./UTRTabView";

const FindUTRBottomSheet = ({ isVisible, onClose }) => {
    return (
        <MBottomSheet
            isVisible={isVisible}
            close={onClose}
            title="How to find Transaction ID/UTR"
            sticky={false}
        >
            <>
                <div>
                    <UTRTabs />
                </div>
                <MButton
                    onPress={() => {
                        onClose()
                    }}
                    text={`Got it!`}
                />
            </>
        </MBottomSheet>
    );
};

export default FindUTRBottomSheet;