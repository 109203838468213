import React from "react";
import "./styles.css";

export default function MBottomSheet({
  title,
  children,
  isVisible,
  close,
  sticky = true,
}) {
  const defaultWidth = 414;
  const defaultHeight = 750;
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var Android = /Android/.test(navigator.userAgent);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  const handleChildElementClick = (e) => {
    e.stopPropagation();
    // Do other stuff here
  };

  return (
    <div
      className={`transition-all duration-1000 translate-y-full false p-4 z-10 rounded-xl h-full absolute w-full cursor-pointer bg-white ${
        isVisible ? "translate-y-0" : ""
      }`}
      onClick={() => {
        !sticky && close();
      }}
      style={{
        height:
          iOS || Android
            ? window.innerHeight
            : height > defaultHeight
            ? "600px"
            : height,
        width:
          iOS || Android
            ? window.innerWidth
            : width > defaultWidth
            ? "400px"
            : width,
        display: "flex",
        background: "transparent",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          boxShadow: "rgb(157, 165, 179) 10px 10px 40px 10px",
          borderStyle: "solid",
          borderColor: "#EFEFEF",
          borderRadius: "20px 20px 0px 0px",
          backgroundColor: "white",
          width: "100%",
          borderWidth: "1px",
          padding: 20,
          paddingBottom: 15,
          height: "fit-content",
          alignSelf: "flex-end",
        }}
        onClick={(e) => handleChildElementClick(e)}
      >
        {title && (
          <div style={{ flexDirection: "row", paddingBottom: "20px" }}>
            <strong>{title}</strong>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
