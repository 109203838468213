import axios from "../utils/axiosInstance";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const getNameFromPAN = async (data) => {
  const result = await axios({
    url: "user_kyc/pan/extract_name",
    method: "POST",
    service: "user",
    data,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const updateUserConsentForPan = async (data) => {
  const result = await axios({
    url: "user_kyc/pan/user_consent",
    method: "POST",
    service: "user",
    data,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getCaptcha = async () => {
  const result = await axios({
    url: "user_kyc/aadhar/get_captcha",
    method: "GET",
    service: "user",
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const verifyCaptchaAndGetOTP = async (data) => {
  const result = await axios({
    url: "user_kyc/aadhar/verify_captcha_for_otp",
    method: "POST",
    service: "user",
    data,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const verifyAadharOtp = async (data) => {
  const result = await axios({
    url: "user_kyc/aadhar/verify_otp_for_verification",
    method: "POST",
    service: "user",
    data,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const uploadOCRData = async (data) => {
  const result = await axios({
    url: "user_kyc/aadhar/ocr_details",
    method: "POST",
    service: "user",
    data,
    headers,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const uploadLiveImage = async (data) => {
  const result = await axios({
    url: "user_kyc/liveliness/live_image",
    method: "POST",
    service: "user",
    data,
    headers,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const addAccountDeprecated = async (data) => {
  const result = await axios({
    url: "user_kyc/bank_account",
    method: "POST",
    service: "user",
    data: data,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const addAccount = async (data) => {
  const result = await axios({
    url: "user_kyc/v2/bank_account",
    method: "POST",
    service: "user",
    data: data,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getAccountStatus = async () => {
  const result = await axios({
    url: "user_kyc/bank_status",
    method: "GET",
    service: "user",
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const deleteAccount = async (data) => {
  const result = await axios({
    url: "user_kyc/bank_account",
    method: "POST",
    service: "user",
    data,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getUserKyc = async () => {
  const result = await axios({
    url: "kyc/for_sdk",
    method: "GET",
    service: "userv2",
    params: {
      source: "APP",
    },
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getUser = async () => {

  const result = await axios({
    url: `client_user`,
    method: "GET",
    service: "userCaas",
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const sendPhoneOtp = async (phone) => {
  try {
    const data = new FormData();
    data.append("phone", `+91${phone}`);
    const result = await axios({
      url: "client/send_otp",
      method: "POST",
      service: "user",
      data: data,
      headers: headers,
    });

    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const verifyPhoneOtp = async (phone, otp) => {
  try {
    const data = new FormData();
    data.append("phone", `+91${phone}`);
    data.append("otp", otp);
    const result = await axios({
      url: "verify_phone_otp",
      method: "POST",
      service: "user",
      data: data,
      headers: headers,
    });

    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const sendEmailOtp = async (email) => {
  try {
    const data = { email: email };
    const result = await axios({
      url: "send_email_otp",
      method: "POST",
      service: "user",
      data: data,
    });
    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return { success: false, error: result?.data?.errors?.[0] };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const verifyEmailOtp = async (email, otp) => {
  try {
    const data = { email: email, otp: otp };
    const result = await axios({
      url: "verify_email_otp",
      method: "POST",
      service: "user",
      data: data,
    });
    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return { success: false, error: result?.data?.errors?.[0] };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const getConfig = async (platform, client_version) => {
  try {
    const result = await axios({
      url: "sdk/config",
      method: "GET",
      service: "user",
      params: {
        platform,
        version: client_version,
      },
    });
    if (result?.data?.success) {
      return { success: true, data: result?.data?.data };
    } else {
      return { success: false, data: "" };
    }
  } catch (e) {
    return {
      success: false,
      error: "",
    };
  }
};

export const getHVToken = async () => {
  try {
    const result = await axios({
      url: "client_user/get_kyc_token",
      method: "GET",
      service: "userCaas",
    });
    if (result?.data?.success) {
      return { success: true, data: result?.data?.data };
    } else {
      return { success: false, data: "" };
    }
  } catch (e) {
    return {
      success: false,
      error: "",
    };
  }
};
