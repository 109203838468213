import LoadingScreen from "components/shared/LoadingScreen";
import { ConfigContext } from "context/ConfigContext";
import { FC, ReactNode, useContext } from "react";
import { PhoneVerificationContainer } from "routes/auth/login";
import { Authenticated } from "./auth-context";
import { AuthContextType as HmacAuthContextType, HmacIssuerContext } from "./issuer/hmac-context";
import { AuthContextType as JwtAuthContextType, JwtIssuerContext } from "./issuer/jwt-context";

type AuthContextType =
  | JwtAuthContextType
  | HmacAuthContextType
  ;



export const useAuth: <T = AuthContextType>() => T = <T = AuthContextType>() => {
  const hmacContext = useContext(HmacIssuerContext)
  const jwtContext = useContext(JwtIssuerContext)

  return (hmacContext ?? jwtContext) as T
};

type AuthConsumerProps = {
  children: ReactNode
  unauthenticatedChildren?: ReactNode
}

export const AuthConsumer: FC<AuthConsumerProps> = ({ children }) => {

  const hmacContext = useContext(HmacIssuerContext)
  const jwtContext = useContext(JwtIssuerContext)

  if (hmacContext !== undefined) {
    if (!(hmacContext.state instanceof Authenticated)) {
      return <LoadingScreen />
    } else {
      return children
    }
  }

  if (jwtContext !== undefined) {
    if (!(jwtContext.state instanceof Authenticated)) {
      return <PhoneVerificationContainer />
    } else {
      return children
    }
  }
  return <></>
}

export const WithAuthentication: FC<AuthConsumerProps> = ({ children, unauthenticatedChildren }) => {
  const auth = useAuth()
  const isAuthenticated = auth.state instanceof Authenticated

  return <ConfigContext.Provider
    // @ts-ignore
    value={{}}
  >
    {isAuthenticated ? children : unauthenticatedChildren ?? <LoadingScreen headerVisible={false} />}
  </ConfigContext.Provider>
}