import StateEnum from "pages/onRamp/states";
import { FC, ReactNode, createContext, useContext } from "react";
import { BackStack, useBackStack } from "utils/backstack";

export const BackStackContext = createContext<BackStack<StateEnum>>({} as BackStack<StateEnum>);

type BackStackContextProviderProps = {
    children: ReactNode,
    flow: string
}

export const BackStackContextProvider: FC<BackStackContextProviderProps> = ({ children, flow }) => {
    const backstack = useBackStack(flow, StateEnum.LOADING)

    return (
        <BackStackContext.Provider value={backstack} > {children} </BackStackContext.Provider>
    )
};

export const useBackStackContext = () => useContext(BackStackContext)