import { AutoClose } from "components/shared/AutoClose";
import { FC, useEffect } from "react";
import { EventLevel, sendEvent } from "utils/events/handler";
import { closeWindow } from "utils/closeWindow";

export type AutoCloseProps = {
    delayInSeconds: number
    redirectUrl: string
}

export const KycAutoClose: FC<AutoCloseProps> = ({ delayInSeconds, redirectUrl }) => {

    useEffect(() => {
        sendEvent("KYC_STATUS", EventLevel.INFO, { status: "COMPLETED" });
    }, [])

    const autoClose = () => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            closeWindow()
        }
    }

    return <AutoClose runner={autoClose} delayInSeconds={delayInSeconds} />
};