import { getConfig } from "api/kycHooks";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Flow } from "types/flow";
import { getPlatform } from "utils/platforms";
import { Authenticated } from "./auth/auth-context";
import { useAuth } from "./auth/use-auth";

export type TransactionStates =
  | "processing"
  | "delayed"
  | "failed"
  | "completed"
  | "onRampCompleted";
export type PaymentMethod = "bank_transfer" | "upi_transfer";
export type ThemeConfig = {
  border: string;
  palette: {
    mode: string;
    primary: {
      main: string;
      text: string;
    };
    secondary: {
      main: string;
      text: string;
    };
  };
  typography: {
    alert: {
      fontSize: number;
      lineHeight: string;
    };
    b1: {
      fontSize: number;
      fontWeight: number;
      lineHeight: string;
    };
    fontFamily: string;
    lineHeight: string;
  };
};

export type OnrampMessage = {
  title: string;
  subtitle: string;
  time: string;
};

export type Features = {
  side_nav: {
    enabled: boolean;
  };
  digilocker: {
    open_external: boolean;
  }
  success_screen: {
    header: {
      disabled: boolean;
    };
    continue_button: {
      disabled: boolean;
    };
  };
  payment_method: {
    allow_intent: boolean
    intent: {
      client_side_handling: {
        ios: boolean
        android: boolean
      }
    }
  }
  withdraw: {
    allowed_networks: string[]
  },
  back_button: {
    reset_on_close: boolean
  },
  use_old_kyc: boolean
};

export type KycRequirements = Record<Flow, KycRequirementForFeature>

export type KycRequirementForFeature = {
  phone: boolean
  email: boolean
  bank_account: boolean
}

export type Config = {
  kyc_requirements: KycRequirements
  branding: string;
  messages: {
    expiry: {
      title: string;
    };
    onramp: Record<TransactionStates, OnrampMessage>;
    support: {
      title: string;
      url: string;
    };
  };
  ocr_enabled: boolean;
  sla: Record<PaymentMethod, number>;
  theme: ThemeConfig;
  features: Features;
};

const defaultConfig = {
  kyc_requirements: {
    onramp: {
      phone: false,
      email: false,
      bank_account: false
    },
    offramp: {
      phone: false,
      email: false,
      bank_account: false
    },
    kyc: {
      phone: false,
      email: false,
      bank_account: false
    }
  },
  branding: "Powered by Mudrex",
  messages: {
    expiry: {
      title: "Your reward is reserved at this price.",
    },
    onramp: {
      completed: {
        subtitle: "You have purchased the reward",
        time: "This may take upto 5 min ",
        title: "Payment successful",
      },
      delayed: {
        subtitle: "It is taking longer than expected",
        time: "This may take upto 5 min ",
        title: "Processing your payment",
      },
      failed: {
        subtitle: "We were not able to find your payment",
        time: "This may take upto 5 min ",
        title: "Payment failed",
      },
      onRampCompleted: {
        subtitle: "You purchase is in progress",
        time: "This may take upto 5 min ",
        title: "We have received your funds",
      },
      processing: {
        subtitle: "We will let you know once completed",
        time: "This may take upto 5 min ",
        title: "Processing your payment...",
      },
    },
    support: {
      title: "Facing problems, get help",
      url: "https://support.mudrex.com/",
    },
  },
  ocr_enabled: false,
  sla: {
    bank_transfer: 3000,
    upi_transfer: 100,
  },
  theme: {
    border: "0",
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
        text: "#FFFFFF",
      },
      secondary: {
        main: "#000000",
        text: "#FFFFFF",
      },
    },
    typography: {
      alert: {
        fontSize: 10,
        lineHeight: "150%",
      },
      b1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "150%",
      },
      fontFamily: "Inter",
      lineHeight: "150%",
    },
  },
  features: {
    side_nav: {
      enabled: false,
    },
    digilocker: {
      open_external: false,           // opens digilocker externally (in browser's new tab) if true
      handle_redirect: true           // Triggers digilocker url if true. Else leaves it to the client to handle the redirect. Only works if open_external is false.
    },
    success_screen: {
      header: {
        disabled: false,
      },
      continue_button: {
        disabled: false,
      },
    },
    payment_method: {
      allow_intent: false,
      intent: {
        client_side_handling: {
          ios: false,
          android: false
        }
      }
    },
    withdraw: {
      allowed_networks: ["MATIC"]
    },
    back_button: {
      reset_on_close: false // Reset backstack on close if true
    },
    use_old_kyc: true,
  },
};

export const ConfigContext = createContext<{ config: Config }>({
  config: defaultConfig,
});

export const useConfig = () => useContext(ConfigContext)

type ConfigContextProviderProps = {
  children: ReactNode,
}

export const ConfigProvider: FC<ConfigContextProviderProps> = (props) => {


  const { children } = props

  const [config, setConfig] = useState<Config>(defaultConfig)
  const { state } = useAuth()
  const isAuthenticated = state instanceof Authenticated

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const getConfigFunc = async () => {
      const result = await getConfig(getPlatform(), window.sessionStorage.getItem("client_version") ?? "0");
      // @ts-ignore
      if (result.success) {
        setConfig(result.data)
      }
    }
    getConfigFunc()
  }, [isAuthenticated])


  return (<ConfigContext.Provider value={{ config }} > {children} </ConfigContext.Provider>)
};