import { getHVToken, getUserKyc } from "api/kycHooks";
import KycSuccess from "components/kyc/KycSuccess";
import StepsScreen from "components/kyc/StepsScreen";
import LoadingScreen from "components/shared/LoadingScreen";
import MStepper from "components/shared/MStepper";
import AadharVerification from "pages/kyc/AadharVerification";
import BankVerification from "pages/kyc/BankVerification";
import LiveImageContainer from "pages/kyc/LiveVerification";
import PanVerification from "pages/kyc/PanVerification";
import PhoneEmailVerification from "pages/kyc/PhoneEmailVerification";
import React, { useEffect, useState } from "react";
import { ExceptionHandler } from "utils/errorHandler";
import { initializeHyververge } from "utils/hyperverge";

const KycDetails = ({
  onSuccess,
  exceptionCTA,
  onException,
  stepsEnabled = true,
  ocrEnabled = false,
}) => {
  useEffect(() => {
    exceptionCTA && ExceptionHandler.setExceptionCTA({ ctaText: exceptionCTA });
    ExceptionHandler.setOnException({ callback: onException });
  });
  const [currentStep, setCurrentStep] = React.useState(-1);
  const [status, setStatus] = useState(null);
  const [currentState, setCurrentState] = useState("LOADING");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [config, setConfig] = useState({});
  const [finalStep, setFinalStep] = useState(null);

  const statusDetermination = (result) => {
    let status = result?.status === "APPROVED" ? "SUCCESS" : "STEPS";
    if (!stepsEnabled && status !== "SUCCESS") {
      status = result?.steps[getInitialStepIndex(result?.steps)].step;
    }
    if (!result?.phone_verified || !result?.email_verified) {
      status = "PHONE_EMAIL_UNVERIFIED";
    }
    return status;
  };

  const fetchAndUpdateKycStatus = async () => {
    try {
      const result = await getUserKyc();
      let status = statusDetermination(result);
      setStatus(result?.status);
      setPhoneVerified(result?.phone_verified);
      setEmailVerified(result?.email_verified);
      setConfig(result?.steps);
      setCurrentState(status);
      setCurrentStep(getInitialStepIndex(result?.steps));
    } catch (err) { }
  };
  const getFinalStepIndex = (obj) => {
    return Number(Math.max(...Object.keys(obj)));
  };
  const getInitialStepIndex = (obj) => {
    return Number(
      Object.keys(obj)
        .sort()
        .find((i) => obj[i]?.is_verified === false)
    );
  };

  useEffect(() => {
    setFinalStep(getFinalStepIndex(config));
  }, [config]);

  const initializeHyperverge = async () => {
    try {
      let config = await getHVToken();
      initializeHyververge(config?.data);
    } catch (err) { }
  };

  useEffect(() => {
    status === "APPROVED" && onSuccess();
  }, [status]);

  useEffect(() => {
    fetchAndUpdateKycStatus();
    initializeHyperverge();
  }, []);

  const updateStep = (step) => {
    switch (step) {
      case "PHONE_EMAIL_UNVERIFIED":
        var status = "STEPS";
        if (!stepsEnabled) {
          status = config[getInitialStepIndex(config)].step;
          setCurrentStep(getInitialStepIndex(config));
        }
        setCurrentState(status);
        return;
      case "STEPS":
        var status = config[getInitialStepIndex(config)].step;
        setCurrentStep(getInitialStepIndex(config));
        setCurrentState(status);
        return;
      default:
        const thisStep = Number(
          Object.keys(config).find((key) => config[key].step === currentState)
        );
        if (thisStep + 1 > finalStep) {
          setCurrentState("SUCCESS");
        } else {
          var status = config[thisStep + 1].step;
          setCurrentState(status);
          setCurrentStep(thisStep + 1);
        }
    }
  };

  const states = (state) => {
    switch (state) {
      case "LOADING":
        return <LoadingScreen />;
      case "PHONE_EMAIL_UNVERIFIED":
        return (
          <PhoneEmailVerification
            emailVerified={emailVerified}
            phoneVerified={phoneVerified}
            onStepCompleted={() => {
              updateStep("PHONE_EMAIL_UNVERIFIED");
              setPhoneVerified(true);
              setEmailVerified(true);
            }}
          />
        );
      case "STEPS":
        return (
          <StepsScreen
            config={config}
            finalStep={finalStep}
            onStepCompleted={() => {
              updateStep("STEPS");
              setEmailVerified(true);
            }}
          />
        );
      case "PAN":
        return (
          <PanVerification
            onStepCompleted={() => {
              updateStep("PAN");
            }}
          />
        );
      case "BANK":
        return (
          <BankVerification
            onStepCompleted={() => {
              updateStep("BANK");
            }}
            isOldFlow={true}
          />
        );
      case "AADHAR":
        return (
          <AadharVerification
            onStepCompleted={() => {
              updateStep("AADHAR");
            }}
            ocrEnabled={ocrEnabled}
          />
        );
      case "SELFIE":
        return (
          <LiveImageContainer
            onStepCompleted={() => {
              updateStep("SELFIE");
            }}
          />
        );
      case "SUCCESS":
        return <KycSuccess onButtonClick={onSuccess} />;
    }
  };

  return (
    <>
      {status &&
        status !== "APPROVED" &&
        currentState !== "LOADING" &&
        currentState !== "PHONE_EMAIL_UNVERIFIED" &&
        currentState !== "STEPS" &&
        currentState !== "SUCCESS" && (
          <MStepper
            totalSteps={finalStep + 1}
            currentStep={Number(currentStep) + 1}
          />
        )}
      {states(currentState)}
    </>
  );
};

export default KycDetails;
