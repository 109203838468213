import { Event } from "./handler"

const sendEvent = (event: Event) => {
    window.parent.postMessage(
        {
          event: event.name,
          level: event.level,
          params: event.data,
        },
        "*"
      )
}

export const WindowEventHandler = {
    SendEvent: sendEvent
}