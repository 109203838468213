import { useEffect, useState } from 'react';

function useDebounce<T>(value: T | null, delay: number, intermediaryValue: T | null): T | null {
  const [debouncedValue, setDebouncedValue] = useState<T | null>(value);

  useEffect(() => {
    setDebouncedValue(intermediaryValue);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;