import { Expose, Type } from 'class-transformer';

export class Quote {
    @Expose({ name: 'from_currency' })
    fromCurrency: string = '';

    @Expose({ name: 'to_currency' })
    toCurrency: string = '';

    @Expose({ name: 'network' })
    network: string = '';

    @Expose({ name: 'from_amount' })
    fromAmount: number = 0;

    @Expose({ name: 'pre_fee_to_amount' })
    preFeeToAmount: number = 0;

    @Expose({ name: 'to_amount' })
    toAmount: number = 0;

    @Expose({ name: 'base_price' })
    basePrice: number = 0;

    @Expose({ name: 'final_price' })
    finalPrice: number = 0;

    @Expose({ name: 'total_fee' })
    totalFee: number = 0;

    @Expose({ name: 'fee_currency' })
    feeCurrency: string = '';

    @Expose({ name: 'fee_breakup' })
    @Type(() => FeeBreakup)
    feeBreakup: FeeBreakup = new FeeBreakup();

    @Expose({ name: 'min_crypto_amount' })
    minCryptoAmount: number = 0;

    @Expose({ name: 'name' })
    name: string = '';
}

export class FeeBreakup {
    @Expose({ name: 'platform_fee' })
    platformFee: number = 0;

    @Expose({ name: 'network_fee' })
    networkFee: number = 0;

    @Expose({ name: 'client_fee' })
    clientFee: number = 0;

    @Expose({ name: 'discount' })
    discount: number = 0;

    @Expose({ name: 'tax_on_fee' })
    taxOnFee: number = 0;

    @Expose({ name: 'tds' })
    tds: number = 0;
}
