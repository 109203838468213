import { Radio, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import colors from "styles/colors";
import { CryptoNetwork } from 'types/CryptoNetwork';

interface CryptoNetworkItemProps {
    network: CryptoNetwork;
    isSelected: boolean;
    setSelectedNetwork: (currency: CryptoNetwork) => void;
}

const CryptoNetworkItem: React.FC<CryptoNetworkItemProps> = ({ network, isSelected, setSelectedNetwork }) => {

    const theme = useTheme();


    return (
        <div>
            <Stack
                direction="column"
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    cursor: 'pointer'
                }}
                onClick={() => { setSelectedNetwork(network) }}
            >
                <Stack spacing={1} direction="row" sx={{
                    display: "flex",
                    alignItems: 'center',
                }} >
                    {/* <Box sx={{
                        height: 40, width: 40, padding: "8px",
                        borderRadius: "8px",
                        // background: alpha(color, 0.1)
                    }}>
                        <CountryIcon icon={network?.id} size={24} />

                    </Box> */}
                    <Stack sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }} color={colors.Dark}>
                            {network.id}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color={colors.Grey.title}>
                            {network.name}
                        </Typography>
                    </Stack>
                    <Radio
                        size="small"
                        checked={isSelected}
                        value="b"
                        name="radio-buttons"
                        style={{ paddingLeft: 0 }}
                        disableRipple={true}
                    // inputProps={{ "aria-label": "B" }}
                    />
                </Stack>
            </Stack>
        </div>
    );

};

export default CryptoNetworkItem;