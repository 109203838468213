import { createOrUpdateTransaction } from "api/OnRamp/createOrUpdateTxn";
import BaseLayout from "components/shared/BaseLayout";
import { useOnrampContext } from "context/OnRamp";
import { FC, ReactNode, useEffect, useState } from "react";
import { OnrampType } from "types/OnRamp";
import { isPlatformMobile } from "utils/platforms";
import StateEnum from "../states";
import MobileIntent from "./MobileIntent";
import { QrCodeIntent } from "./QrCodeIntent";

type UpiIntentContainerProps = {
    init: any
    selectedCurrency: any
    inputAmount: number
    finalAmountYouGet: number
    paymentMethods: any
    bankId: string
    accountNumber: number
    onSuccess: () => void
    external: boolean
    externalNetwork: string
    walletAddress: string
    orderDetails: () => void
    orderId?: string
    setPaymentDetails: (data: any) => void
    cryptoAmount: number
    setCurrentState: (state: any, isCheckPoint: boolean) => void
    transactionId: string
    selectedPaymentApp: string
    selectedPaymentMethod: string
    priceLock: boolean
}

export const UpiIntentContainer: FC<UpiIntentContainerProps> = (props) => {

    const { cryptoCurrencyState } = useOnrampContext()
    const cryptoCurrency = cryptoCurrencyState.value

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [upiUrl, setUpiUrl] = useState<string | null>(null)

    const handleTransfer = async () => {
        setLoading(true);
        try {
            const buyTransaction = await createOrUpdateTransaction(
                props.external ? OnrampType.ONRAMP_AND_WITHDRAW : OnrampType.ONRAMP,
                props.priceLock,
                props.bankId,
                props.selectedCurrency?.symbol,
                cryptoCurrency?.symbol,
                props.inputAmount,
                props.cryptoAmount,
                props.selectedPaymentMethod,
                props.externalNetwork,
                props.walletAddress,
                undefined,
                undefined,
                undefined,
                props.transactionId
            )

            props.setPaymentDetails(buyTransaction.data);
            setLoading(false);
            setUpiUrl(buyTransaction.data?.payment_intent)

            return buyTransaction?.data?.id;
        } catch (err) {
            setLoading(false);
            setError("Please try again after some time.");
        }
    };

    useEffect(() => {
        handleTransfer();
    }, []);


    let body: ReactNode

    if (isPlatformMobile()) {
        body = <MobileIntent
            inputAmount={props.inputAmount}
            selectedPaymentApp={props.selectedPaymentApp}
            url={upiUrl ?? ""}
            proceedToSuccess={() => props.setCurrentState(StateEnum.SUCCESS_SCREEN, true)}
        />
    } else {
        body = <QrCodeIntent
            inputAmount={props.inputAmount}
            selectedPaymentApp={props.selectedPaymentApp}
            url={upiUrl ?? ""}
            proceedToSuccess={() => props.setCurrentState(StateEnum.SUCCESS_SCREEN, true)}
        />
    }

    return (<BaseLayout
        headerVisible={true}
        brandingVisible={false}
        body={body}
        footer={undefined}
        bottomSheet={undefined} />
    )
}