import { Button, Stack, Typography } from '@mui/material';
import CountryIcon from "assets/svgs/Icomoon";
import CountryPickerBS from 'components/auth/CountryPickerBS';
import ConfirmPhone from "components/kyc/ConfirmPhone";
import BaseLayout from "components/shared/BaseLayout";
import MBottomSheet from "components/shared/BottomSheet";
import KycTextInput from "components/shared/KycTextInput";
import MButton from "components/shared/MButton";
import MHeading from "components/shared/MHeading";
import { useState } from 'react';
import colors from "styles/colors";

const PhoneVerification = ({
  phone,
  setPhone,
  phoneInputError,
  isBottomsheetVisible,
  setIsBottomsheetVisible,
  onSubmitPhone,
  error,
  setError,
  loading,
  bottomsheetLoading,
  otp,
  setOtp,
  onSubmitPhoneOtp,
  onReenterPhone,
  onResendOtp,
  otpError,
  otpSuccess,
  setOtpError,
  setOtpSucces,
  resendLoading,
  otpTimer,
  setOtpTimer,
  selectedCountry,
  countries,
  setCountry,
}) => {

  const [countryPickerOpen, setCountryPickerOpen] = useState(false)
  const showCountryPicker = countries && setCountry
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      forceShowBackButton={true}
      headerVisible={true}
      body={
        <div id="body">
          <div id="body">
            <MHeading
              heading={"Enter your phone"}
              subHeading={"We need your phone number to proceed further"}
            />
            <KycTextInput
              placeholder={"Enter Phone"}
              style={{
                paddingBottom: 20,
                paddingTop: 20,
              }}
              textStyle={{ fontSize: 16, fontWeight: 600 }}
              leading={
                <Button
                  disableElevation
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: 8,
                  }}
                  sx={{
                    textTransform: "none !important",
                  }}
                  disabled={!showCountryPicker}
                  onClick={() => { setCountryPickerOpen(true) }}
                >
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CountryIcon icon={selectedCountry.iso2} />
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.Dark.title,
                      }}
                    >
                      +{selectedCountry.isdcode}
                    </Typography>
                  </Stack>
                </Button>
              }
              text={phone}
              setText={(text) => {
                setError(null);
                setPhone(text);
              }}
              autoCapitalize={false}
              keyboardType="number"
              errorText={phoneInputError || error}
              maxLength={10}
            />
          </div>
        </div>
      }
      footer={
        <div id="footer">
          <MButton
            disabled={loading || phoneInputError != null}
            onPress={onSubmitPhone}
            text={"Continue"}
            isPurpleButton
            size="medium"
            loading={loading}
          />
        </div>
      }
      bottomSheet={
        <>
          <MBottomSheet
            isVisible={isBottomsheetVisible}
            // close={() => {
            //   setIsBottomsheetVisible(false);
            // }}
            title="Confirm your phone"
          >
            <ConfirmPhone
              otp={otp}
              setOtp={setOtp}
              onSubmitPhoneOtp={onSubmitPhoneOtp}
              isLoading={bottomsheetLoading}
              onReenterPhone={onReenterPhone}
              onResendOtp={onResendOtp}
              phone={phone}
              otpError={otpError}
              otpSuccess={otpSuccess}
              setOtpError={setOtpError}
              setOtpSucces={setOtpSucces}
              resendLoading={resendLoading}
              otpTimer={otpTimer}
              setOtpTimer={setOtpTimer}
              selectedCountry={selectedCountry}
            />
          </MBottomSheet>
          {showCountryPicker &&
            <CountryPickerBS
              showDrawer={countryPickerOpen}
              toggleDrawer={setCountryPickerOpen}
              selectedCountry={selectedCountry}
              countries={countries}
              setCountry={setCountry}
            />}
        </>
      }
    />
  );
};

export default PhoneVerification;
