import { Alert, Button, Typography } from "@mui/material";
import CountryIcon from "assets/svgs/Icomoon";
import IMPS from "assets/svgs/imps.svg";
import NEFT from "assets/svgs/neft.svg";
import UPI from "assets/svgs/upi.svg";
import USDT from "assets/svgs/usdt.svg";
import FiatCurrencyPickerBS from "components/onRamp/FiatCurrencyPickerBottomSheet";
import WithdrawNetworkPickerBS from "components/onRamp/WithdrawNetworkPickerBottomSheet";
import BaseLayout from "components/shared/BaseLayout";
import KycTextInput from "components/shared/KycTextInput";
import LoadingScreen from "components/shared/LoadingScreen";
import MButton from "components/shared/MButton";
import MRow from "components/shared/MRow";
import { ScrollView } from "components/shared/ScrollView";
import { useOnrampContext } from "context/OnRamp";
import { useEffect, useState } from "react";
import colors from "styles/colors";
import useDebounce from "utils/useDebounce";
import styles from "./styles";
const AddFunds = (props) => {
  const {
    init,
    setCurrentState,
    inputAmount,
    setInputAmount,
    areConversionDetailsLoading,
    setCheckingLimits,
    showCrashError,
    checkingLimits,
    setShowCrashError,
    conversionDetails,
    selectedCryptoCurrency,
    external,
    externalData,
    inputCryptoAmount,
    setInputCryptoAmount,
    walletAddress,
    setPaymentDetails,
    errors,
    goToNextState,
  } = props

  const {  withdrawNetworkState, fiatCurrencyState, quote, fiatAmount, cryptoAmount } = useOnrampContext()

  useEffect(() => {
    fiatAmount?.setLocked(false)
    cryptoAmount?.setLocked(false)
  }, [])

  const fiatCurrencyInputState = fiatCurrencyState.fiatCurrency
  const selectedFiatCurrency = fiatCurrencyInputState?.value

  const classes = styles();
  // prettier-ignore
  const numbersReg = new RegExp("^[0-9]+[.]?[0-9]?[0-9]?$");
  const [loading, setLoading] = useState(false);

  let cryptoAmountError = null
  if (!areConversionDetailsLoading && conversionDetails?.min_crypto_amount && Number(conversionDetails?.min_crypto_amount) > Number(inputCryptoAmount)) {
    cryptoAmountError = `Please enter an amount greater than ${Number(conversionDetails?.min_crypto_amount ?? 0)} ${selectedCryptoCurrency?.symbol}`
  }
  const cryptoAmountErrorDebounced = useDebounce(cryptoAmountError, 500, null)

  const [currencyDrawerOpen, setCurrencyDrawerOpen] = useState(false)
  const [networkDrawerOpen, setNetworkDrawerOpen] = useState(false)

  const notReadyToRender = !selectedFiatCurrency || !selectedCryptoCurrency

  if (notReadyToRender) {
    return <LoadingScreen></LoadingScreen>
  }

  const amountsAreValid = inputAmount && Number(inputAmount) && inputCryptoAmount && Number(inputCryptoAmount)
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Buy"}
      headerVisible={true}
      body={
        <div id="body">
          <div style={styles.mainContainer}>
            <ScrollView>
              <div style={styles.container}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.Grey.title,
                  }}
                >
                  {"Purchase Amount"}
                </Typography>
                <div>
                  <div>
                    <KycTextInput
                      autoCapitalize={false}
                      style={{
                        paddingBottom: 20,
                        paddingTop: 20,
                      }}
                      textStyle={{ fontSize: 16, fontWeight: 600 }}
                      leading={
                        <Typography
                          style={{
                            backgroundColor: colors?.Background?.secondary,
                          }}
                          className={styles.currencySymbolInput}
                        >
                          {selectedFiatCurrency?.currencySymbol}
                        </Typography>
                      }
                      trailing={
                        <Button
                          disableElevation
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: 0,
                          }}
                          sx={{
                            textTransform: "none !important",
                          }}
                          disabled={!fiatCurrencyInputState.setValue}
                          onClick={() => { setCurrencyDrawerOpen(true) }}
                        >
                          <div className={classes.currencyContainer}>
                            <CountryIcon icon={selectedFiatCurrency?.iconId} size={24} />
                            <Typography
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.Dark.title,
                              }}
                            >
                              {selectedFiatCurrency?.symbol}
                            </Typography>
                          </div>
                        </Button>
                      }
                      textAlignVertical={"center"}
                      placeholder={"Enter Amount"}
                      placeholderTextColor={"#BCBCBC"}
                      text={`${inputAmount}`}
                      setText={(val) => {
                        if (numbersReg.test(val) || val === "") {
                          if (val === "") {
                            setInputAmount(0);
                            return;
                          }
                          setInputAmount(Number(val));
                          // var cryptoAmount = external
                          //   ? (
                          //     val / conversionDetails?.base_price -
                          //     conversionDetails?.fee_breakup?.network_fee
                          //   ).toFixed(2)
                          //   : (val * conversionDetails?.base_rate).toFixed(2);

                          // setInputCryptoAmount(
                          //   cryptoAmount > 0 ? cryptoAmount : 0
                          // );
                          setShowCrashError(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ ...styles.container, marginTop: 24 }}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.Grey.title,
                  }}
                >
                  {"Asset being purchased"}
                </Typography>
                <div>
                  <div>
                    <KycTextInput
                      autoCapitalize={false}
                      style={{ paddingBottom: 20, paddingTop: 20 }}
                      textStyle={{ fontSize: 16, fontWeight: 600 }}
                      trailing={
                        <Button
                          disableElevation
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: 0,
                          }}
                          sx={{
                            textTransform: "none !important",
                          }}
                          // disabled={true}
                          onClick={() => { setNetworkDrawerOpen(true) }}
                        >
                          <div className={classes.currencyContainer}>
                            <img
                              alt="crypto-flag"
                              src={USDT}
                              className={classes.flag}
                            />
                            <div
                              style={{
                                marginRight: 3,
                                alignItems: 'start',
                                alignContent: 'start'
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.Dark.title,
                                }}
                              >
                                {externalData
                                  ? externalData?.to_currency
                                  : selectedCryptoCurrency?.symbol}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: colors.Dark.title,
                                  textAlign: 'left'
                                }}
                              >
                                {withdrawNetworkState.selectedNetwork?.value?.id}
                              </Typography>

                            </div>
                          </div>
                        </Button>
                      }
                      textAlignVertical={"center"}
                      placeholder={"Enter Amount"}
                      placeholderTextColor={"#BCBCBC"}
                      text={inputCryptoAmount}
                      errorText={cryptoAmountErrorDebounced}
                      setText={(val) => {
                        if (numbersReg.test(val) || val === "") {
                          if (val === "") {
                            setInputCryptoAmount(0);
                            return;
                          }
                          setInputCryptoAmount(Number(val));
                          setShowCrashError(false);
                        }
                      }}
                    />
                    {
                      quote.isFetching && (
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          color={colors.Grey.title}
                        >
                          Fetching you the best prices!
                        </Typography>
                      )
                    }
                    {
                      !quote.isFetching && quote.data && (
                        <MRow
                          leftElement={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography
                                fontSize={12}
                                fontWeight={400}
                                color={colors.Grey.title}
                              >
                                {`${external ? externalData?.to_currency : "USDT"
                                  } ${1} = ${external
                                    ? conversionDetails?.basePrice
                                    : conversionDetails?.price
                                  } ${selectedFiatCurrency?.symbol}`}
                              </Typography>
                            </div>
                          }
                          rightElement={
                            external && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <Typography
                                  fontSize={12}
                                  fontWeight={400}
                                  color={colors.Grey.title}
                                >
                                  {`Network Fees = ${conversionDetails?.feeBreakup?.networkFee
                                    } ${selectedCryptoCurrency?.symbol}`}
                                </Typography>
                              </div>
                            )
                          }
                        />
                      )
                    }
                    {!quote.isFetching && quote.errors?.[0]?.text && (
                      <div>
                        <Typography variant="alert">
                          <Alert severity="error">{quote.errors[0].text}</Alert>
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
        </div>
      }
      footer={
        <div className={classes.buttonContainer}>
          {errors?.conversionError && (
            <Typography variant="alert">
              <Alert severity="error">{errors?.conversionError}</Alert>
            </Typography>
          )}
          {fiatCurrencyInputState.value.symbol === "INR" && <div
            style={{
              margin: "15px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: colors.Grey.title,
              }}
            >
              Pay using
            </Typography>
            <img
              alt="upi"
              src={UPI}
              style={{ marginLeft: 6, marginRight: 5 }}
            />
            <img
              alt="imps"
              src={IMPS}
              style={{ marginLeft: 5, marginRight: 5 }}
            />
            <img
              alt="neft"
              src={NEFT}
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          </div>}
          <MButton
            onPress={goToNextState}
            text={"Proceed"}
            isPurpleButton
            size="medium"
            loading={
              (external && externalData?.payment_method
                ? loading
                : checkingLimits) || areConversionDetailsLoading
            }
            disabled={
              quote.isFetching ||
              !amountsAreValid ||
              !(
                selectedFiatCurrency?.status === 1 &&
                !showCrashError &&
                !areConversionDetailsLoading
              ) ||
              (external && externalData?.payment_method
                ? loading
                : checkingLimits) ||
              errors?.conversionError
            }
          />
        </div>
      }

      bottomSheet={<div>
        <FiatCurrencyPickerBS
          showDrawer={currencyDrawerOpen}
          toggleDrawer={setCurrencyDrawerOpen}
        />
        <WithdrawNetworkPickerBS
          showDrawer={networkDrawerOpen}
          toggleDrawer={setNetworkDrawerOpen}
        />
      </div>}
    />
  );
};

export default AddFunds;
