import React, { useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import BaseLayout from "components/shared/BaseLayout";
import { ConfigContext } from "context/ConfigContext";
import colors from "styles/colors";
const KycSuccess = ({ onButtonClick }) => {
  const configContext = useContext(ConfigContext);
  useEffect(() => {
    setTimeout(() => {
      onButtonClick();
    }, 3000);
  }, []);
  useEffect(() => {
    let currentState = configContext.themeState.currentState;
    configContext.themeState.setCurrentState({
      ...currentState,
      theme: "success",
    });
  }, []);

  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              borderRadius: "20px",
              boxShadow: "0px -11px 14px -6px rgba(0, 0, 0, 0.03)",
              borderColor: "#95DFC5",
              borderWidth: "1px",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "50px 0px", textAlign: "center" }}>
              {/* <SuccessIcon /> */}
              <Typography style={{ fontsize: "20px", fontWeight: 700 }}>
                {"You are now verified."}
              </Typography>
              <Typography
                style={{
                  fontsize: "14px",
                  paddingTop: 10,
                  fontWeight: 400,
                  color: colors.Grey.title,
                }}
              >
                {"You can continue with your transaction"}
              </Typography>
            </div>
          </div>
        </div>
      }
      footer={
        <MButton
          onPress={onButtonClick}
          text={"Continue"}
          isPurpleButton
          size="medium"
        />
      }
    />
  );
};

export default KycSuccess;
