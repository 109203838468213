import useCancellableNetworkRequest, { Response } from "hooks/networking/useCancellableNetworkRequest";
import { InputValue } from "types/input-value";
import { Quote } from "types/quote";
import useDebounce from "utils/useDebounce";




export function useGetQuote(params: {
    fiatSymbol: string,
    cryptoSymbol: string,
    network?: string,
    clientId?: string,
    cryptoAmount: InputValue<number>,
    fiatAmount: InputValue<number>,
}): Response<Quote> {

    const cryptoAmountDebounced = useDebounce(params.cryptoAmount.value, 600, 0)

    var url = `w/quote?from_currency=${params.fiatSymbol}&to_currency=${params.cryptoSymbol}&network=${params.network}&`;
    url += params.cryptoAmount ? `to_amount=${cryptoAmountDebounced}` : `from_amount=${params.fiatAmount.value}`;
    params.clientId && (url += `&client_id=${params.clientId}`);

    const requestConfig: any = {
        url: url,
        method: "GET",
        service: "walletV2Caas",
        params: {},
        data: null,
    };
    return useCancellableNetworkRequest(requestConfig, [params.fiatSymbol, params.cryptoSymbol, params.network, cryptoAmountDebounced],
        Boolean(params.network) && Boolean(cryptoAmountDebounced),
        Quote);
}