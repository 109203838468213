export type PhoneCountry = {
    name: string;
    iso2: string;
    iso3: string;
    isdcode: string;
}


export const countryCodes: Array<PhoneCountry> = [
    { name: "AFGHANISTAN", iso2: "AF", iso3: "AFG", isdcode: "93" },
    { name: "ALBANIA", iso2: "AL", iso3: "ALB", isdcode: "355" },
    { name: "ALGERIA", iso2: "DZ", iso3: "DZA", isdcode: "213" },
    { name: "AMERICAN SAMOA", iso2: "AS", iso3: "ASM", isdcode: "1684" },
    { name: "ANDORRA", iso2: "AD", iso3: "AND", isdcode: "376" },
    { name: "ANGOLA", iso2: "AO", iso3: "AGO", isdcode: "244" },
    { name: "ANGUILLA", iso2: "AI", iso3: "AIA", isdcode: "1264" },
    // { name: "ANTARCTICA", iso2: "AQ", iso3: "NULL", isdcode: "0" },
    { name: "ANTIGUA AND BARBUDA", iso2: "AG", iso3: "ATG", isdcode: "1268" },
    { name: "ARGENTINA", iso2: "AR", iso3: "ARG", isdcode: "54" },
    { name: "ARMENIA", iso2: "AM", iso3: "ARM", isdcode: "374" },
    { name: "ARUBA", iso2: "AW", iso3: "ABW", isdcode: "297" },
    { name: "AUSTRALIA", iso2: "AU", iso3: "AUS", isdcode: "61" },
    { name: "AUSTRIA", iso2: "AT", iso3: "AUT", isdcode: "43" },
    { name: "AZERBAIJAN", iso2: "AZ", iso3: "AZE", isdcode: "994" },
    { name: "BAHAMAS", iso2: "BS", iso3: "BHS", isdcode: "1242" },
    { name: "BAHRAIN", iso2: "BH", iso3: "BHR", isdcode: "973" },
    { name: "BANGLADESH", iso2: "BD", iso3: "BGD", isdcode: "880" },
    { name: "BARBADOS", iso2: "BB", iso3: "BRB", isdcode: "1246" },
    { name: "BELARUS", iso2: "BY", iso3: "BLR", isdcode: "375" },
    { name: "BELGIUM", iso2: "BE", iso3: "BEL", isdcode: "32" },
    { name: "BELIZE", iso2: "BZ", iso3: "BLZ", isdcode: "501" },
    { name: "BENIN", iso2: "BJ", iso3: "BEN", isdcode: "229" },
    { name: "BERMUDA", iso2: "BM", iso3: "BMU", isdcode: "1441" },
    { name: "BHUTAN", iso2: "BT", iso3: "BTN", isdcode: "975" },
    { name: "BOLIVIA", iso2: "BO", iso3: "BOL", isdcode: "591" },
    {
        name: "BOSNIA AND HERZEGOVINA",
        iso2: "BA",
        iso3: "BIH",
        isdcode: "387"
    },
    { name: "BOTSWANA", iso2: "BW", iso3: "BWA", isdcode: "267" },
    // { name: "BOUVET ISLAND", iso2: "BV", iso3: "NULL", isdcode: "0" },
    { name: "BRAZIL", iso2: "BR", iso3: "BRA", isdcode: "55" },
    // {
    //     name: "BRITISH INDIAN OCEAN TERRITORY",
    //     iso2: "IO",
    //     iso3: "NULL",
    //     isdcode: "246"
    // },
    { name: "BRUNEI DARUSSALAM", iso2: "BN", iso3: "BRN", isdcode: "673" },
    { name: "BULGARIA", iso2: "BG", iso3: "BGR", isdcode: "359" },
    { name: "BURKINA FASO", iso2: "BF", iso3: "BFA", isdcode: "226" },
    { name: "BURUNDI", iso2: "BI", iso3: "BDI", isdcode: "257" },
    { name: "CAMBODIA", iso2: "KH", iso3: "KHM", isdcode: "855" },
    { name: "CAMEROON", iso2: "CM", iso3: "CMR", isdcode: "237" },
    { name: "CANADA", iso2: "CA", iso3: "CAN", isdcode: "1" },
    { name: "CAPE VERDE", iso2: "CV", iso3: "CPV", isdcode: "238" },
    { name: "CAYMAN ISLANDS", iso2: "KY", iso3: "CYM", isdcode: "1345" },
    {
        name: "CENTRAL AFRICAN REPUBLIC",
        iso2: "CF",
        iso3: "CAF",
        isdcode: "236"
    },
    { name: "CHAD", iso2: "TD", iso3: "TCD", isdcode: "235" },
    { name: "CHILE", iso2: "CL", iso3: "CHL", isdcode: "56" },
    { name: "CHINA", iso2: "CN", iso3: "CHN", isdcode: "86" },
    // { name: "CHRISTMAS ISLAND", iso2: "CX", iso3: "NULL", isdcode: "61" },
    // {
    //     name: "COCOS (KEELING) ISLANDS",
    //     iso2: "CC",
    //     iso3: "NULL",
    //     isdcode: "672"
    // },
    { name: "COLOMBIA", iso2: "CO", iso3: "COL", isdcode: "57" },
    { name: "COMOROS", iso2: "KM", iso3: "COM", isdcode: "269" },
    { name: "CONGO", iso2: "CG", iso3: "COG", isdcode: "242" },
    {
        name: "CONGO THE DEMOCRATIC REPUBLIC OF THE",
        iso2: "CD",
        iso3: "COD",
        isdcode: "242"
    },
    { name: "COOK ISLANDS", iso2: "CK", iso3: "COK", isdcode: "682" },
    { name: "COSTA RICA", iso2: "CR", iso3: "CRI", isdcode: "506" },
    { name: "COTE DIVOIRE", iso2: "CI", iso3: "CIV", isdcode: "225" },
    { name: "CROATIA", iso2: "HR", iso3: "HRV", isdcode: "385" },
    { name: "CUBA", iso2: "CU", iso3: "CUB", isdcode: "53" },
    { name: "CYPRUS", iso2: "CY", iso3: "CYP", isdcode: "357" },
    { name: "CZECH REPUBLIC", iso2: "CZ", iso3: "CZE", isdcode: "420" },
    { name: "DENMARK", iso2: "DK", iso3: "DNK", isdcode: "45" },
    { name: "DJIBOUTI", iso2: "DJ", iso3: "DJI", isdcode: "253" },
    { name: "DOMINICA", iso2: "DM", iso3: "DMA", isdcode: "1767" },
    { name: "DOMINICAN REPUBLIC", iso2: "DO", iso3: "DOM", isdcode: "1809" },
    { name: "ECUADOR", iso2: "EC", iso3: "ECU", isdcode: "593" },
    { name: "EGYPT", iso2: "EG", iso3: "EGY", isdcode: "20" },
    { name: "EL SALVADOR", iso2: "SV", iso3: "SLV", isdcode: "503" },
    { name: "EQUATORIAL GUINEA", iso2: "GQ", iso3: "GNQ", isdcode: "240" },
    { name: "ERITREA", iso2: "ER", iso3: "ERI", isdcode: "291" },
    { name: "ESTONIA", iso2: "EE", iso3: "EST", isdcode: "372" },
    { name: "ETHIOPIA", iso2: "ET", iso3: "ETH", isdcode: "251" },
    {
        name: "FALKLAND ISLANDS (MALVINAS)",
        iso2: "FK",
        iso3: "FLK",
        isdcode: "500"
    },
    { name: "FAROE ISLANDS", iso2: "FO", iso3: "FRO", isdcode: "298" },
    { name: "FIJI", iso2: "FJ", iso3: "FJI", isdcode: "679" },
    { name: "FINLAND", iso2: "FI", iso3: "FIN", isdcode: "358" },
    { name: "FRANCE", iso2: "FR", iso3: "FRA", isdcode: "33" },
    { name: "FRENCH GUIANA", iso2: "GF", iso3: "GUF", isdcode: "594" },
    { name: "FRENCH POLYNESIA", iso2: "PF", iso3: "PYF", isdcode: "689" },
    // {
    //     name: "FRENCH SOUTHERN TERRITORIES",
    //     iso2: "TF",
    //     iso3: "NULL",
    //     isdcode: "0"
    // },
    { name: "GABON", iso2: "GA", iso3: "GAB", isdcode: "241" },
    { name: "GAMBIA", iso2: "GM", iso3: "GMB", isdcode: "220" },
    { name: "GEORGIA", iso2: "GE", iso3: "GEO", isdcode: "995" },
    { name: "GERMANY", iso2: "DE", iso3: "DEU", isdcode: "49" },
    { name: "GHANA", iso2: "GH", iso3: "GHA", isdcode: "233" },
    { name: "GIBRALTAR", iso2: "GI", iso3: "GIB", isdcode: "350" },
    { name: "GREECE", iso2: "GR", iso3: "GRC", isdcode: "30" },
    { name: "GREENLAND", iso2: "GL", iso3: "GRL", isdcode: "299" },
    { name: "GRENADA", iso2: "GD", iso3: "GRD", isdcode: "1473" },
    { name: "GUADELOUPE", iso2: "GP", iso3: "GLP", isdcode: "590" },
    { name: "GUAM", iso2: "GU", iso3: "GUM", isdcode: "1671" },
    { name: "GUATEMALA", iso2: "GT", iso3: "GTM", isdcode: "502" },
    { name: "GUINEA", iso2: "GN", iso3: "GIN", isdcode: "224" },
    { name: "GUINEA-BISSAU", iso2: "GW", iso3: "GNB", isdcode: "245" },
    { name: "GUYANA", iso2: "GY", iso3: "GUY", isdcode: "592" },
    { name: "HAITI", iso2: "HT", iso3: "HTI", isdcode: "509" },
    // {
    //     name: "HEARD ISLAND AND MCDONALD ISLANDS",
    //     iso2: "HM",
    //     iso3: "NULL",
    //     isdcode: "0"
    // },
    {
        name: "HOLY SEE (VATICAN CITY STATE)",
        iso2: "VA",
        iso3: "VAT",
        isdcode: "39"
    },
    { name: "HONDURAS", iso2: "HN", iso3: "HND", isdcode: "504" },
    { name: "HONG KONG", iso2: "HK", iso3: "HKG", isdcode: "852" },
    { name: "HUNGARY", iso2: "HU", iso3: "HUN", isdcode: "36" },
    { name: "ICELAND", iso2: "IS", iso3: "ISL", isdcode: "354" },
    { name: "INDIA", iso2: "IN", iso3: "IND", isdcode: "91" },
    { name: "INDONESIA", iso2: "ID", iso3: "IDN", isdcode: "62" },
    {
        name: "IRAN ISLAMIC REPUBLIC OF",
        iso2: "IR",
        iso3: "IRN",
        isdcode: "98"
    },
    { name: "IRAQ", iso2: "IQ", iso3: "IRQ", isdcode: "964" },
    { name: "IRELAND", iso2: "IE", iso3: "IRL", isdcode: "353" },
    { name: "ISRAEL", iso2: "IL", iso3: "ISR", isdcode: "972" },
    { name: "ITALY", iso2: "IT", iso3: "ITA", isdcode: "39" },
    { name: "JAMAICA", iso2: "JM", iso3: "JAM", isdcode: "1876" },
    { name: "JAPAN", iso2: "JP", iso3: "JPN", isdcode: "81" },
    { name: "JORDAN", iso2: "JO", iso3: "JOR", isdcode: "962" },
    { name: "KAZAKHSTAN", iso2: "KZ", iso3: "KAZ", isdcode: "7" },
    { name: "KENYA", iso2: "KE", iso3: "KEN", isdcode: "254" },
    { name: "KIRIBATI", iso2: "KI", iso3: "KIR", isdcode: "686" },
    {
        name: "KOREA DEMOCRATIC PEOPLES REPUBLIC OF",
        iso2: "KP",
        iso3: "PRK",
        isdcode: "850"
    },
    { name: "KOREA REPUBLIC OF", iso2: "KR", iso3: "KOR", isdcode: "82" },
    { name: "KUWAIT", iso2: "KW", iso3: "KWT", isdcode: "965" },
    { name: "KYRGYZSTAN", iso2: "KG", iso3: "KGZ", isdcode: "996" },
    {
        name: "LAO PEOPLES DEMOCRATIC REPUBLIC",
        iso2: "LA",
        iso3: "LAO",
        isdcode: "856"
    },
    { name: "LATVIA", iso2: "LV", iso3: "LVA", isdcode: "371" },
    { name: "LEBANON", iso2: "LB", iso3: "LBN", isdcode: "961" },
    { name: "LESOTHO", iso2: "LS", iso3: "LSO", isdcode: "266" },
    { name: "LIBERIA", iso2: "LR", iso3: "LBR", isdcode: "231" },
    {
        name: "LIBYAN ARAB JAMAHIRIYA",
        iso2: "LY",
        iso3: "LBY",
        isdcode: "218"
    },
    { name: "LIECHTENSTEIN", iso2: "LI", iso3: "LIE", isdcode: "423" },
    { name: "LITHUANIA", iso2: "LT", iso3: "LTU", isdcode: "370" },
    { name: "LUXEMBOURG", iso2: "LU", iso3: "LUX", isdcode: "352" },
    { name: "MACAO", iso2: "MO", iso3: "MAC", isdcode: "853" },
    {
        name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
        iso2: "MK",
        iso3: "MKD",
        isdcode: "389"
    },
    { name: "MADAGASCAR", iso2: "MG", iso3: "MDG", isdcode: "261" },
    { name: "MALAWI", iso2: "MW", iso3: "MWI", isdcode: "265" },
    { name: "MALAYSIA", iso2: "MY", iso3: "MYS", isdcode: "60" },
    { name: "MALDIVES", iso2: "MV", iso3: "MDV", isdcode: "960" },
    { name: "MALI", iso2: "ML", iso3: "MLI", isdcode: "223" },
    { name: "MALTA", iso2: "MT", iso3: "MLT", isdcode: "356" },
    { name: "MARSHALL ISLANDS", iso2: "MH", iso3: "MHL", isdcode: "692" },
    { name: "MARTINIQUE", iso2: "MQ", iso3: "MTQ", isdcode: "596" },
    { name: "MAURITANIA", iso2: "MR", iso3: "MRT", isdcode: "222" },
    { name: "MAURITIUS", iso2: "MU", iso3: "MUS", isdcode: "230" },
    // { name: "MAYOTTE", iso2: "YT", iso3: "NULL", isdcode: "269" },
    { name: "MEXICO", iso2: "MX", iso3: "MEX", isdcode: "52" },
    {
        name: "MICRONESIA, FEDERATED STATES OF",
        iso2: "FM",
        iso3: "FSM",
        isdcode: "691"
    },
    { name: "MOLDOVA REPUBLIC OF", iso2: "MD", iso3: "MDA", isdcode: "373" },
    { name: "MONACO", iso2: "MC", iso3: "MCO", isdcode: "377" },
    { name: "MONGOLIA", iso2: "MN", iso3: "MNG", isdcode: "976" },
    { name: "MONTSERRAT", iso2: "MS", iso3: "MSR", isdcode: "1664" },
    { name: "MOROCCO", iso2: "MA", iso3: "MAR", isdcode: "212" },
    { name: "MOZAMBIQUE", iso2: "MZ", iso3: "MOZ", isdcode: "258" },
    { name: "MYANMAR", iso2: "MM", iso3: "MMR", isdcode: "95" },
    { name: "NAMIBIA", iso2: "NA", iso3: "NAM", isdcode: "264" },
    { name: "NAURU", iso2: "NR", iso3: "NRU", isdcode: "674" },
    { name: "NEPAL", iso2: "NP", iso3: "NPL", isdcode: "977" },
    { name: "NETHERLANDS", iso2: "NL", iso3: "NLD", isdcode: "31" },
    { name: "NETHERLANDS ANTILLES", iso2: "AN", iso3: "ANT", isdcode: "599" },
    { name: "NEW CALEDONIA", iso2: "NC", iso3: "NCL", isdcode: "687" },
    { name: "NEW ZEALAND", iso2: "NZ", iso3: "NZL", isdcode: "64" },
    { name: "NICARAGUA", iso2: "NI", iso3: "NIC", isdcode: "505" },
    { name: "NIGER", iso2: "NE", iso3: "NER", isdcode: "227" },
    { name: "NIGERIA", iso2: "NG", iso3: "NGA", isdcode: "234" },
    { name: "NIUE", iso2: "NU", iso3: "NIU", isdcode: "683" },
    { name: "NORFOLK ISLAND", iso2: "NF", iso3: "NFK", isdcode: "672" },
    {
        name: "NORTHERN MARIANA ISLANDS",
        iso2: "MP",
        iso3: "MNP",
        isdcode: "1670"
    },
    { name: "NORWAY", iso2: "NO", iso3: "NOR", isdcode: "47" },
    { name: "OMAN", iso2: "OM", iso3: "OMN", isdcode: "968" },
    { name: "PAKISTAN", iso2: "PK", iso3: "PAK", isdcode: "92" },
    { name: "PALAU", iso2: "PW", iso3: "PLW", isdcode: "680" },
    // {
    //     name: "PALESTINIAN TERRITORY, OCCUPIED",
    //     iso2: "PS",
    //     iso3: "NULL",
    //     isdcode: "970"
    // },
    { name: "PANAMA", iso2: "PA", iso3: "PAN", isdcode: "507" },
    { name: "PAPUA NEW GUINEA", iso2: "PG", iso3: "PNG", isdcode: "675" },
    { name: "PARAGUAY", iso2: "PY", iso3: "PRY", isdcode: "595" },
    { name: "PERU", iso2: "PE", iso3: "PER", isdcode: "51" },
    { name: "PHILIPPINES", iso2: "PH", iso3: "PHL", isdcode: "63" },
    { name: "PITCAIRN", iso2: "PN", iso3: "PCN", isdcode: "0" },
    { name: "POLAND", iso2: "PL", iso3: "POL", isdcode: "48" },
    { name: "PORTUGAL", iso2: "PT", iso3: "PRT", isdcode: "351" },
    { name: "PUERTO RICO", iso2: "PR", iso3: "PRI", isdcode: "1787" },
    { name: "QATAR", iso2: "QA", iso3: "QAT", isdcode: "974" },
    { name: "REUNION", iso2: "RE", iso3: "REU", isdcode: "262" },
    { name: "ROMANIA", iso2: "RO", iso3: "ROM", isdcode: "40" },
    { name: "RUSSIAN FEDERATION", iso2: "RU", iso3: "RUS", isdcode: "70" },
    { name: "RWANDA", iso2: "RW", iso3: "RWA", isdcode: "250" },
    { name: "SAINT HELENA", iso2: "SH", iso3: "SHN", isdcode: "290" },
    {
        name: "SAINT KITTS AND NEVIS",
        iso2: "KN",
        iso3: "KNA",
        isdcode: "1869"
    },
    { name: "SAINT LUCIA", iso2: "LC", iso3: "LCA", isdcode: "1758" },
    {
        name: "SAINT PIERRE AND MIQUELON",
        iso2: "PM",
        iso3: "SPM",
        isdcode: "508"
    },
    {
        name: "SAINT VINCENT AND THE GRENADINES",
        iso2: "VC",
        iso3: "VCT",
        isdcode: "1784"
    },
    { name: "SAMOA", iso2: "WS", iso3: "WSM", isdcode: "684" },
    { name: "SAN MARINO", iso2: "SM", iso3: "SMR", isdcode: "378" },
    { name: "SAO TOME AND PRINCIPE", iso2: "ST", iso3: "STP", isdcode: "239" },
    { name: "SAUDI ARABIA", iso2: "SA", iso3: "SAU", isdcode: "966" },
    { name: "SENEGAL", iso2: "SN", iso3: "SEN", isdcode: "221" },
    // {
    //     name: "SERBIA AND MONTENEGRO",
    //     iso2: "CS",
    //     iso3: "NULL",
    //     isdcode: "381"
    // },
    { name: "SEYCHELLES", iso2: "SC", iso3: "SYC", isdcode: "248" },
    { name: "SIERRA LEONE", iso2: "SL", iso3: "SLE", isdcode: "232" },
    { name: "SINGAPORE", iso2: "SG", iso3: "SGP", isdcode: "65" },
    { name: "SLOVAKIA", iso2: "SK", iso3: "SVK", isdcode: "421" },
    { name: "SLOVENIA", iso2: "SI", iso3: "SVN", isdcode: "386" },
    { name: "SOLOMON ISLANDS", iso2: "SB", iso3: "SLB", isdcode: "677" },
    { name: "SOMALIA", iso2: "SO", iso3: "SOM", isdcode: "252" },
    { name: "SOUTH AFRICA", iso2: "ZA", iso3: "ZAF", isdcode: "27" },
    // {
    //     name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    //     iso2: "GS",
    //     iso3: "NULL",
    //     isdcode: "0"
    // },
    { name: "SPAIN", iso2: "ES", iso3: "ESP", isdcode: "34" },
    { name: "SRI LANKA", iso2: "LK", iso3: "LKA", isdcode: "94" },
    { name: "SUDAN", iso2: "SD", iso3: "SDN", isdcode: "249" },
    { name: "SURINAME", iso2: "SR", iso3: "SUR", isdcode: "597" },
    { name: "SVALBARD AND JAN MAYEN", iso2: "SJ", iso3: "SJM", isdcode: "47" },
    { name: "SWAZILAND", iso2: "SZ", iso3: "SWZ", isdcode: "268" },
    { name: "SWEDEN", iso2: "SE", iso3: "SWE", isdcode: "46" },
    { name: "SWITZERLAND", iso2: "CH", iso3: "CHE", isdcode: "41" },
    { name: "SYRIAN ARAB REPUBLIC", iso2: "SY", iso3: "SYR", isdcode: "963" },
    {
        name: "TAIWAN, PROVINCE OF CHINA",
        iso2: "TW",
        iso3: "TWN",
        isdcode: "886"
    },
    { name: "TAJIKISTAN", iso2: "TJ", iso3: "TJK", isdcode: "992" },
    {
        name: "TANZANIA, UNITED REPUBLIC OF",
        iso2: "TZ",
        iso3: "TZA",
        isdcode: "255"
    },
    { name: "THAILAND", iso2: "TH", iso3: "THA", isdcode: "66" },
    // { name: "TIMOR-LESTE", iso2: "TL", iso3: "NULL", isdcode: "670" },
    { name: "TOGO", iso2: "TG", iso3: "TGO", isdcode: "228" },
    { name: "TOKELAU", iso2: "TK", iso3: "TKL", isdcode: "690" },
    { name: "TONGA", iso2: "TO", iso3: "TON", isdcode: "676" },
    { name: "TRINIDAD AND TOBAGO", iso2: "TT", iso3: "TTO", isdcode: "1868" },
    { name: "TUNISIA", iso2: "TN", iso3: "TUN", isdcode: "216" },
    { name: "TURKEY", iso2: "TR", iso3: "TUR", isdcode: "90" },
    { name: "TURKMENISTAN", iso2: "TM", iso3: "TKM", isdcode: "7370" },
    {
        name: "TURKS AND CAICOS ISLANDS",
        iso2: "TC",
        iso3: "TCA",
        isdcode: "1649"
    },
    { name: "TUVALU", iso2: "TV", iso3: "TUV", isdcode: "688" },
    { name: "UGANDA", iso2: "UG", iso3: "UGA", isdcode: "256" },
    { name: "UKRAINE", iso2: "UA", iso3: "UKR", isdcode: "380" },
    { name: "UNITED ARAB EMIRATES", iso2: "AE", iso3: "ARE", isdcode: "971" },
    { name: "UNITED KINGDOM", iso2: "GB", iso3: "GBR", isdcode: "44" },
    // {
    //     name: "UNITED STATES MINOR OUTLYING ISLANDS",
    //     iso2: "UM",
    //     iso3: "NULL",
    //     isdcode: "1"
    // },
    {
        name: "UNITED STATES OF AMERICA",
        iso2: "US",
        iso3: "USA",
        isdcode: "1"
    },
    { name: "URUGUAY", iso2: "UY", iso3: "URY", isdcode: "598" },
    { name: "UZBEKISTAN", iso2: "UZ", iso3: "UZB", isdcode: "998" },
    { name: "VANUATU", iso2: "VU", iso3: "VUT", isdcode: "678" },
    { name: "VENEZUELA", iso2: "VE", iso3: "VEN", isdcode: "58" },
    { name: "VIET NAM", iso2: "VN", iso3: "VNM", isdcode: "84" },
    { name: "VIRGIN ISLANDS U.S.", iso2: "VI", iso3: "VIR", isdcode: "1340" },
    {
        name: "VIRGIN ISLANDS, BRITISH",
        iso2: "VG",
        iso3: "VGB",
        isdcode: "1284"
    },
    { name: "WALLIS AND FUTUNA", iso2: "WF", iso3: "WLF", isdcode: "681" },
    { name: "WESTERN SAHARA", iso2: "EH", iso3: "ESH", isdcode: "212" },
    { name: "YEMEN", iso2: "YE", iso3: "YEM", isdcode: "967" },
    { name: "ZAMBIA", iso2: "ZM", iso3: "ZMB", isdcode: "260" },
    { name: "ZIMBABWE", iso2: "ZW", iso3: "ZWE", isdcode: "263" }
];