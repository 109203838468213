import {
  getCaptcha,
  uploadOCRData,
  verifyAadharOtp,
  verifyCaptchaAndGetOTP,
} from "api/kycHooks";
import AadharDetails from "components/kyc/AadharVerification";
import { useUserContext } from "context/UserContext";
import React, { useEffect, useState } from "react";
import {
  setUpDocumentCaptureConfig,
  startDocumentCapture,
  startUserSession,
} from "utils/hyperverge";
import { base64ToBlob, getTimestampInSeconds, hypervergeErrToIgnoreArr, isEmpty, syncTimeout } from "utils/validators";

const AadharDetailsContainer = ({ onStepCompleted, ocrEnabled }) => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [aadhar, setAadhar] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [aadharImageError, setAadharImageError] = useState("");
  const [bottomSheetKey, setBottomSheetKey] = useState("aadhar_otp");
  const [captchaImage, setCaptchaImage] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [aadharDetails, setAadharDetails] = useState(null);
  const [failedOcrIndex, setFailedOcrIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaLoading, setIsCaptchaLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [aadharErrors, setAadharErrors] = useState("");
  const [otpErrorText, setOtpErrorText] = useState(null);
  const [captchaErrorText, setCaptchaErrorText] = useState(null);
  const [showOtpSent, setShowOtpSent] = useState(false);
  const [error, setError] = React.useState(false);

  const onError = async () => {
    setError(true);
  };

  const onRetry = async () => {
    setError(false);
  };

  useEffect(() => {
    setCaptchaErrorText(null);
  }, [captcha]);

  useEffect(() => {
    fetchCaptcha(1, getTimestampInSeconds(), getTimestampInSeconds() + 3);
  }, []);

  const documentCaptureClosure = (error, res, side, aadharData) => {
    setIsLoading(true);
    try {
      if (!isEmpty(error)) {
        setIsLoading(false);
        if (hypervergeErrToIgnoreArr?.indexOf(error?.errorCode) === -1) {
          setAadharImageError(error?.errorMsg);
        }
        if (side === "back") {
          setFailedOcrIndex(1);
        } else {
          setFailedOcrIndex(0);
        }
      } else {
        const { imgBase64 } = res;

        if (side === "front") {
          const { response } = res || {};
          const { result } = response || {};
          const [data] = result || [];

          if (data?.details?.face?.present === "yes") {
            const hvConfig = setUpDocumentCaptureConfig("back");
            startDocumentCapture(hvConfig, (err, res) => {
              documentCaptureClosure(err, res, "back", {
                front_uri: imgBase64,
                aadhar_number: data?.details?.aadhaar?.value,
                name: data?.details?.name?.value,
                gender: data?.details?.gender?.value,
                dob: data?.details?.dob?.value,
              });
            });
          }
        } else {
          const { apiResult } = res || {};
          const { result } = apiResult || {};
          const [item] = result || [];
          const data = {
            ...aadharData,
            address: item?.details?.address?.value,
            back_uri: imgBase64,
          };
          handleOCRData(data);
        }
      }
    } catch (e) { }
    setIsLoading(false);
  };

  const showErrorState = (err) => {
    const errorObj = err?.response?.data?.errors;
    const errorText = errorObj?.length
      ? errorObj[0]?.text
      : "Oops! We could not verify your Aadhar details";
    setAadharErrors(errorText);
  };

  const handleOCRData = async (aadharData) => {
    setIsLoading(true);
    const { front_uri, aadhar_number, name, gender, dob, back_uri, address } =
      aadharData || {};
    const data = new FormData();

    data.append("aadhar_number", aadhar_number);
    data.append("address", address);
    data.append("name", name);
    data.append("gender", gender);
    data.append("dob", dob);

    const frontBlob = base64ToBlob(front_uri);
    const backBlob = base64ToBlob(back_uri);
    data.append("aadhar_front_image", frontBlob);
    data.append("aadhar_back_image", backBlob);
    try {
      await uploadOCRData(data);
      onStepCompleted();
    } catch (err) {
      setShowBottomSheet(false);
      setIsLoading(false);
      showErrorState(err);
      onError();
    }
  };

  const user = useUserContext().user

  const startAadharCapture = async () => {
    try {

      startUserSession(user.userId);
      const hvConfig = setUpDocumentCaptureConfig("front");
      startDocumentCapture(hvConfig, (error, res) =>
        documentCaptureClosure(error, res, "front")
      );
    } catch (e) { }
  };

  const onContinueClick = async () => {
    setIsLoading(true);
    try {
      await verifyCaptchaAndGetOTP(
        {
          session_id: sessionId,
          security_code: captcha,
          aadhar_number: aadhar,
        },
      );
      if (bottomSheetKey !== "aadhar_otp") {
        setBottomSheetKey("aadhar_otp");
      }
      setShowBottomSheet(true);
    } catch (err) {
      const errorCode = err?.response?.data?.errors?.[0]?.code;
      if (errorCode === 11104) {
        setCaptchaErrorText("Invalid CAPTCHA! Please re-enter");
      } else {
        showErrorState(err);
        refetchCaptcha(false);
      }
    }
    setIsLoading(false);
  };

  const onResendCodeClick = async () => {
    setShowOtpSent(false);
    setOtpErrorText(null);
    try {
      await verifyCaptchaAndGetOTP(
        {
          session_id: sessionId,
          security_code: captcha,
          aadhar_number: aadhar,
        },
      );
      setShowOtpSent(true);
    } catch (err) {
      onError();
    }
  };

  const onSubmitOtpClick = async (otp) => {
    if (isNaN(otp) || otp?.length !== 6) {
      setOtpErrorText("Invalid OTP");
      return;
    }

    setIsLoading(true);
    try {
      const result = await verifyAadharOtp(
        {
          session_id: sessionId,
          aadhar_number: aadhar,
          otp,
        },
      );

      const { aadhar_details } = result || {};
      const { name, dob, gender, address, masked_aadhar_number } =
        aadhar_details || {};
      setShowBottomSheet(true);
      setBottomSheetKey("aadhar_confirm");
      setAadharDetails({
        name,
        dob,
        gender,
        address,
        masked_aadhar_number,
      });
    } catch (err) {
      if (err?.response?.status === 400) {
        const errorObj = err?.response?.data?.errors;
        const errorText = errorObj?.length ? errorObj[0]?.text : "Invalid OTP";
        setOtpErrorText(errorText);
      } else {
        setShowBottomSheet(false);
        setTimeout(() => {
          showErrorState(err);
          refetchCaptcha(false);
        }, 500);
        onError();
      }
    }
    setIsLoading(false);
  };

  const onEditAadharNumber = () => {
    setShowBottomSheet(false);
    setAadhar("");
    setCaptcha("");
    refetchCaptcha();
  };

  const resetOtpErrorText = () => {
    setOtpErrorText(null);
  };

  const fetchCaptcha = async (count, startTime, endTime) => {
    setIsScreenLoading(true);
    setCaptchaImage(null);
    setAadharErrors("");
    try {
      const { captcha: image, session_id } = (await getCaptcha()) || {};

      setCaptchaImage(image);
      setSessionId(session_id);
      setIsScreenLoading(false);
      return true;
    } catch (err) {
      if (count <= 3) {
        if (err?.response?.status == 503) {
          await syncTimeout(endTime - startTime);
          const result = await fetchCaptcha(
            count + 1,
            getTimestampInSeconds(),
            getTimestampInSeconds() + 3
          );
          if (result) {
            setIsScreenLoading(false);
            return;
          }
        }
      }
      setAadharErrors(err?.response?.data?.errors?.[0]?.text);
      onError();
    }
    setIsScreenLoading(false);
  };

  const refetchCaptcha = async (clear_error = true) => {
    setIsCaptchaLoading(true);
    setCaptchaImage(null);
    setCaptcha("");
    if (clear_error) {
      setAadharErrors("");
    }
    try {
      const { captcha: image, session_id } = (await getCaptcha()) || {};
      setCaptchaImage(image);
      setSessionId(session_id);
    } catch (err) {
      setAadharErrors(err?.response?.data?.errors?.[0]?.text);
      onError();
    }
    setIsCaptchaLoading(false);
  };

  const startAadharNotLinkedFlow = () => {
    setBottomSheetKey("aadhar_capture");
    !showBottomSheet && setShowBottomSheet(true);
  };

  const onConfirmClick = () => {
    onStepCompleted();
  };

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
  };

  useEffect(() => {
    if (aadharImageError) {
      setTimeout(() => {
        setAadharImageError("");
      }, 15000);
    }
  }, [aadharImageError]);

  return (
    <AadharDetails
      aadharImageError={aadharImageError}
      onContinueClick={onContinueClick}
      showBottomSheet={showBottomSheet}
      closeBottomSheet={closeBottomSheet}
      aadhar={aadhar}
      setAadhar={setAadhar}
      captcha={captcha}
      setCaptcha={setCaptcha}
      captchaImage={captchaImage}
      startAadharNotLinkedFlow={startAadharNotLinkedFlow}
      onConfirmClick={onConfirmClick}
      bottomSheetKey={bottomSheetKey}
      sessionId={sessionId}
      startAadharCapture={startAadharCapture}
      onSubmitOtpClick={onSubmitOtpClick}
      refetchCaptcha={refetchCaptcha}
      onEditAadharNumber={onEditAadharNumber}
      aadharDetails={aadharDetails}
      onResendCodeClick={onResendCodeClick}
      isLoading={isLoading}
      isScreenLoading={isScreenLoading}
      otpErrorText={otpErrorText}
      resetOtpErrorText={resetOtpErrorText}
      isCaptchaLoading={isCaptchaLoading}
      showOtpSent={showOtpSent}
      captchaErrorText={captchaErrorText}
      aadharErrors={aadharErrors}
      setAadharErrors={setAadharErrors}
      onError={onError}
      onRetry={onRetry}
      setShowOtpSent={setShowOtpSent}
      error={error}
      onReenterAadhar={onEditAadharNumber}
      ocrEnabled={ocrEnabled}
    />
  );
};

export default AadharDetailsContainer;
