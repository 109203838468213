import { useState } from "react";
import WalletScreen from "components/onRamp/WalletAddress";

const WalletScreenContainer = ({
  walletAddress,
  setWalletAddress,
  onSubmit,
}) => {
  return (
    <WalletScreen
      walletAddress={walletAddress}
      setWalletAddress={setWalletAddress}
      onSubmit={onSubmit}
    />
  );
};

export default WalletScreenContainer;
