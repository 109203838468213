import React, { useState } from "react";
import MBottomSheet from "components/shared/BottomSheet";
import MCheckBox from "components/shared/CheckBox";
import MButton from "components/shared/MButton";
import { Typography } from "@mui/material";
import ConfirmPAN from "components/kyc/ConfirmPAN";
import KycTextInput from "components/shared/KycTextInput";
import styles from "./styles";
import { isPanCardValid } from "utils/validators";
import { Grid } from "@mui/material";
import BaseLayout from "components/shared/BaseLayout";
import MHeading from "components/shared/MHeading";
import PanMessage from "components/shared/PanMessage";

const PanDetails = ({
  onContinueClick,
  onReenterPanClick,
  showBottomSheet,
  setShowBottomSheet,
  panNumber,
  setPanNumber,
  onUserConsent,
  name,
  isLoading,
  panErrors,
  setPanErrors,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const classes = styles();
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div id="body">
          <MHeading heading="Enter your PAN" />
          <KycTextInput
            placeholder={"ABCDE1234A"}
            text={panNumber}
            setText={(text) => {
              setPanNumber(text);
              setPanErrors("");
            }}
            maxLength={10}
            errorText={
              panNumber?.length === 10 && !isPanCardValid(panNumber)
                ? "Invalid PAN number"
                : panErrors
                ? panErrors
                : null
            }
          />
          <PanMessage
            text={
              "Please ensure the name on the PAN matches with the name linked to your bank account"
            }
          />
        </div>
      }
      footer={
        <div id="footer">
          <div className={classes.footerContainer}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <div className={classes.gridItem}>
                  <MCheckBox
                    isChecked={isChecked}
                    size={"18"}
                    onClick={() => {
                      setIsChecked(!isChecked);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="caption"
                  style={{ textAlign: "justify", lineHeight: "0% !important" }}
                >
                  {
                    "I certify that I am at least 18 years old and using my own documents for verification"
                  }
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div>
            <MButton
              onPress={() => onContinueClick()}
              disabled={!isChecked || !isPanCardValid(panNumber)}
              text={"Continue"}
              isPurpleButton
              size="medium"
              loading={!showBottomSheet && isLoading}
            />
          </div>
        </div>
      }
      bottomSheet={
        <MBottomSheet
          isVisible={showBottomSheet}
          close={() => setShowBottomSheet(false)}
          title="Confirm your details"
        >
          <ConfirmPAN
            onReenterPanClick={onReenterPanClick}
            name={name}
            onUserConsent={onUserConsent}
            panNumber={panNumber}
            isLoading={showBottomSheet && isLoading}
          />
        </MBottomSheet>
      }
    />
  );
};

export default PanDetails;
