import React from "react";
// import { View } from "react-native";
// import styles from "./styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import colors from "../../../styles/colors";
import { Typography } from "@mui/material";
import styles from "./styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.Background.secondary,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

const MStepper = ({ currentStep = 0, totalSteps }) => {
  const classes = styles();
  return (
    <>
      <Typography variant="caption" className={classes.steps}>
        {`Step ${currentStep} / ${totalSteps}`}
      </Typography>
      <BorderLinearProgress
        variant="determinate"
        value={(currentStep * 100) / totalSteps}
      />
    </>
  );
};

export default MStepper;
