import { useEffect, useRef } from "react";

interface PollingConfig {
  interval: number; // Initial interval (ms)
  maxInterval?: number; // Optional max polling interval(ms)
  onPoll: () => Promise<any>; // Function to execute at each poll
  onSuccess?: (result: any) => void; // Optional success callback
  dependencies?: any[]; // Dependency list to conditionally trigger polling
}

export const usePolling = ({
  interval,
  maxInterval,
  onPoll,
  onSuccess,
  dependencies = [],
}: PollingConfig) => {
  const elapsed = useRef(0);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const currentInterval = useRef(interval);

  const poll = async () => {
    try {
      const result = await onPoll();
      onSuccess?.(result);
    } catch (error) {
      console.error("Polling error:", error);
    }

    elapsed.current += currentInterval.current;

    if (!maxInterval || elapsed.current < maxInterval) {
      currentInterval.current = Math.min(
        currentInterval.current * 2,
        maxInterval
          ? maxInterval - elapsed.current
          : currentInterval.current * 2
      );
    }
    timeoutId.current = setTimeout(poll, currentInterval.current);
  };

  useEffect(() => {
    poll();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, dependencies);
};
