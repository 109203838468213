import { Box, Divider, Radio, Stack, Typography } from '@mui/material';
import CountryIcon from 'assets/svgs/Icomoon';
import { PhoneCountry } from "constants/country_isd";
import colors from "styles/colors";

interface CountryItemProps {
    country: PhoneCountry;
    selected: boolean;
    setCountry: (Country: PhoneCountry) => void;
}

const CountryItem: React.FC<CountryItemProps> = ({ country, selected, setCountry }) => {

    return (
        <div>
            <Stack
                direction="column"
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    cursor: 'pointer'
                }}
                onClick={() => { setCountry(country) }}
            >
                <Stack spacing={1} direction="row" sx={{
                    display: "flex",
                    alignItems: 'center',
                }} >
                    <Box sx={{
                        height: 40, width: 40, padding: "8px",
                        borderRadius: "8px",
                        // background: alpha(color, 0.1)
                    }}>
                        <CountryIcon icon={country.iso2} size={24} />

                    </Box>
                    <Stack sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }} color={colors.Dark}>
                        {country.name}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color={colors.Grey.title}>
                            {"+" +country.isdcode}
                        </Typography>
                    </Stack>
                    <Radio
                        size="small"
                        checked={selected}
                        value="b"
                        name="radio-buttons"
                        style={{ paddingLeft: 0 }}
                        disableRipple={true}
                    // inputProps={{ "aria-label": "B" }}
                    />
                </Stack>
            </Stack>
            <Divider />
        </div>
    );

};

export default CountryItem;