import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BuyCoin from "assets/svgs/buy-coin";
import IcBuy from "assets/svgs/ic-buy";
import SellCoin from "assets/svgs/sell-coin";
import TransactionHistory from "assets/svgs/transaction-history";
import { ProfileBanner } from "components/profile/profile-banner";
import {
  NavContext,
  STEP_ONRAMP,
  STEP_TRANSACTION_HISTORY,
} from "context/NavContext";
import { UserContext } from "context/UserContext";
import { FC, useContext } from "react";

interface SideNavProps {
  text: string;
}

export const SideNav: FC<SideNavProps> = (props) => {
  const {} = props;

  const { user } = useContext(UserContext);
  const { setCurrentStep } = useContext(NavContext);

  return (
    <div>
      {user && (
        <>
          <ProfileBanner user={user} />
          <Divider />
        </>
      )}
      <List>
        <ListItem key={"buy"} disablePadding>
          <ListItemButton
            onClick={() => {
              setCurrentStep(STEP_ONRAMP);
            }}
          >
            <ListItemIcon sx={{ height: 24, width: 24 }}>
              <IcBuy color="#000" />
            </ListItemIcon>
            <ListItemText primary={"Buy"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={"transactions"} disablePadding>
          <ListItemButton
            onClick={() => {
              setCurrentStep(STEP_TRANSACTION_HISTORY);
            }}
          >
            <ListItemIcon sx={{ height: 24, width: 24 }}>
              <TransactionHistory color="#000" />
            </ListItemIcon>
            <ListItemText primary={"Transaction History"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </div>
  );
};
