import { ConfigContext } from "context/ConfigContext";
import React, { useContext, useState } from "react";

const useSecret = () => {
  const init = useContext(ConfigContext);
  const [signature, setSignature] = useState(null);

  const loadSignature = async () => {
    const newSignature = await init();
    setSignature(newSignature);
  };

  const getSignature = async () => {
    var now = Math.floor(Date.now() / 1000);
    if (!signature || signature?.timestamp + 1800 <= now) {
      await loadSignature();
    }
    return signature;
  };

  return { getSignature };
};
let signature = null;

const loadSignature = async (callback) => {
  signature = await callback();
};

const getSignature = async (init) => {
  var now = Math.floor(Date.now() / 1000);
  if (!signature || signature?.timestamp + 10 <= now) {
    await loadSignature(init);
  }
  return signature;
};

export { getSignature, useSecret };
