import React from "react";
import MButton from "components/shared/MButton";
import { Typography, Alert } from "@mui/material";
import gs from "styles/styles";
import colors from "styles/colors";
import ExpiredTimer from "assets/svgs/expired-timer.svg";

const ExpiryBottomsheet = ({}) => {
  const globalStyles = gs();
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingBottom: 15,
        }}
      >
        <img src={ExpiredTimer} />
      </div>
      <div className={globalStyles.dialogContainer}>
        <div
          style={{
            marginBottom: 15,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 5,
          }}
        >
          <Typography fontSize={14} style={{ color: colors.Dark.title }}>
            <strong>{`Oops! Your session has expired.`}</strong>
          </Typography>
          <Typography fontSize={14} className={globalStyles.confirmText}>
            Please retry and restart the payment <br /> to complete your
            purchase.
          </Typography>
        </div>
      </div>
      <div>
        <MButton
          onPress={() => {
            window.parent.postMessage(
              {
                event: "expired",
              },
              "*"
            );
          }}
          text={"Retry"}
          isPurpleButton
        />
      </div>
    </div>
  );
};

export default ExpiryBottomsheet;
