import { createUseStyles } from "react-jss";
import colors from "styles/colors";

const styles = createUseStyles({
  container: {
    paddingHorizontal: 20,
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  title: {
    marginBottom: 20,
  },
  subtitle: {},
  inputTitle: {
    marginBottom: 14,
    marginTop: 24,
    color: colors.Dark.title,
  },
  purpleText: {
    textAlign: "center",
    color: colors.Primary.background,
  },
  captchaContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    borderWidth: "1px",
    width: "fit-content",
    minWidth: 152,
    minHeight: 43,
    borderColor: colors?.Grey?.borderGrey,
  },
  captcha: {
    height: 38,
    border: 1,
    marginRight: 14,
    resizeMode: "contain",
    borderRadius: 4,
  },
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 6,
  },
  aadharError: {
    color: colors.Error.primary,
    marginLeft: 4,
    marginTop: 3,
  },
});

export default styles;
