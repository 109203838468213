import { Expose, Type, plainToInstance } from "class-transformer";

// {
//     "id": "ebd413c8-1f9b-4cd9-8497-4b25655865d9",
//     "transaction_type": "WITHDRAW",
//     "status": "COMPLETED",
//     "amount": 50,
//     "failure_code": "MANUAL_APPROVAL_REQUIRED",
//     "failure_desc": "Reason for manual approval ADMIN_WITHDRAW_APPROVAL",
//     "created_at": 1689341772000,
//     "updated_at": 1689344527000,
//     "crypto_symbol": "USDC",
//     "userUUID": "663d88cd-2d92-4424-ac46-cf6f5c706e25",
//     "address": "0x9d0d74b4f9ab1a58dad43f5ad45097a47dc54f5b",
//     "network": "ETH",
//     "txn_hash": "Internal transfer 140413511924",
//     "title": "Crypto Withdraw",
//     "content": {
//         "title": "",
//         "sub_title": "",
//         "steps": null,
//         "cta": null
//     }
// }

export class Transaction {
  @Expose() id: string;
  @Expose({ name: "userUUID" }) userId: string;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt: Date;
  @Expose({ name: "updated_at" }) @Type(() => Date) updatedAt: Date;
  @Expose({ name: "transaction_type" }) type: TransactionType;
  @Expose({ name: "crypto_amount" }) cryptoAmount: number;
  @Expose({ name: "status" }) status: TransactionStatus;
  @Expose({ name: "failure_desc" }) failureDesc?: string;
  @Expose({ name: "crypto_symbol" }) cryptoSymbol?: string;
  @Expose({ name: "payment_method" }) paymentMethod?: string;

  constructor(
    id: string,
    userId: string,
    createdAt: Date,
    updatedAt: Date,
    type: TransactionType,
    usdtAmount: number,
    status: TransactionStatus,
    failureDesc: string
  ) {
    this.id = id;
    this.userId = userId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.type = type;
    this.cryptoAmount = usdtAmount;
    this.status = status;
    this.failureDesc = failureDesc;
  }

  getStatus(): string {
    switch (this.status) {
      case TransactionStatus.ONRAMP_INITIATED:
        return "processing"
        break;
      case TransactionStatus.PROCESSING:
        return "processing"
        break;
      case TransactionStatus.FAILED:
        return "failed"
        break;
      case TransactionStatus.COMPLETED:
        return "completed"
        break;
    }
    return "processing"
  }

}

export enum TransactionType {
  BUY = "BUY",
  SELL = "SELL",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  EXTERNAL_BUY = "EXTERNAL_BUY",
  ONRAMP_AND_WITHDRAW = "ONRAMP_AND_WITHDRAW",
}

export enum TransactionStatus {
  ONRAMP_INITIATED = "ONRAMP_INITIATED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
}

export function getTransaction(): Transaction {
  const jsonStr = `            {
        "id": "ebd413c8-1f9b-4cd9-8497-4b25655865d9",
        "transaction_type": "WITHDRAW",
        "status": "COMPLETED",
        "amount": 50,
        "failure_code": "MANUAL_APPROVAL_REQUIRED",
        "failure_desc": "Reason for manual approval ADMIN_WITHDRAW_APPROVAL",
        "created_at": 1689341772000,
        "updated_at": 1689344527000,
        "crypto_symbol": "USDC",
        "userUUID": "663d88cd-2d92-4424-ac46-cf6f5c706e25",
        "address": "0x9d0d74b4f9ab1a58dad43f5ad45097a47dc54f5b",
        "network": "ETH",
        "txn_hash": "Internal transfer 140413511924",
        "title": "Crypto Withdraw",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    }`;
  const plain: object = JSON.parse(jsonStr);
  const txn = plainToInstance(Transaction, plain, {
    excludeExtraneousValues: true,
  });
  return txn;
}

export function getTransactions(): Array<Transaction> {
  const jsonStr = `
 [
    {
        "id": "14447c4c-5191-46ed-a23d-2abe5498bb93",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.95650268,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1692171012000,
        "updated_at": 1692201002000,
        "crypto_symbol": "USDT",
        "fiat_amount": 1000,
        "fiat_symbol": "INR",
        "crypto_amount": 10.95650268,
        "exchange_rate": 91.27,
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "fecaa108-0944-4b69-9f54-f74039630a22",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.96130659,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1692170897000,
        "updated_at": 1692201003000,
        "crypto_symbol": "USDT",
        "fiat_amount": 1000,
        "fiat_symbol": "INR",
        "crypto_amount": 10.96130659,
        "exchange_rate": 91.23,
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "16d1e452-d7e2-4beb-a617-8ddccd77f0f6",
        "transaction_type": "BUY",
        "status": "COMPLETED",
        "amount": 54.89076737,
        "created_at": 1692011254000,
        "updated_at": 1692017489000,
        "crypto_symbol": "USDT",
        "fiat_amount": 5000,
        "fiat_symbol": "INR",
        "crypto_amount": 54.89076737,
        "exchange_rate": 91.09,
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "71edec75-655e-40fc-9a8b-7dedcc1f97e9",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 0.97,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691408910000,
        "updated_at": 1691665428000,
        "crypto_symbol": "USDT",
        "fiat_amount": 100,
        "fiat_symbol": "INR",
        "crypto_amount": 0.97,
        "exchange_rate": 103.09,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "8bc1dc3f-2565-49d3-81c2-260f4fac1757",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 0.97,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691404269000,
        "updated_at": 1691665443000,
        "crypto_symbol": "USDT",
        "fiat_amount": 100,
        "fiat_symbol": "INR",
        "crypto_amount": 0.97,
        "exchange_rate": 103.09,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "a462c601-c600-4059-8071-4b075d9b9dae",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 0.97,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691401051000,
        "updated_at": 1691665460000,
        "crypto_symbol": "USDT",
        "fiat_amount": 100,
        "fiat_symbol": "INR",
        "crypto_amount": 0.97,
        "exchange_rate": 103.09,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "1c7eaa58-ccdf-4564-9295-10186f49b803",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 0.31,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691237282000,
        "updated_at": 1691665378000,
        "crypto_symbol": "USDT",
        "fiat_amount": 40,
        "fiat_symbol": "INR",
        "crypto_amount": 0.31,
        "exchange_rate": 129.03,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "c747c380-a74c-4589-8bb5-aa5b16b5a492",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 0.31,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691237146000,
        "updated_at": 1691665484000,
        "crypto_symbol": "USDT",
        "fiat_amount": 40,
        "fiat_symbol": "INR",
        "crypto_amount": 0.31,
        "exchange_rate": 129.03,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "4d598dbe-f998-414f-a604-e672859180cf",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.19,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691227497000,
        "updated_at": 1691665406000,
        "crypto_symbol": "USDT",
        "fiat_amount": 937.43,
        "fiat_symbol": "INR",
        "crypto_amount": 10.19,
        "exchange_rate": 92,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "75ce88a4-7d35-4c29-8451-391205f5aa18",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.19,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691226238000,
        "updated_at": 1691665429000,
        "crypto_symbol": "USDT",
        "fiat_amount": 936.72,
        "fiat_symbol": "INR",
        "crypto_amount": 10.19,
        "exchange_rate": 91.93,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "577689b4-00d1-417e-a6bf-34e597f11be5",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.19,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691223770000,
        "updated_at": 1691665410000,
        "crypto_symbol": "USDT",
        "fiat_amount": 937.33,
        "fiat_symbol": "INR",
        "crypto_amount": 10.19,
        "exchange_rate": 91.99,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "12cf2356-7fbc-4280-8992-3e79147d6a0d",
        "transaction_type": "BUY",
        "status": "FAILED",
        "amount": 10.2,
        "failure_code": "PAYMENT_TIMED_OUT",
        "failure_desc": "Payment not received from the user",
        "created_at": 1691222223000,
        "updated_at": 1691665370000,
        "crypto_symbol": "USDT",
        "fiat_amount": 937.13,
        "fiat_symbol": "INR",
        "crypto_amount": 10.2,
        "exchange_rate": 91.88,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Failure reason: Payment not received from the user",
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "96f73444-0c1a-4623-9d20-73c5b2e2fb9a",
        "transaction_type": "BUY",
        "status": "CREATED",
        "amount": 10,
        "created_at": 1691077201000,
        "updated_at": 1691077201000,
        "crypto_symbol": "USDT",
        "fiat_amount": 912.5,
        "fiat_symbol": "INR",
        "crypto_amount": 10,
        "exchange_rate": 91.25,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Will be processed by ",
        "eta": 1691163601000,
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    },
    {
        "id": "a8cbfc30-cc6f-4a1d-9eaa-7706d11dde89",
        "transaction_type": "BUY",
        "status": "CREATED",
        "amount": 10,
        "created_at": 1691077163000,
        "updated_at": 1691077169000,
        "crypto_symbol": "USDT",
        "fiat_amount": 912.5,
        "fiat_symbol": "INR",
        "crypto_amount": 10,
        "exchange_rate": 91.25,
        "source_id": "70deaa66-fbee-4d9d-aee5-b063b1cd6db4",
        "bank_transaction_id": "1241434141",
        "userUUID": "7114562e-ce47-4054-9d4e-4df69ca61ac1",
        "title": "Bank Deposit",
        "message": "Will be processed by ",
        "eta": 1691163563000,
        "content": {
            "title": "",
            "sub_title": "",
            "steps": null,
            "cta": null
        }
    }
 ]
 `;

  const plain: Array<object> = JSON.parse(jsonStr);
  const txn = plainToInstance(Transaction, plain, {
    excludeExtraneousValues: true,
  });
  return txn;
}
