import { User } from "types/user";

export type State = Unauthenticated | Authenticated

export class Unauthenticated { }

export class Authenticated {
    user?: User

    constructor(user?: User) {
        this.user = user;
    }
}


export type AuthContextType = {
    state: State
}