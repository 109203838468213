import { makeOnRampWithdrawTransaction, makeTransaction, updateOnRampWithdrawTransaction } from "api/onRampHooks";
import { OnrampType } from "types/OnRamp";

export async function createOrUpdateTransaction(
    onramp_type: OnrampType,
    priceLock: boolean,
    source_id: string,
    from_currency: string,
    to_currency: string,
    from_amount: number,
    to_amount: number,
    payment_method: string,
    network: string,
    crypto_wallet_address: string,
    verification_code?: string,
    utr?: string,
    existing_order_id?: string,
    new_txn_id?: string,
) {
    switch (onramp_type) {
        case OnrampType.ONRAMP:
            return createOrUpdateOnramp(priceLock, source_id, from_currency, to_currency, from_amount, to_amount, payment_method, network, crypto_wallet_address, existing_order_id, new_txn_id, verification_code, utr)
        case OnrampType.ONRAMP_AND_WITHDRAW:
            return createOrUpdateOnrampAndWithdraw(priceLock, source_id, from_currency, to_currency, from_amount, to_amount, payment_method, network, crypto_wallet_address, existing_order_id, new_txn_id, verification_code, utr)
    }
}

async function createOrUpdateOnrampAndWithdraw(
    priceLock: boolean,
    source_id: string,
    from_currency: string,
    to_currency: string,
    from_amount: number,
    to_amount: number,
    payment_method: string,
    network: string,
    crypto_wallet_address: string,
    existing_order_id?: string,
    new_txn_id?: string,
    verification_code?: string,
    utr?: string,
) {
    if (existing_order_id) {
        return updateOnRampWithdrawTransaction({ init: undefined, orderId: existing_order_id, utr, verification_code })
    } else {
        const data = {
            source_id,
            from_currency,
            ...(!priceLock && { from_amount }), // Do not send from_amount in priceLock
            to_currency,
            to_amount,
            payment_method,
            network,
            crypto_wallet_address,
            ...(new_txn_id && { uuid: new_txn_id }),
            verification_code,
            utr,
        }

        return makeOnRampWithdrawTransaction({
            init: undefined,
            data: data,
        })
    }
}

async function createOrUpdateOnramp(
    priceLock: boolean,
    source_id: string,
    from_currency: string,
    to_currency: string,
    from_amount: number,
    to_amount: number,
    payment_method: string,
    network: string,
    crypto_wallet_address: string,
    existing_order_id?: string,
    new_txn_id?: string,
    verification_code?: string,
    utr?: string,
) {

    const data = {
        verification_code,
        source_id,
        fiat_symbol: from_currency,
        crypto_symbol: to_currency,
        fiat_amount: from_amount,
        crypto_amount: to_amount,
        payment_method: payment_method,
        ...(new_txn_id && { uuid: new_txn_id }),
    }

    return makeTransaction({
        init: undefined,
        data: data,
    });

}