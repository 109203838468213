import React from "react";
import { Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import styles from "./styles";
import ConfirmRow from "components/shared/ConfirmRow";
import colors from "styles/colors";
import MLink from "components/shared/Link";
const ConfirmAadhar = ({
  onEditAadharNumber,
  aadharDetails,
  onConfirmClick,
}) => {
  const classes = styles();
  const { masked_aadhar_number, name, gender, dob } = aadharDetails || {};
  return (
    <div className={classes.container}>
      <Typography variant="caption" style={{ color: colors?.Grey?.title }}>
        {"Please check and confirm your bank details"}
      </Typography>
      <div className={classes.detailsContainer}>
        <ConfirmRow label="Aadhar Number" value={masked_aadhar_number} />
        <ConfirmRow label="Name" value={name} />
        <ConfirmRow label="Date of Birth" value={dob} />
        <ConfirmRow label="Gender" value={gender} />
      </div>
      <MButton
        onPress={onConfirmClick}
        text={"Confirm"}
        isPurpleButton
        size="medium"
      />
      <MLink
        text={"Wrong details? Edit Aadhar no."}
        onClick={onEditAadharNumber}
      />
    </div>
  );
};

export default ConfirmAadhar;
