import { SVGProps } from "react"
const TransactionHistory = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                fill={props.color}
                d="M15.97 12.97a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l2.47-2.47-2.47-2.47a.75.75 0 0 1 0-1.06Z"
            />
            <path
                fill={props.color}
                d="M3.75 16.5a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 0 1.5h-15a.75.75 0 0 1-.75-.75Z"
            />
            <path
                fill={props.color}
                d="M8.03 3.97a.75.75 0 0 1 0 1.06L5.56 7.5l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 0 1 1.06 0Z"
            />
            <path
                fill={props.color}
                d="M3.75 7.5a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 0 1.5h-15a.75.75 0 0 1-.75-.75Z"
            />
        </g>

    </svg>
)
export default TransactionHistory
