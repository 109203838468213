import { Typography } from "@mui/material";
import bankMethod from "assets/images/bank-transfer.png";
import React from 'react';
import colors from 'styles/colors';
import { useThemeHook } from "styles/themeMudrex";
import { PaymentMethodNote } from 'types/payment-method';
import PaymentMethodNotes from '../PaymentMethodNotes';


export type BankPaymentMethodNotesProps = {
    bankName: string
    accountNumber: string
    notes: Array<PaymentMethodNote>
}

const BankPaymentMethodNotes: React.FC<BankPaymentMethodNotesProps> = ({ bankName, accountNumber, notes }) => {
    const [theme] = useThemeHook();

    return (

        <>
            <Typography
                style={{
                    fontSize: "14px",
                    fontWeight: 600,
                }}
            >
                Transfer using IMPS only from
            </Typography>
            <div style={{
                ...theme.gradientBackgroundGrey,
                paddingBottom: 16,
            }} >

                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                            style={{
                                display: "flex", marginTop: 4,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                alt="payment-method"
                                style={{
                                    height: 35,
                                    borderWidth: 1,
                                    borderColor: colors.Grey.borderGrey,
                                    borderRadius: 6,
                                }}
                                src={bankMethod}
                            />
                        </div>
                        <div
                            style={{
                                lineHeight: "150%",
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: 8,
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    lineHeight: "150%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontFeatureSettings:
                                        "'cv01' on, 'cv08' on, 'cv05' on",
                                    color: "black",
                                }}
                            >
                                {bankName}
                            </Typography>
                            <Typography variant="caption" color={colors.Grey.title}>
                                {accountNumber}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ marginTop: 8, marginLeft: 16 }} ><PaymentMethodNotes notes={notes} /></div>
                </div>
            </div>
        </>
    );
};

export default BankPaymentMethodNotes;