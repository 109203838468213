import { createTheme } from "@mui/material/styles";

const themeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#4350AF",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    fontFamily: "Inter",
    lineHeight: "150%",
    alert: {
      fontSize: 10,
      lineHeight: "150%",
    },
    b1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%",
    },
  },
});

export default themeOptions;
