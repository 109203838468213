import { useEffect, useState, useMemo } from 'react';
import { useGetPaymentMethods } from 'api/OnRamp/useGetPaymentMethods';
import { FiatCurrencyState } from './currency';
import { PAYMENT_METHODS } from 'constants/constants';
import useInputValue from 'types/input-value';

interface PaymentMethodOption {
  conversion_type: string;
  name: string;
  display_name: string;
  description: string;
  icon: string | null;
  mode: string;
  type: string;
  priority: number;
  subtext: object;
  input_upi_id: boolean;
}

interface PaymentMethod {
  enabled: boolean;
  options: PaymentMethodOption[];
  category_name: string;
  category_display_name: string;
  default: boolean;
  subtext: object;
}

type Props = {
  cryptoSymbol: string;
  fiatCurrencyState: FiatCurrencyState;
  kycCountry: string | undefined;
  paymentMethod: string;
};

export const usePaymentMethod = ({ cryptoSymbol, fiatCurrencyState, kycCountry, paymentMethod }: Props) => {
  const selectedPaymentMethod = useInputValue(paymentMethod, '', false, '');
  const selectedPaymentApp = useInputValue(selectedPaymentMethod.value, '');

  const selectedFiatCurrency = fiatCurrencyState?.fiatCurrency?.value;
  const currencyCode = selectedFiatCurrency?.symbol;

  const paymentMethods = useGetPaymentMethods({
    cryptoSymbol,
    currencyCode,
    kycCountry,
  });

  const paymentMethodValidation = useMemo(() => {
    if (!paymentMethods?.data || !paymentMethod) return undefined

    const isValidPaymentMethod = PAYMENT_METHODS.includes(paymentMethod);

    const enablePaymentMethods = Object.values(paymentMethods.data)
      .flatMap((method: PaymentMethod) => method.options.map((option: PaymentMethodOption) => option.name));

    const isEnabledPaymentMethod = enablePaymentMethods.includes(paymentMethod);

    return { isValid: isValidPaymentMethod, isEnabled: isEnabledPaymentMethod };
  }, [paymentMethods?.data, paymentMethod]);


  return {
    ...paymentMethods,
    selectedPaymentMethod,
    selectedPaymentApp,
    paymentMethodValidation,
  };
};
