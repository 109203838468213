import { createUseStyles } from "react-jss";
import colors from "styles/colors";

const styles = createUseStyles({
  container: {
    paddingHorizontal: 20,
  },
  text: { color: colors.Dark.title },
  resendCodeText: {},
  subheading: {
    marginBottom: 4,
  },
  defaultText: {},
  name: {},
  purpleText: {
    textAlign: "center",
    color: colors.Primary.background,
  },
  otpSentContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 6,
  },
  otpSentText: {
    marginLeft: 6,
  },
});

export default styles;
