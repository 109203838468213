
import { PhoneCountry, countryCodes } from 'constants/country_isd';
import { useState } from 'react';

export type PhoneCountryState = {
    selectedCountry: PhoneCountry
    countries: Array<PhoneCountry>
    setCountry: (country: PhoneCountry) => void
}

export const useCountry = (def: string = "IND"): PhoneCountryState => {
    const countries = countryCodes
    const [selectedCountry, setCountry] = useState<PhoneCountry>(countries.find((country) => country.iso3 === def) || countries[0])

    return { selectedCountry, countries, setCountry }

}