const getIdsFromParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const keys =  ["secret", "timestamp", "user_id", "client_id"];
  const [signature, timestamp, userId, clientId] = keys.map((key) =>
    searchParams.get(key)
  );

  if (signature && timestamp && userId && clientId) {
    return { signature, timestamp, userId, clientId };
  }
};


const getIdsFromPaths = () => {
  const { pathname } = new URL(window.location.href);
  // Check if the pathname contains the required substring
  if (!pathname.includes("kyc/digilocker")) {
    return undefined;
  }

  const ids = pathname.split("/").slice(-4);
  
  const signature = ids[0];
  const timestamp = ids[1];
  const userId = ids[2];
  const clientId = ids[3];

  if (signature && timestamp && userId && clientId) {
    return { signature, timestamp, userId, clientId };
  }

  return undefined;
};


const getInitParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hv_token = searchParams.get("hv_token");
  const mode = "DEV";

  const hmacData = getIdsFromPaths() ?? getIdsFromParams();

  if (hmacData) {
    return async () => {
      return { ...hmacData, mode, hv_token };
    };
  }
  return undefined;
};

export const init = getInitParams();
