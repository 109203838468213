import { createUseStyles } from "react-jss";
import colors from "styles/colors";
import { FontStyles } from "styles/fonts";

const styles = createUseStyles({
  title2: {
    marginTop: 20,
    ...FontStyles.title14SemiBold,
    color: colors.Dark.title,
  },
  detailsContainer: {
    paddingBottom: 20,
    borderRadius: 12,
    marginTop: 12,
    marginBottom: 20,
  },
  detailRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
});

export default styles;
