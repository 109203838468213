import { Typography } from "@mui/material";
import { LoadingView } from "components/shared/LoadingScreen";
import MButton from "components/shared/MButton";
import { FC } from "react";
import QRCode from "react-qr-code";

type QrCodeIntentProps = {
    inputAmount: number
    selectedPaymentApp: string
    url: string
    proceedToSuccess: () => void
}

export const QrCodeIntent: FC<QrCodeIntentProps> = (props) => {
    return (<div
        style={{
            display: 'flex',
            minHeight: '100%',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <Typography
            sx={{ fontSize: 16 }}
            style={{ fontWeight: 700, paddingTop: 3, paddingBottom: 3, width: '100%', textAlign: 'left' }}
        >
            {`Make payment of ₹${props.inputAmount}`}
        </Typography>
        <div
            style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                display: 'flex',
                flexDirection: 'column'
            }}>
            <Typography
                sx={{ fontSize: 14 }}
                style={{ fontWeight: 600, paddingBottom: 10 }}
            >
                {props.url ?
                    "Scan QR to Pay" : "Loading..."}
            </Typography>
            {props.url ?
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 4,
                        display: 'contents',
                    }} >
                    <QRCode
                        size={1024}
                        style={{ height: "auto", width: "60%" }}
                        value={props.url}
                        viewBox={`0 0 1024 1024`}
                    />
                </div> : <LoadingView />
            }
        </div>
        <Typography
            sx={{ fontSize: 14 }}
            style={{ fontWeight: 400, paddingTop: 3, paddingBottom: 3 }}
        >
            {`Made the payment? Please confirm,`}
        </Typography>
        <MButton
            onPress={props.proceedToSuccess}
            text={"I have transferred"}
            // size="medium"
            loading={false}
            disabled={!props.url} outlineButton={undefined}
        />

    </div>)
}