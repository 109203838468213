import React from "react";
import { Typography } from "@mui/material";
import gs from "styles/styles";
import MButton from "components/shared/MButton";
import MLink from "components/shared/Link";
import ConfirmRow from "components/shared/ConfirmRow";

const ConfirmBankAccount = ({
  onReenterClick,
  onConfirmClick,
  accountInfo,
  isLoading,
}) => {
  const { name, number, ifscCode } = accountInfo || {};
  const globalStyles = gs();
  return (
    <>
      <div className={globalStyles.dialogContainer}>
        <Typography
          variant="h10"
          sx={{ paddingX: 0, paddingY: 0 }}
          className={globalStyles.confirmText}
        >
          {"Please check and confirm your bank details"}
        </Typography>
        <div style={{ marginBottom: "1rem" }}>
          <ConfirmRow
            label={
              <>
                Account Holder Name <br /> (as per bank)
              </>
            }
            value={name}
          />
          <ConfirmRow label="Account Number" value={number} />
          <ConfirmRow label="IFSC Code" value={ifscCode} />
        </div>
        <MButton
          onPress={onConfirmClick}
          loading={isLoading}
          text="Continue"
          isPurpleButton
          size="medium"
        />
        <MLink text="Not your account? Edit" onClick={onReenterClick} />
      </div>
    </>
  );
};

export default ConfirmBankAccount;
