import { Typography } from '@mui/material';
import React from 'react';
import { PaymentMethodNote } from 'types/payment-method';

export type PaymentMethodNotesProps = {
    notes: Array<PaymentMethodNote>
}

const PaymentMethodNotes: React.FC<PaymentMethodNotesProps> = ({ notes }) => {
    return (
        <>
            <ul style={{ listStyleType: "disc" }}>
                {notes?.map((note, index) => <li key={index}><Typography fontSize={12}>{note?.text}</Typography></li>)}
            </ul>
        </>
    );
};

export default PaymentMethodNotes;