const MRow = ({ leftElement, rightElement }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {leftElement}
      {rightElement}
    </div>
  );
};

export default MRow;
