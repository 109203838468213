import { SVGProps } from "react"
const IcBuy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Z"
            clipRule="evenodd"
        />
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M11.959 8.292a.75.75 0 0 1 0 1.06L9.31 12l2.648 2.648a.75.75 0 0 1-1.061 1.06L7.72 12.53a.75.75 0 0 1 0-1.06l3.178-3.178a.75.75 0 0 1 1.06 0Z"
            clipRule="evenodd"
        />
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M7.5 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 12Z"
            clipRule="evenodd"
        />
    </svg>
)
export default IcBuy
