import React from "react";
import { Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import BottomSheet from "components/shared/BottomSheet";
import styles from "./styles";

const KycPending = ({ onExit, onStartKYC, showBottomSheet }) => {
  return (
    <BottomSheet
      isVisible={showBottomSheet}
      closeOnBackdropPress={false}
      dismissable={false}
      dragComponent={
        <div
          style={{
            alignItems: "center",
            paddingBottom: 30,
            paddingTop: 18,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              height: 3,
              width: 60,
              borderRadius: 10,
              backgroundColor: "#D9D9D9",
            }}
          />
        </div>
      }
      title="Your KYC is pending"
    >
      <div style={{ alignItems: "center", justifyContent: "center" }}>
        {/* <ErrorIcon height={100} width={100} /> */}
        <Typography
          style={{ paddingHorizontal: 20, marginTop: 20, marginBottom: 40 }}
        >
          {
            "Your KYC is Pending. Please complete your KYC to continue with your transaction"
          }
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingHorizontal: 40,
          }}
        >
          <div style={{ paddingRight: 5, flex: 1 }}>
            <MButton
              onPress={onStartKYC}
              text={"Start KYC"}
              isPurpleButton
              size="medium"
              customContainerStyle={styles.buttonStyle}
            />
          </div>
          <div style={{ paddingLeft: 5, flex: 1 }}>
            <MButton
              onPress={onExit}
              text={"Go Back"}
              isPurpleButton={false}
              size="medium"
              customContainerStyle={styles.buttonStyle}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default KycPending;
