import useCancellableNetworkRequest, { Response } from "hooks/networking/useCancellableNetworkRequest";
import { PaymentMethodConfig } from "types/payment-method";

export default function useGetPaymentMethodConfig(paymentMethod: string): Response<PaymentMethodConfig> {

    const requestConfig: any = {
        url: `v1/conversion/fiat/methods/${paymentMethod}/config`,
        method: "GET",
        service: "wallet",
        params: {},
        data: null,
    };

    return useCancellableNetworkRequest(requestConfig, [paymentMethod], true, PaymentMethodConfig)
}