import { createOrUpdateTransaction } from "api/OnRamp/createOrUpdateTxn";
import BaseLayout from "components/shared/BaseLayout";
import { useOnrampContext } from "context/OnRamp";
import StateEnum from "pages/onRamp/states";
import { FC, ReactNode, useEffect, useState } from "react";
import { OnrampType } from "types/OnRamp";
import { CheckoutAddCardComponent } from "./checkout-add-card";

type CheckoutContainerProps = {
  // init: any
  selectedCurrency: any
  inputAmount: number
  // finalAmountYouGet: number
  // paymentMethods: any
  // bankId: string
  // accountNumber: number
  // onSuccess: () => void
  external: boolean
  externalNetwork: string
  walletAddress: string
  // orderDetails: () => void
  // orderId?: string
  setPaymentDetails: (data: any) => void
  cryptoAmount: number
  setCurrentState: (state: any) => void
  transactionId: string
  // selectedPaymentApp: string
  selectedPaymentMethod: string
  priceLock: boolean
  orderDetails: any
}

export const CheckoutContainer: FC<CheckoutContainerProps> = (props) => {

  const { cryptoCurrencyState } = useOnrampContext()
  const cryptoCurrency = cryptoCurrencyState.value
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [generalError, setGeneralError] = useState("")

  const handleTransfer = async (token: string) => {
    setLoading(true);
    try {
      const buyTransaction = await createOrUpdateTransaction(
        props.external ? OnrampType.ONRAMP_AND_WITHDRAW : OnrampType.ONRAMP,
        props.priceLock,
        token,
        props.selectedCurrency?.symbol,
        cryptoCurrency?.symbol,
        props.inputAmount,
        props.cryptoAmount,
        props.selectedPaymentMethod,
        props.externalNetwork,
        props.walletAddress,
        undefined,
        undefined,
        undefined,
        props.transactionId
      )

      props.setPaymentDetails(buyTransaction.data);
      setLoading(false);

      return buyTransaction?.data?.id;
    } catch (err) {
      setLoading(false);
      setGeneralError("Please try again after some time.");
    }
  }

  useEffect(() => {
    if (!props.orderDetails) {
      return
    }

    if (props.orderDetails.status === "PROCESSING") {
      setGeneralError("");
    } else if (props.orderDetails.status === "FAILED") {
      props.setCurrentState(StateEnum.SUCCESS_SCREEN);
      setGeneralError(
        "Something went wrong, please try again after some time."
      );
    } else {
      const redirectUrl = props.orderDetails?._links?.redirect?.href
      if (redirectUrl) {
        
        const currentUrl = window.location.href
        window.sessionStorage.setItem('request_url', currentUrl)

        window.location.href = redirectUrl;
      } else {
        props.setCurrentState(StateEnum.SUCCESS_SCREEN);
      }
      setGeneralError("");
    }
    setProcessing(true);
  }, [props.orderDetails])

  let body: ReactNode
  if (props.orderDetails ? true : false) {
    body = <div />
  } else {
    body = <CheckoutAddCardComponent
      selectedCurrency={props.selectedCurrency}
      inputAmount={props.inputAmount}
      external={props.external}
      externalNetwork={props.externalNetwork}
      walletAddress={props.walletAddress}
      cryptoAmount={props.cryptoAmount}
      transactionId={props.transactionId}
      createTransaction={handleTransfer}
      selectedPaymentMethod={props.selectedPaymentMethod}
      priceLock={props.priceLock}
      generalError={generalError}
      setProcessing={setProcessing}
      processing={processing}
    />
  }

  return (<BaseLayout
    headerVisible={true}
    brandingVisible={false}
    body={body}
    footer={undefined}
    bottomSheet={undefined} />
  )
}