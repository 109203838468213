import React from "react";
import colors from "../../../styles/colors";
import { Typography } from "@mui/material";
import gs from "../../../styles/styles";

const ConfirmRow = ({ label, value }) => {
  const globalStyles = gs();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <Typography className={globalStyles.confirmText} style={{ fontSize: 12 }}>
        <strong>{label}</strong>
      </Typography>
      <Typography
        style={{ color: colors?.Dark?.title, fontSize: 12 }}
        className={globalStyles.confirmText}
      >
        <strong>{value}</strong>
      </Typography>
    </div>
  );
};

export default ConfirmRow;
