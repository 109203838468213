import React, { useLayoutEffect, useState } from "react";
import moment from "moment";
import { Typography, CircularProgress } from "@mui/material";
import colors from "styles/colors";
import Timer from "assets/svgs/timer.svg";
const CountdownTimer = ({ eventTime, onExpiry }) => {
  const currentTime = moment.utc().valueOf() / 1000;
  const [countdown, setCountdown] = useState(null);

  var leftTime = eventTime - currentTime;
  var duration = moment.duration(leftTime, "seconds");
  var interval = 1000;

  useLayoutEffect(() => {
    const intervalId = setInterval(function () {
      // Time Out check
      if (duration.asSeconds() <= 0) {
        onExpiry();
      }

      //Otherwise
      duration = moment.duration(duration.asSeconds() - 1, "seconds");
      setCountdown(duration.minutes() + "m " + duration.seconds() + "s");
      return () => clearInterval(intervalId);
    }, interval);
  }, []);

  return countdown ? (
    <div
      style={{
        padding: 5,
        background: "#FB8348",
        minWidth: 80,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 5,
        borderRadius: 2,
      }}
    >
      <img src={Timer} />
      <Typography
        fontSize={12}
        style={{ color: colors.Background.primary, minWidth: 52 }}
      >
        {countdown}
      </Typography>
    </div>
  ) : (
    <CircularProgress size={"1rem"} />
  );
};

export default CountdownTimer;
