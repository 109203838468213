import { Box, Radio, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountryIcon from 'assets/svgs/Icomoon';
import colors from "styles/colors";
import { FiatConversionWithCountry } from 'types/country';

interface CurrencyItemProps {
    currency: FiatConversionWithCountry;
    selectedCurrency: FiatConversionWithCountry;
    setSelectedCurrency: (currency: FiatConversionWithCountry) => void;
}

const CurrencyItem: React.FC<CurrencyItemProps> = ({ currency, selectedCurrency, setSelectedCurrency }) => {

    const theme = useTheme();

    // let color
    // if (transaction.status == TransactionStatus.COMPLETED) {
    //     color = theme.palette.success.light
    // } else if (transaction.status == TransactionStatus.FAILED) {
    //     color = theme.palette.error.light
    // } else {
    //     color = theme.palette.info.light
    // }

    return (
        <div>
            <Stack
                direction="column"
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    cursor: 'pointer'
                }}
                onClick={() => { setSelectedCurrency(currency) }}
            >
                <Stack spacing={1} direction="row" sx={{
                    display: "flex",
                    alignItems: 'center',
                }} >
                    <Box sx={{
                        height: 40, width: 40, padding: "8px",
                        borderRadius: "8px",
                        // background: alpha(color, 0.1)
                    }}>
                        <CountryIcon icon={currency?.iconId} size={24} />

                    </Box>
                    <Stack sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }} color={colors.Dark}>
                            {currency.symbol}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color={colors.Grey.title}>
                            {currency.countryName}
                        </Typography>
                    </Stack>
                    <Radio
                        size="small"
                        checked={selectedCurrency.symbol === currency.symbol}
                        value="b"
                        name="radio-buttons"
                        style={{ paddingLeft: 0 }}
                        disableRipple={true}
                    // inputProps={{ "aria-label": "B" }}
                    />
                </Stack>
            </Stack>
        </div>
    );

};

export default CurrencyItem;