import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import IconBackCircle from "assets/svgs/back-circle";
import IconCloseCircle from "assets/svgs/close-circle";
import ExpiryBottomsheet from "components/onRamp/ExpiryBottomsheet";
import { useBackStackContext } from "context/BackStackContext";
import { ConfigContext } from "context/ConfigContext";
import { useToast } from "hooks/useToast";
import React, { useContext, useState } from "react";
import { successThemeMap, useThemeHook } from "styles/themeMudrex";
import { EventLevel, sendEvent } from "utils/events/handler";
import Shield from "../../../assets/svgs/shield.svg";
import colors from "../../../styles/colors";
import MBottomSheet from "../BottomSheet";
import CountdownTimer from "../CountdownTimer";
import { InsideDrawer } from "../Drawer/InsideDrawer";
import MRow from "../MRow";
import { ScrollView } from "../ScrollView";
import MToast from "../Toast";
import { SideNav } from "../side-nav";
import styles from "./styles";

const BaseLayout = ({
  body,
  footer,
  bottomSheet,
  headerTitle = "",
  headerVisible = false,
  brandingVisible = true,
  forceShowBackButton = false,
}) => {

  const backstack = useBackStackContext()

  const toast = useToast();
  const [theme] = useThemeHook();
  const configContext = useContext(ConfigContext);
  const expiresAt = configContext?.expiresAt;
  const defaultWidth = 414;
  const defaultHeight = 750;
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var Android = /Android/.test(navigator.userAgent);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  const [isExpiryBottomsheetVisible, setIsExpiryBottomsheetVisibile] =
    useState(false);

  const onExpiry = () => {
    setIsExpiryBottomsheetVisibile(true);
  };

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowSidebar(open);
  };

  const onBack = () => {

    if (backstack && backstack.goBack()) {
      return
    } 

    if(configContext?.config?.features?.back_button?.reset_on_close ) {
      // reload the page
      window.location.reload()
    }

    sendEvent("BACK", EventLevel.INFO);
    window.parent.postMessage(
      {
        event: "back",
      },
      "*"
    );
  };

  const classes = styles();

  var showBackButton = true
  if (configContext?.config?.features?.back_button?.disabled === undefined) {
    showBackButton = false
  } else {
    showBackButton = !(configContext?.config?.features?.back_button?.disabled)
  }

  showBackButton = showBackButton || forceShowBackButton

  const showCloseButton = backstack.isTop
  return (
    <ScrollView
      sx={{
        placeItems: "center",
        justifyContent: "center",
        display: "grid",
        margin: "auto",
        overflow: "hidden",
        position: "relative",
        background: "#FFFFF",
      }}
    >
      <Box sx={{
        placeItems: "center",
        justifyContent: "center",
        display: "grid",
        margin: "auto",
        backgroundColor: "#F5F5F5",
        textAlign: "left",
      }} >
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            borderColor: "#FBFBFB",
            height:
              iOS || Android
                ? window.innerHeight
                : height > defaultHeight
                  ? "600px"
                  : height,

            width:
              iOS || Android
                ? window.innerWidth
                : width > defaultWidth
                  ? "400px"
                  : width
          }}
        >
          {toast?.toasts.map((toast) => (
            <MToast toast={toast} />
          ))}
          <div className={classes.childPadding}>
            {headerVisible && (
              <>
                <Stack
                  direction={"row"}
                  id="header"
                  style={{
                    padding: 20,
                    height: 70,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 15,
                    ...theme.headerBackground,
                  }}
                >
                  {showBackButton && (
                    showCloseButton ? (
                      <IconButton aria-label="close" onClick={onBack}>
                        <IconCloseCircle color={theme.gradientText} />
                      </IconButton>
                    ) : <IconButton aria-label="back" onClick={onBack}>
                      <IconBackCircle color={theme.gradientText} />
                    </IconButton>
                  )
                  }
                  <Typography
                    style={{
                      flexGrow: 1,
                      fontSize: "16px",
                      fontWeight: 600,
                      ...theme.gradientText,
                    }}
                  >
                    {headerTitle}
                  </Typography>
                  {configContext?.config?.features?.side_nav?.enabled && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleSidebar(true)}
                      edge="start"
                      sx={{ mr: 2, ...(showSidebar && { display: "none" }) }}
                    >
                      <MenuIcon
                        sx={{ color: theme.gradientText.color }}
                      />
                    </IconButton>)}
                </Stack>

                {expiresAt && (
                  <div
                    style={{
                      borderRadius: 0,
                      marginTop: 0,
                      padding: "10px 20px 10px 20px",
                      ...theme.bannerBackground,
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                  >
                    <MRow
                      leftElement={
                        <Typography fontSize={14}>
                          {`${configContext?.config?.messages?.expiry?.title}`}
                        </Typography>
                      }
                      rightElement={
                        <CountdownTimer
                          eventTime={expiresAt}
                          onExpiry={onExpiry}
                        />
                      }
                    />
                  </div>
                )}
              </>
            )}
            <div
              style={{
                padding: "20px",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                ...successThemeMap[
                configContext?.themeState?.currentState?.theme
                ],
              }}
            >
              <ScrollView
                sx={{
                  width: "100%",
                  height: "60%",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >{body}</ScrollView>
              {footer}
              {configContext?.config?.branding && brandingVisible && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    color: colors.Grey.title,
                  }}
                >
                  <Typography
                    variant="alert"
                    fontSize={10}
                    style={{ marginRight: 5, color: colors.Dark.title }}
                  >
                    {configContext?.config?.branding}
                  </Typography>
                  <img src={Shield} />
                </div>
              )}
            </div>
            {isExpiryBottomsheetVisible && (
              <MBottomSheet isVisible={isExpiryBottomsheetVisible}>
                <ExpiryBottomsheet />
              </MBottomSheet>
            )}
            {bottomSheet}
          </div>
          {configContext?.config?.features?.side_nav?.enabled && (
            <InsideDrawer
              sx={{ width: 256 }}
              anchor={"right"}
              open={showSidebar}
              onClose={toggleSidebar(false)}
            >
              <Box
                onClick={toggleSidebar(false)}
                onKeyDown={toggleSidebar(false)}
              >
                <SideNav />
              </Box>
            </InsideDrawer>
          )}
        </Box>
      </Box>
    </ScrollView >
  );
};

export default BaseLayout;
