import { PingDigilocker as pingDigilocker } from "api/Kyc/pingDigilocker";
import LoadingScreen from "components/shared/LoadingScreen";
import React, { useEffect, useState } from "react";
import AutocloseDigilocker from "pages/digilocker/autoclose-digilocker";

const Digilocker: React.FC = () => {
  const [statusUpdated, setStatusUpdated] = useState(false);
  useEffect(() => {
    const urlStr = window.location.href;
    const url = new URL(urlStr);
    const refId = url.searchParams.get("state");
    const error = url.searchParams.get("error");

    if (!refId) {
      return;
    }
    let digilockerStatus = error ? "REJECTED" : "APPROVED";

    const runAsync = async () => {
      await pingDigilocker(refId, digilockerStatus);
      const url = window.sessionStorage.getItem("request_url");
      if (url) window.location.href = url!;
      else {
        setStatusUpdated(true);
      }
    };

    runAsync();
  }, []);

  return statusUpdated ? <AutocloseDigilocker /> : <LoadingScreen />;
};

export default Digilocker;
