import { Alert, Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import MHeading from "components/shared/MHeading";
import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react';
import { FC, useEffect, useState } from "react";
import colors from "styles/colors";


type CheckoutAddCardComponentProps = {
    // init: any
    selectedCurrency: any
    inputAmount: number
    // finalAmountYouGet: number
    // paymentMethods: any
    // bankId: string
    // accountNumber: number
    // onSuccess: () => void
    external: boolean
    externalNetwork: string
    walletAddress: string
    // orderDetails: () => void
    // orderId?: string
    // setPaymentDetails: (data: any) => void
    cryptoAmount: number
    // setCurrentState: (state: any) => void
    transactionId: string
    createTransaction: (token: string) => void
    selectedPaymentMethod: string
    priceLock: boolean,
    processing: boolean,
    setProcessing: (processing: boolean) => void
    generalError: string
}

export const CheckoutAddCardComponent: FC<CheckoutAddCardComponentProps> = (props) => {

    const [bin, setBin] = useState<string | null>(null);
    const [cardToken, setCardToken] = useState<string | null>(null)

    const [cardError, setCardError] = useState<string | null>(null)
    const [cvvError, setCvvError] = useState<string | null>(null)
    const [expiryError, setExpiryError] = useState<string | null>(null)

    const [isCardValid, setIsCardValid] = useState<boolean>(false)

    useEffect(() => {
        if (cardToken != null) {
            props.createTransaction(cardToken)
        }
    }, [cardToken])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <div
                style={{
                    flex: 1
                }}
            >
                <Frames
                    config={{
                        debug: process.env.REACT_APP_STAGE !== "production",
                        publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY as string,
                        localization: {
                            cardNumberPlaceholder: 'Card number',
                            expiryMonthPlaceholder: 'MM',
                            expiryYearPlaceholder: 'YY',
                            cvvPlaceholder: 'CVV',
                        },
                        style: {
                            base: {
                                fontSize: '17px',
                            },
                        },
                    }}
                    frameValidationChanged={(e) => {
                        console.log('Frame Validation Changed', e)
                        if (e.element === "card-number") {
                            setCardError(null);
                            if (
                                e.isEmpty === false &&
                                e.isValid === false
                            ) {
                                setCardError("Invalid card number");
                                props.setProcessing(false);
                            }
                        }
                        if (e.element === "expiry-date") {
                            setExpiryError(null);
                            if (
                                e.isEmpty === false &&
                                e.isValid === false
                            ) {
                                setExpiryError("Invalid expiry date");
                                props.setProcessing(false);
                            }
                        }
                        if (e.element === "cvv") {
                            setCvvError(null);
                            if (
                                e.isEmpty === false &&
                                e.isValid === false
                            ) {
                                setCvvError("Invalid cvv");
                                props.setProcessing(false);
                            }
                        }
                    }}
                    paymentMethodChanged={(e) => {
                        console.log('Payment Method Changed', e)
                    }}
                    cardValidationChanged={(e) => {
                        console.log('Card Validation Changed', e)
                        setIsCardValid(e.isValid)
                    }}
                    cardSubmitted={() => {
                        console.log('Card Submitted')
                        props.setProcessing(true)
                    }}
                    cardTokenized={(e) => {
                        setCardToken(e?.token ?? null);
                    }}
                    cardTokenizationFailed={(e) => {
                        props.setProcessing(false);
                    }}
                    cardBinChanged={(e) => {
                        setBin(e?.bin ?? null);
                    }}
                >
                    <MHeading
                        heading={"Add Credit/Debit Card"} subHeading={undefined} />
                    <Typography
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.Grey.title,
                            marginBottom: 8,
                            marginTop: 24
                        }}
                    >
                        {"Card Number"}
                    </Typography>

                    <CardNumber
                        style={{
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: colors.Grey.title,
                            height: "68px",
                            padding: "12px"
                        }}
                    />
                    {cardError && <Typography
                        sx={{ marginTop: "8px" }}
                    >
                        <Alert severity="error">{cardError}</Alert>
                    </Typography>}

                    <Typography
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.Grey.title,
                            marginBottom: 8,
                            marginTop: 24
                        }}
                    >
                        {"Expiry Date"}
                    </Typography>
                    <ExpiryDate style={{
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: colors.Grey.title,
                        height: "68px",
                        padding: "12px"
                    }} />
                    {expiryError && <Typography
                        sx={{ marginTop: "8px" }}
                    >
                        <Alert severity="error">{expiryError}</Alert>
                    </Typography>}

                    <Typography
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.Grey.title,
                            marginBottom: 8,
                            marginTop: 24
                        }}
                    >
                        {"CVV"}
                    </Typography>
                    <Cvv style={{
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: colors.Grey.title,
                        height: "68px",
                        padding: "12px"
                    }} />
                    {cvvError && <Typography
                        sx={{ marginTop: "8px" }}
                    >
                        <Alert severity="error">{cvvError}</Alert>
                    </Typography>}
                </Frames>

            </div>
            {props.generalError && <Typography
                sx={{ marginTop: "8px" }}
            >
                <Alert severity="error">{props.generalError}</Alert>
            </Typography>}
            <MButton
                sx={{ marginTop: "48px" }}
                onPress={() => Frames.submitCard()}
                text={"Proceed"}
                // @ts-ignore
                isPurpleButton
                size="medium"
                loading={props.processing}
                // @ts-ignore
                disabled={props.processing || cardError || cvvError || expiryError || !isCardValid}
            />

        </div>
    )

}