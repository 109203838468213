import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  container: {
    padding: 20,
    flex: 1,
  },
  title: {
    marginBottom: 12,
  },
  title2: {
    marginTop: 20,
    marginBottom: 12,
  },
  subtitle1: {},
  detailsContainer: {
    padding: 20,
    borderRadius: 12,
    marginTop: 14,
    marginBottom: 24,
  },
  detailRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonStyle: {
    borderRadius: 30,
    paddingVertical: 10,
    marginVertical: 8,
    marginHorizontal: 20,
  },
});

export default styles;
