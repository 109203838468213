import { createUseStyles } from "react-jss";
import colors from "../../../styles/colors";

const styles = createUseStyles({
  steps: {
    color: colors.Grey.title,
    marginBottom: 4,
    textAlign: "start",
  },
});

export default styles;
