import useGetCryptoNetworks from "api/OnRamp/useGetCryptoNetworks";
import { useConfig } from "context/ConfigContext";
import { Authenticated } from "context/auth/auth-context";
import { useAuth } from "context/auth/use-auth";
import { useEffect, useMemo } from "react";
import { CryptoNetwork } from "types/CryptoNetwork";
import useInputValue, { InputValue } from "types/input-value";

export type WithdrawNetworkState = {
    cryptoNetworks: CryptoNetwork[];
    selectedNetwork: InputValue<CryptoNetwork> | null;
};

export default function useGetWithdrawNetworkState(coin: string, presetSymbol?: string, fixedPreset: boolean = false): WithdrawNetworkState {
    const { data } = useGetCryptoNetworks(coin);
    
    const config = useConfig()?.config

    const isAuthenticated = useAuth()?.state instanceof Authenticated;

    const allowedNetworks = config?.features?.withdraw?.allowed_networks ?? [];

    const allCryptoNetworks: Array<CryptoNetwork> = data?.[0]?.networkList ?? [];
    const defaultNetwork = isAuthenticated ? allowedNetworks[0] : "MATIC";
    const cryptoId = useInputValue(presetSymbol, defaultNetwork, false, "withdraw_network");

    const cryptoNetworks = isAuthenticated ? allCryptoNetworks.filter((network) => allowedNetworks.includes(network.id)) : allCryptoNetworks;

    const presetNetwork = useMemo(() => allCryptoNetworks?.find((network) => network.id === cryptoId.preset), [cryptoId.preset, allCryptoNetworks]);
    const selectedNetwork = useMemo(() => allCryptoNetworks?.find((network) => network.id === cryptoId.value), [cryptoId.value, allCryptoNetworks]);

    useEffect(() => {
        if (!isAuthenticated || allowedNetworks.length === 0 || fixedPreset) {
            return;
        }

        if (!allowedNetworks.includes(cryptoId.value)) {
            cryptoId.setValue!(allowedNetworks[0]);
        }

    }, [isAuthenticated, allowedNetworks]);

    let setValue: ((value: CryptoNetwork) => void) | null = null
    if (cryptoId.setValue) {
        setValue = ((value: CryptoNetwork) => cryptoId.setValue!(value.id))
    }

    let selectedNetworkValue: InputValue<CryptoNetwork> | null = null
    if (selectedNetwork) {
        selectedNetworkValue = {
            value: selectedNetwork,
            setValue: setValue,
            preset: presetNetwork ?? null,
            locked: cryptoId.locked,
            setLocked: cryptoId.setLocked,
            fixedPreset: fixedPreset
        }

    }

    return { cryptoNetworks: cryptoNetworks, selectedNetwork: selectedNetworkValue };
}