import { ThemeProvider } from "@mui/material/styles";
import { AuthContextProvider } from "context/auth/issuer-context";
import Caas from "pages/caas";
import { useSearchParams } from "react-router-dom";
import themeOptions from "styles/theme";

const OffRamp = ({ }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const signature = searchParams.get("token")
  const timestamp = searchParams.get("ts")
  const userId = searchParams.get("user_id")
  const clientId = searchParams.get("client_id")
  const mode = "DEV"
  const hv_token = searchParams.get("hv_token")

  let init

  if (signature && timestamp && userId && clientId && mode && hv_token) {
    init = async () => {
      return { signature, timestamp, userId, clientId, mode, hv_token };
    };
  }

  const version = searchParams.get("version") ?? "0"
  window.sessionStorage.setItem("client_version", version);

  return (
    <>
      <AuthContextProvider init={init}
        clientId={searchParams.get("client_id")}
      >
        <ThemeProvider theme={themeOptions}>
          <Caas
            init={init}
            sdkConfiguration={{
              steps: ["offramp"],
              kycEnabled: true,
              bankEnabled: true,
            }}
            onKYCSuccessCallback={() => { }}
            onRampSuccessCallback={() => { }}
            offRampSuccessCallback={() => { }}
            onExceptionCallback={() => {
              window.location.reload();
            }}
            exceptionCTA={"Try again"}
            startCustomKYCCallback={() => { }}
            onBack={() => { }}
            presetKycCountry={searchParams.get("kyc_country") ?? "IND"}
            fixedPresetKycCountry={!(searchParams.get("show_kyc_country_picker") ?? false)}
          />
        </ThemeProvider>
      </AuthContextProvider>
    </>
  );
};

export default OffRamp;
