import { useEffect, useMemo, useState } from "react";

export type InputValue<T> = {
    value: T;
    setValue: ((value: T) => void) | null
    preset: T | null;
    fixedPreset: boolean;
    locked: boolean
    setLocked: ((value: boolean) => void) | null;
}

const useInputValue = <T,>(preset: T | undefined, def: T, fixedPreset: boolean = false, key?: string): InputValue<T> => {

    const storedValue = useMemo(() => key ? JSON.parse(window.sessionStorage.getItem("input_" + key) ?? "null") ?? null : null, [])

    const [value, setValue] = useState(preset ?? storedValue ?? def)
    const setValueOrNull = fixedPreset ? null : setValue
    const [locked, setLocked] = useState(fixedPreset)
    const setLockedOrNull = fixedPreset ? null : setLocked

    useEffect(() => {
        if (key) {
            window.sessionStorage.setItem("input_" + key, JSON.stringify(value))
        }

    }, [value])

    return { value, setValue: setValueOrNull, preset: preset ?? null, locked, setLocked: setLockedOrNull, fixedPreset };
};

export default useInputValue;