import { Expose, Type } from "class-transformer"
import { Country } from "./country"

export class User {

    @Expose({ name: "user_uuid" }) userId: string = ""
    @Expose({ name: "name" }) name: string = ""
    @Expose({ name: "email" }) email: string = ""
    @Expose({ name: "phone" }) phone: string = ""
    @Expose({ name: "kyc_status" }) kycStatus: string = ""
    @Expose({ name: "bank_accounts" }) bankAccounts: Array<BankAccount> = []
    @Expose({ name: "phone_country" }) @Type(() => Country) phoneCountry: Country | null = null
    @Expose({ name: "kyc_country" }) @Type(() => Country) kycCountry: Country | null = null

    isKycVerified(): boolean {
        return this.kycStatus.toUpperCase() == "VERIFIED"
    }

    hasValidBankAccount(): boolean {
        return this.bankAccounts.length > 0
    }

    hasValidEmail(): boolean {
        return this.email.length > 0
    }

    hasValidPhone(): boolean {
        return this.phone.length > 0
    }

    isAllowedToTransact(requirePhone: boolean, requireEmail: boolean, requireBank: boolean): boolean {
        return this.isKycVerified()
            && (!requirePhone || this.hasValidPhone())
            && (!requireEmail || this.hasValidEmail())
            && (!requireBank || this.hasValidBankAccount())
    }

    getCountry(): Country | null {
        if (this.kycCountry?.iso3) {
            return this.kycCountry
        } else if (this.phoneCountry?.iso3) {
            return this.phoneCountry
        }

        return null
    }
}

export type BankAccount = {
    account_holder_name: string;
    ifsc_code: string;
    status: string;
    account_number: string;
    bank_id: string;
}