import { Box, Typography } from '@mui/material';
import FailedIcon from "assets/svgs/onrampfailed.svg";
import { InsideDrawer } from 'components/shared/Drawer/InsideDrawer';
import MHeading from 'components/shared/MHeading';
import React from 'react';


type InsideDrawerBottomSheetProps = {
    showDrawer: boolean;
    heading: string;
    description: string
};

const InsideDrawerBottomSheet: React.FC<InsideDrawerBottomSheetProps> = (
    { showDrawer, heading, description }

    ) => {

    return (
        <InsideDrawer
            anchor={"bottom"}
            open={showDrawer}
            onClose={() => { }}
            PaperProps={{
                square: false,
                sx: {
                    height: 'calc(40%)',
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
            }}
        >
            <Box
                component="section"
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        zIndex: 1,
                    }}
                >
                    <MHeading heading={heading} subHeading={undefined} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '16px',
                        }}>
                        <img style={{ height: 60 }} src={FailedIcon} /></Box>
                        <Typography sx={{ paddingLeft: 2, paddingRight: 2 }}>{description}</Typography>
                </Box>
            </Box>
        </InsideDrawer>
    );
};

export default InsideDrawerBottomSheet;
