import { createContext } from 'react';

export const STEP_KYC = 1
export const STEP_ONRAMP = 2
export const STEP_TRANSACTION_HISTORY = 6

export const sdkSteps = {
    loading: 0,
    kyc: STEP_KYC,
    onramp: STEP_ONRAMP,
    offramp: 3,
    exception: 4,
    success: 5,
    transaction_history: STEP_TRANSACTION_HISTORY,
    profile: 7
};

type NavContextState = {
    setCurrentStep: (step: number) => void;
    currentStep: number;
};

export const NavContext = createContext<NavContextState>({
    setCurrentStep: (step: number) => { },
    currentStep: 0,
});