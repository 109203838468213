import { Box, Divider, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import IcSearch from 'assets/svgs/search';
import { InsideDrawer } from 'components/shared/Drawer/InsideDrawer';
import KycTextInput from 'components/shared/KycTextInput';
import MHeading from 'components/shared/MHeading';
import { useOnrampContext } from 'context/OnRamp';
import FuzzySearch from 'fuzzy-search';
import React, { useState } from 'react';
import colors from 'styles/colors';
import { FiatConversionWithCountry } from 'types/country';
import CurrencyItem from './CurrencyItem';

type FiatCurrencyPickerBottomSheetProps = {
    showDrawer: boolean;
    toggleDrawer: (show: boolean) => void;
};

const FiatCurrencyPickerBS: React.FC<FiatCurrencyPickerBottomSheetProps> = (
    { showDrawer, toggleDrawer }
) => {

    const { fiatCurrencyState } = useOnrampContext()
    const currencyList = fiatCurrencyState.currencyList
    const selectedCurrency = fiatCurrencyState.fiatCurrency?.value
    const setCurrency = fiatCurrencyState.fiatCurrency?.setValue

    const [searchQuery, setSearchQuery] = useState('');
    const searcher = new FuzzySearch(currencyList, ['symbol', 'countryName', 'currencyName'], { sort: true });

    const filterResult: Array<FiatConversionWithCountry> = searcher.search(searchQuery);

    const closeDrawer = () => {
        toggleDrawer(false)
    }

    const setSelected = (country: FiatConversionWithCountry) => {
        setCurrency?.(country);
        setSearchQuery('');
        closeDrawer();
    }

    return (
        <InsideDrawer
            anchor={"bottom"}
            open={showDrawer}
            onClose={closeDrawer}
            PaperProps={{
                square: false,
                sx: {
                    height: 'calc(100% - 64px)',
                    top: 64,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                },
            }}
        >
            <Box
                component="section"
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        // borderBottom: '1px solid #E0E0E0',
                        zIndex: 1,
                    }}
                >
                    <MHeading heading={"Choose Currency"} subHeading={undefined} />
                    {/*@ts-ignore */}
                    <KycTextInput
                        placeholder={""}
                        text={searchQuery}
                        setText={setSearchQuery}
                        leading={<IcSearch width={24} height={24} />}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1
                    }}
                >
                    <Typography sx={{ paddingLeft: 2, paddingRight: 2 }}>
                        Selected Currency
                    </Typography>
                    <CurrencyItem
                        key={selectedCurrency!.symbol}
                        currency={selectedCurrency!}
                        selectedCurrency={selectedCurrency!}
                        setSelectedCurrency={setSelected}
                    />

                    <div style={{ height: 8, width: "100%", background: alpha(colors.Primary.background, 0.1) }} />
                    <Typography sx={{ marginTop: 2, paddingLeft: 2, paddingRight: 2 }}>
                        Other Currencies
                    </Typography>
                    {filterResult && filterResult.map((currency) => (
                        <>
                            <CurrencyItem
                                key={currency.symbol}
                                currency={currency}
                                selectedCurrency={selectedCurrency!}
                                setSelectedCurrency={setSelected}
                            />
                            <Divider />
                        </>
                    ))}
                </Box>
            </Box>
        </InsideDrawer>
    );
};

export default FiatCurrencyPickerBS;