import { createUseStyles } from "react-jss";
import colors from "styles/colors";
import { FontStyles } from "styles/fonts";

const styles = createUseStyles({
  container: {
    paddingTop: 24,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignContent: "center",
  },
  heading: { fontSize: 14, color: colors.Grey.title },
  detailsContainer: {
    paddingVertical: 18,
    marginTop: 14,
  },
  confirmText: {
    fontSize: 14,
    color: colors.Grey.title,
  },
  defaultText: { color: colors.Dark.title },
  name: {
    marginTop: 10,
    fontSize: 16,
    color: colors.Dark.title,
    ...FontStyles.button16Medium,
  },
});

export default styles;
