import InsideDrawerBottomSheet from 'components/shared/InsideDrawerBottomSheet';
import { useOnrampContext } from 'context/OnRamp';
import React from 'react';

type InvalidNetworkBottomSheetProps = {
    showDrawer: boolean;
};

const InvalidNetworkBS: React.FC<InvalidNetworkBottomSheetProps> = ({ showDrawer }) => {
    const { withdrawNetworkState } = useOnrampContext();

    const selectedNetwork = withdrawNetworkState.selectedNetwork?.value?.name;
    const alternate = withdrawNetworkState.cryptoNetworks?.[0]?.name;

    return (
        <InsideDrawerBottomSheet 
            showDrawer={showDrawer} 
            heading='Invalid Network' 
            description={`Payments are currently not accepted on the ${selectedNetwork} network. Please use ${alternate}.`} 
        />
    );
};

export default InvalidNetworkBS;
