import useNetworkRequest, { Response } from "hooks/networking/useNetworkRequest";
import { FiatConversionWithCountry } from "types/country";



export function useGetConversionOptions(): Response<Array<FiatConversionWithCountry>> {
    const requestConfig: any = {
        url: 'v1/conversion/fiat',
        method: "GET",
        service: "wallet",
        params: {
            type: "buy",
        },
        data: null,
    };
    // @ts-ignore
    return useNetworkRequest(requestConfig, [], true, false, FiatConversionWithCountry)
}