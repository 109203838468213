import axios from "../utils/axiosInstance";

export const fetchCurrenciesList = async ({ init }) => {
  const result = await axios({
    url: "v1/conversion/fiat/",
    method: "GET",
    service: "wallet",
    params: {
      type: "sell",
    },
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const fetchAmountYouGetDetails = async ({ init, fiatSymbol }) => {
  const result = await axios({
    url: `v1/conversion/fiat/price?fiat=${fiatSymbol}&crypto=USDT&type=sell`,
    service: "wallet",
    method: "GET",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const fetchLimits = async ({ init, inputAmount }) => {
  const result = await axios({
    url: `/common/validate?event=withdraw&coin=USDT&amount=${inputAmount}`,
    method: "GET",
    service: "wallet",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const sellFiat = async ({ init, data }) => {
  const result = await axios({
    url: `v1/conversion/fiat/sell`,
    method: "POST",
    service: "wallet",
    data,
    init: init,
  });

  return result?.data;
};

export const fetchConversionMethods = async ({
  init,
  fiatSymbol,
  kycCountry,
}) => {
  const result = await axios({
    url: `v1/conversion/fiat/methods?fiat=${fiatSymbol}&crypto=USDT&type=sell&country=${kycCountry}`,
    service: "wallet",
    method: "GET",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const fetchBalance = async ({ init }) => {
  const result = await axios({
    url: `balance`,
    service: "walletCaas",
    method: "GET",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};
