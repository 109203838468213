import Avatar from '@mui/material/Avatar';
import { FC } from 'react';
import { sanitised } from 'utils/string';

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(text?: string) {

    return {
        sx: {
            bgcolor: stringToColor(sanitised(text, "Mudrex")),
        },
        children: `${text?.split(' ')?.[0]?.[0] ?? ""}`,
    };
}

interface StringAvatarProps {
    text?: string
}

export const StringAvatar: FC<StringAvatarProps> = (props) => {
    const { text } = props;
    return (
        <Avatar {...stringAvatar(text)} />
    );
};