import IcoMoon from "react-icomoon";
import iconSet from "./icomoon-selection.json";

const CountryIcon = props => {
    return <IcoMoon iconSet={iconSet} {...props} />;
};

CountryIcon.defaultProps = {
    size: 16
};

export default CountryIcon;
