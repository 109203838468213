import { AutoClose } from 'components/shared/AutoClose';
import React from 'react';
import { closeWindow } from 'utils/closeWindow';

const Digilocker: React.FC = () => {

    return (
        <AutoClose
            delayInSeconds={3}
            runner={closeWindow}
        />
    );
};

export default Digilocker;  