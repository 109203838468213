import { FC, useEffect, useState } from "react";
import CountDownScreen from "./CountDownScreen";

export type AutoCloseProps = {
    delayInSeconds: number
    runner: () => void
}

export const AutoClose: FC<AutoCloseProps> = ({ delayInSeconds, runner }) => {

    const [delay, setDelay] = useState(delayInSeconds)

    useEffect(() => {

        if (delay <= 0) {
            runner()
        }

        const timer = setTimeout(() => {
            setDelay(delay - 1)
        }, 1000);

        return () => clearTimeout(timer);
    }, [delay])

    return <CountDownScreen delay={delay}/>
};