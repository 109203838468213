import { Alert, Typography } from "@mui/material";
import PaymentMethod from "components/onRamp/PaymentMethods";
import BaseLayout from "components/shared/BaseLayout";
import LoadingScreen from "components/shared/LoadingScreen";
import MButton from "components/shared/MButton";
import { useConfig } from "context/ConfigContext";
import { useOnrampContext } from "context/OnRamp";
import { useState } from "react";
import { useThemeHook } from "styles/themeMudrex";
import styles from "./styles";

const ChoosePaymentsMethod = ({
  methods,
  isLoadingMethods,
  fiatAmount,
  onClickMap,
  loading,
  error
}) => {
  const [theme] = useThemeHook();
  const [method, setMethod] = useState(null);

  const { fiatCurrencyState } = useOnrampContext()
  const selectedCurrency = fiatCurrencyState.fiatCurrency?.value

  const config = useConfig()
  const enabledModes = config?.config?.features?.payment_method?.modes ?? []

  return (
    <>
      {!isLoadingMethods ? (
        <BaseLayout
          theme={"primary"}
          headerTitle={"Payment Method"}
          headerVisible={true}
          body={
            <div id="body">
              <div style={styles.container}>
                {Object.keys(methods).filter((key) => methods[key] !== undefined).map((key) => {
                  return (
                    methods[key].enabled && enabledModes.includes(key) && (
                      <div
                        style={{ ...theme.gradientBackgroundGrey }}
                        key={key}
                      >
                        <PaymentMethod
                          method={methods[key]}
                          methodName={key}
                          onClick={(categoryName, methodName) => {
                            setMethod({ categoryName, methodName });
                          }}
                          currentMethod={method?.methodName}
                          background={theme.gradientBackgroundGrey}
                        />
                        {/* } */}

                      </div>)
                  );
                })}
              </div>
            </div>
          }
          footer={
            <div
              style={{
                paddingTop: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <strong>{`Buying at\n`}</strong>
                  <strong>{`${selectedCurrency.currencySymbol} ${fiatAmount}`}</strong>
                </Typography>
              </div>
              <div style={{ width: "40%" }}>
                <MButton
                  text={"Continue"}
                  disabled={!method}
                  onPress={() => {
                    onClickMap(method.categoryName, method.methodName);
                  }}
                  loading={loading}
                />
              </div>
            </div>
          }

        />
      ) : (
        <LoadingScreen />
      )}
      {error && (
        <div style={{ marginBottom: 10 }}>
          <Alert severity="error">
            <Typography variant="alert">{error}</Typography>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ChoosePaymentsMethod;
