import { createUseStyles } from "react-jss";
import colors from "styles/colors";
import { FontStyles } from "styles/fonts";

const styles = createUseStyles({
  colorGrey: {
    color: colors.Grey.title,
  },
  colorPurple: {
    color: colors.Primary.background,
  },
  topContainer: {},
  container: {
    padding: 24,
    position: "relative",
    height: "100%",
  },
  header: {
    flexDirection: "row",
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 14,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: colors.Grey.borderGrey,
  },
  headerText: {
    ...FontStyles.subtitle14SemiBold,
    color: colors.Grey.title,
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: colors.Grey.borderGrey,
  },
  amount: {
    color: colors.Dark.title,
  },
  amountInput: {},
  label: {
    ...FontStyles.caption13Medium,

    marginBottom: 10,
  },
  input: {
    ...FontStyles.title24SemiBold,

    marginLeft: 10,
    height: "100%",
    width: "100%",
  },
  upiInput: {
    ...FontStyles.subtitle16Medium,
    height: "100%",
    width: "80%",
  },
  verifyContainer: {
    borderWidth: 1,
    borderColor: colors.Grey.borderGrey,
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 4,
  },
  UTRInput: {
    ...FontStyles.subtitle16Regular,
    height: "100%",
    width: "100%",
    // borderBottomWidth: 1,
    // borderColor: colors.Grey.borderGrey
  },
  fullWidthInput: {
    ...FontStyles.subtitle16Medium,
    width: "100%",
    height: "100%",
  },
  inputGroup: {
    ...FontStyles.input16Medium,
  },
  upiInputGroup: {
    ...FontStyles.subtitle14Medium,
    color: colors.Primary.background,
  },
  rateInfo: {
    ...FontStyles.caption13Medium,
    marginTop: 24,
    color: colors.Grey.title,
    textAlign: "center",
  },
  disabledButton: {
    pointerEvents: "none",
    borderRadius: 26,
    marginTop: 24,
    height: 48,
  },
  primaryButtonPosition: {
    // position: "absolute",
    // bottom: 75,
    width: "100%",
    // left: 24
  },
  transferredButtonContainer: {
    marginTop: 10,
    paddingHorizontal: 20,
  },
  primaryButton: {
    backgroundColor: colors.Primary.background,
    borderRadius: 26,
    marginTop: 24,
    height: 48,
  },
  secondaryButton: {
    backgroundColor: "transparent",
    borderRadius: 26,
    marginTop: 24,
    height: 48,
    borderWidth: 2,
    borderColor: colors.Primary.background,
  },
  primaryButtonText: {
    ...FontStyles.button16Medium,
    color: "#FDFDFD",
    textAlign: "center",
  },
  secondaryButtonText: {
    ...FontStyles.button16Medium,
    color: colors.Primary.background,
    textAlign: "center",
  },
  primaryButtonSuccess: {
    backgroundColor: colors.Primary.background,
    borderRadius: 26,
    marginTop: 24,
    height: 48,
    position: "absolute",
    bottom: 35,
    left: 0,
    width: "100%",
  },
  positionBottom: {
    position: "absolute",
    left: 24,
    bottom: 75,
    width: "100%",
  },
  conversionLabel: {
    ...FontStyles.caption13Medium,
  },
  conversionAmount: {
    ...FontStyles.title24SemiBold,

    marginTop: 4,
  },
  vpaInfo: {
    paddingVertical: 18,
    paddingHorizontal: 20,
    marginTop: 14,
    borderRadius: 12,
  },
  vpaLabel: {
    ...FontStyles.caption12Regular,
  },
  vpaValue: {
    ...FontStyles.subtitle14Medium,
    marginTop: 4,
  },
  note: {
    backgroundColor: "#eee9f2",
    paddingHorizontal: 20,
    paddingVertical: 14,
    borderRadius: 12,
  },
  noteText: {
    ...FontStyles.caption12Medium,
    color: colors.Primary.background,
    marginLeft: 8,
  },
  bankAccountLabel: {
    ...FontStyles.subtitle14Medium,
    color: colors.Grey.title,
    marginBottom: 16,
  },
  bankDetails: {
    borderWidth: 1,
    borderColor: colors.Dark.title,
    borderRadius: 6,
    padding: 14,
  },
  kycDetails: {
    ...FontStyles.caption13Medium,
    color: colors.Grey.title,
    marginTop: 6,
  },
  accountNumber: {
    ...FontStyles.subtitle16Medium,
  },
  deleteAccount: {
    cursor: "pointer",
  },
  positionAbsoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: 50 }, { translateY: 50 }],
  },
  errorText: {
    ...FontStyles.caption12Regular,

    marginTop: 12,
  },
  captionPayments: {
    marginRight: 14,
    ...FontStyles.caption13Medium,
    color: colors.Primary.background,
  },
  paymentContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingVertical: 18,
    borderWidth: 1,
    borderColor: colors.Grey.borderGrey,
    borderRadius: 12,
  },
  paymentTitle: {
    ...FontStyles.subtitle14SemiBold,
  },
  colorProgress: {},
  txHistory: {
    paddingHorizontal: 24,
    paddingVertical: 14,
  },
  successLabel: {
    ...FontStyles.body13Regular,
    color: colors.Grey.title,
    width: "50%",
  },
  successValue: {
    ...FontStyles.caption13Medium,
    width: "50%",
  },
  image: {
    resizeMode: "contain",
  },
  timer: {
    width: 18,
    height: 18,
  },
  username: {
    ...FontStyles.caption12Regular,
    color: colors.Grey.title,
    marginTop: 11,
  },
  upiTimer: {
    height: 260,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // borderWidth: 1,
    // borderColor: "red",
    margin: "auto",
    textAlign: "center",
  },
  ticks: {
    ...FontStyles.title38SemiBold,
    color: colors.Primary.background,
  },
  upiRequest: {
    borderWidth: 1,
    borderColor: colors.Grey.borderGrey,
    backgroundColor: colors.Grey.title,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 18,
  },
  paytmImage: {
    width: 42,
    height: 42,
  },
  neftNote: {
    color: colors.Primary.background,
    borderRadius: 12,
  },
  neftNoteContainer: {
    marginLeft: 20,
    marginTop: 16,
    marginRight: 20,
    marginBottom: 0,
    backgroundColor: "#EC786E10",
    borderRadius: 12,
  },
  neftNoteText: {
    lineHeight: 18,

    color: "#EC786E",
    padding: 16,
    fontSize: 12,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  belowHeaderStrip: {
    backgroundColor: "#FFF2CE",
    paddingHorizontal: 16,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  earnText: {
    color: "#CB9600",

    fontSize: 12,
  },
  knowMoreText: {
    color: "#4B2880",

    fontSize: 12,
  },
  startWithContainer: {
    marginTop: 32,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#F7F7F7",
    padding: 14,
  },
  startWithText: {
    textAlign: "center",
    color: "#7B8793",

    fontSize: 12,
  },
  goBackHeader: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: "#EFEFEF",
    borderWidth: 1,
    marginBottom: 34,
  },
  goBackTextContent: {
    marginTop: 16,
    marginBottom: 32,

    color: "#1E1623",
    textAlign: "center",
    fontSize: 16,
    lineHeight: 24,
  },
  goBackSheetContent: {
    paddingHorizontal: 16,
    paddingBottom: 24,
    alignItems: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    alignContent: "center",
  },
  goBackSheetButtonLeft: {
    flex: 1,
    marginRight: 8,
  },
  goBackSheetButtonRight: {
    flex: 1,
    marginLeft: 8,
  },
  sepaNoteContainer: {
    backgroundColor: "#F9D26415",
    padding: 16,
    borderRadius: 12,
    marginTop: 24,
  },
  sepeNoteHeader: {
    fontSize: 12,
    color: "#E7B835",
    marginBottom: 4,
  },
  sepaNote: {
    color: "#1E1623",

    fontSize: 12,
  },
  depositAccountContainer: {
    marginTop: 8,
    background:
      "linear-gradient(161.38deg, rgba(242, 225, 254, 0.1) 41.6%, rgba(199, 223, 255, 0.1) 74.8%), linear-gradient(71.55deg, rgba(242, 225, 254, 0.1) 40.97%, rgba(199, 223, 255, 0.1) 74.98%), #FFFFFF",
    borderRadius: 12,
    padding: 16,
    paddingBottom: 0,
  },
});

export default styles;
