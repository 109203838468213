import BaseLayout from "../BaseLayout";
import gs from "styles/styles";

const CountDownScreen = ({ delay }) => {
  const globalStyles = gs();
    
  return (
        <BaseLayout
          theme={"primary"}
          headerVisible={true}
          brandingVisible={false}
          body={
            <div className={globalStyles.containerCenter}>
              {delay >= 0 && <span>Redirecting in {delay >= 0 ? delay : 0} seconds</span>}
              {delay<0 && <span style={{textAlign: 'center'}}>You can now close this window & continue with your kyc</span>}
            </div>
          }
        />
      );
};

export default CountDownScreen;