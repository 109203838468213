import axios from "axios";
import {
  kycServiceURLV2,
  userCaasURL,
  userServicesURL,
  userServicesURLV2,
  walletCaasURL,
  walletServicesURL,
  walletServicesURLV2,
  walletV2CaasURL,
} from "constants/constants.js";

const instance = axios.create({
  timeout: 40000,
});
export default instance;

instance.interceptors.request.use(
  async (config) => {
    const { service, url } = config;
    config.baseURL =
      process.env.REACT_APP_HOST ?? "https://staging.mudrex.com/api/";

    config.headers["X-Request-Id"] = 123456; //'yMxBDAVJRsLMnv4wRmCiQjJApQnmSyiW';
    config.headers["X-Source"] = "sdk";
    config.headers["X-Sdk-Version"] = 1;
    config.headers["X-Platform"] = "caas";

    const constructedURL = serviceIdentifier(service) + url;
    config.url = constructedURL;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const serviceIdentifier = (service) => {
  switch (service) {
    case "user":
      return userServicesURL;
    case "userv2":
      return userServicesURLV2;
    case "wallet":
      return walletServicesURL;
    case "userCaas":
      return userCaasURL;
    case "walletCaas":
      return walletCaasURL;
    case "walletV2":
      return walletServicesURLV2;
    case "walletV2Caas":
      return walletV2CaasURL;
    case "kycv2":
      return kycServiceURLV2;
    default:
      return userServicesURL;
  }
};
