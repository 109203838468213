import { createUseStyles } from "react-jss";
import colors from "styles/colors";

const styles = createUseStyles({
  mainContainer: {
    height: "100%",
  },
  mainContainerWithKeyboard: {
    height: "100%",
    paddingBottom: 0,
  },
  scrollViewContainer: {
    height: "100%",
  },
  bottomMargin: {
    marginBottom: 30,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "scroll",
    height: "100%",
  },
  headingContainer: {
    paddingHorizontal: 20,
    paddingBottom: 16,
    marginBottom: 30,
  },
  keyboardOpenContainer: {
    height: "88%",
  },
  amountInputContainer: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  heading: {
    fontSize: 20,

    color: "#1E1623",
    lineHeight: 32,
  },
  belowHeading: {
    color: "#7B8793",
    fontSize: 13,

    lineHeight: 18,
  },
  balance: {
    color: "#1E1623",
    fontSize: 14,
  },
  enterAmountText: {
    color: "#7B8793",
    marginBottom: 30,
  },
  currencyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    borderLeftColor: "#efefef",
    borderLeftWidth: 1,
    width: 140,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 6,
    alignItems: "center",
    paddingHorizontal: 20,
  },
  errorContainer: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  errorIcon: {
    marginTop: 2,
  },
  errorText: {
    marginLeft: 4,
  },
  inputValue: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  currencyText: {
    fontSize: 14,
    // lineHeight: 18,
    color: "#7B8793",
    marginLeft: 8,
    marginRight: 8,
  },
  textInput: {
    height: 48,
    fontSize: 20,

    color: "#1E1623",
    paddingRight: 14,
    paddingLeft: 6,
    flex: 1,
  },
  textInputAndroid: {
    height: 48,
    fontSize: 20,

    color: "#1E1623",
    paddingRight: 14,
    paddingLeft: 6,
    flex: 1,
    paddingTop: 5,
    paddingBottom: 0,
  },
  currencySymbolInput: {
    paddingLeft: 20,
    fontSize: 20,
    color: "#1E1623",
  },
  amountYouGetContainer: {
    display: "flex",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF9EE",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 10,
    height: 30,
  },
  amountYouGetText: {
    marginRight: 7,
    lineHeight: 24,
    fontSize: 13,
    color: "#7B8793",
  },
  amountYouGetTextLoading: {
    marginRight: 0,
    lineHeight: 24,
    fontSize: 13,
    color: "#7B8793",
  },
  amountYouGetTextHighlight: {
    color: "#1E1623",
    fontSize: 16,
  },
  amountShortcutContainer: {
    flex: 1,
    marginHorizontal: 8,
  },
  amountAddShortcutsContainer: {
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    width: "100%",
  },
  amountShortcut: {
    borderWidth: 1,
    borderColor: "#efefef",
    borderRadius: 20,
    paddingHorizontal: 2,
    paddingVertical: 8,
  },
  amountShortcutValue: {
    color: "#7B8793",
    fontSize: 14,
    textAlign: "center",
  },
  flag: {
    width: 18,
    height: 18,
    borderRadius: 50,
    overflow: "hidden",
    marginRight: 4,
  },
  buttonContainer: {
    paddingHorizontal: 20,
    marginTop: 32,
  },
  addShortcutBackground: {
    backgroundColor: colors.Primary.background,
  },
  changeShortcutTextColor: {
    color: "#FDFDFD",
  },
  disableShortcut: {
    backgroundColor: "#BCBCBC",
  },
  disableShortcutText: {
    color: "#FDFDFD",
  },
  lottieLoaderContainer: {},
  lottieLoader: {
    width: 40,
    height: 22,
  },
  loadingStateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  boltDuesContainer: {
    borderRadius: 8,
    backgroundColor: "#F7F5FB",
    flexDirection: "row",
    alignItems: "center",
    margin: 20,
    flexShrink: 1,
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  boltDuesText: {
    flexShrink: 1,
    paddingLeft: 10,
  },
});

export default styles;
