import { ClassConstructor, plainToInstance } from "class-transformer";
import { useEffect, useState } from "react";
import networkCallWrapper from "./networkCallWrapper";

export type CallParams<D, P> = {
    url: string
    method: string
    service: string
    params: P
    data: D | null
}

export interface Response<D> {
    errors: Array<Error> | null,
    isFetching: boolean
    data: D | null
}

export interface NetworkState<D> {
    isFetching: boolean,
    data: D | null,
    errors: Array<Error> | null,
}

export type Error = {
    code: number,
    text: string,
}

function useCancellableNetworkRequest<D, P>(
    callParams: CallParams<D, P>,
    dependencyList: Array<any>,
    dependencyCondition: Boolean,
    type: ClassConstructor<D>
): Response<D> {

    const [networkState, setNetworkState] = useState<NetworkState<D>>({
        isFetching: true,
        data: null,
        errors: null,
    });

    const [abortController, setAbortController] = useState<AbortController | undefined>(undefined);

    useEffect(() => {
        abortController?.abort()

        if (dependencyCondition || networkState.errors !== null) {

            const newAbortController = new AbortController()
            setAbortController(newAbortController)

            const func = async () => {

                setNetworkState({
                    data: null,
                    isFetching: true,
                    errors: null
                });

                const url = callParams?.url;
                const method = callParams?.method;
                const service = callParams?.service;
                const params = callParams?.params;
                const data = callParams?.data;

                try {
                    const request = await networkCallWrapper(
                        url,
                        method,
                        service,
                        params,
                        data,
                        newAbortController?.signal
                    );

                    if (request.success) {
                        const instanceData = plainToInstance(type, request.data, {
                            excludeExtraneousValues: true,
                        });
                        setNetworkState((prev) => ({
                            ...prev,
                            data: instanceData,
                            isFetching: false,
                        }))
                    } else {
                        setNetworkState((prev) => ({
                            ...prev,
                            errors: request?.errors,
                            isFetching: false,
                        }));
                    }
                } catch (err: any) {
                    console.log("func ~ err=>", err)
                }
            };

            func()

        }

    }, [...dependencyList]);

    return {
        errors: networkState.errors,
        isFetching: networkState.isFetching,
        data: networkState.data,
    };

}

export default useCancellableNetworkRequest;
