import { useGetUser } from 'api/profile/useGetUser';
import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { User } from 'types/user';
import { Authenticated } from './auth/auth-context';
import { useAuth } from './auth/use-auth';

type UserState = {
    user: User | null
    fetching: boolean
    refreshUser: () => void
}

const initialState = {
    user: null,
    fetching: false,
    refreshUser: () => { }
};

export const UserContext = createContext<UserState>(initialState);

type UserContextProviderProps = {
    children: ReactNode,
}

export const UserContextProvider: FC<UserContextProviderProps> = (props) => {


    const { children } = props

    const { state } = useAuth()
    const isAuthenticated = state instanceof Authenticated

    const userData = useGetUser(isAuthenticated)

    const refreshUser = () => {
        userData.refresh()
    }

    return (
        <UserContext.Provider value={{ user: userData.data, refreshUser, fetching: userData.isFetching }} > {children} </UserContext.Provider>
    )
};

export const useUserContext = () => useContext(UserContext)