interface IfscMap {
    [key: string]: string;
}
const ifscMap: IfscMap = {
    SBIN: "State Bank of India",
    PYTM: "Paytm Payments Bank",
    HDFC: "HDFC Bank",
    KKBK: "Kotak Bank",
    BARB: "Bank of Baroda",
    PUNB: "Punjab National Bank",
    ICIC: "ICICI Bank",
    UBIN: "Union Bank of India",
    FDRL: "Federal Bank",
    CNRB: "Canara Bank",
    UTIB: "Axis Bank",
    BKID: "Bank of India",
    IDIB: "Indian Bank",
    CBIN: "Central Bank of India",
    UCBA: "UCO Bank",
    IPOS: "India Post Payments Bank",
    AIRP: "Airtel Payments Bank",
    INDB: "IndusInd Bank",
    ESFB: "Equitas Small Finance Bank",
    IBKL: "IDBI Bank",
    IDFB: "IDFC Bank",
    MAHB: "Bank of Maharashtra",
    IOBA: "Indian Overseas Bank",
    JAKA: "Jammu and Kashmir Bank",
    YESB: "Yes Bank",
    KARB: "Karnataka Bank",
    DBSS: "DBS Bank",
    FINO: "Fino Payments Bank",
    BDBL: "Bandhan Bank",
    AUBL: "AU Small Finance Bank"
};

export const getBankNameFromIFSC = (ifsc: string) => {
    if (!ifsc) return "";
    return ifscMap[ifsc.slice(0, 4)];
};