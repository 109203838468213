import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  choosePaymentMethodContainer: {
    height: "100%",
  },
  headingContainer: {
    marginBottom: 30,
    backgroundColor: "#FDFDFD",
    borderBottomColor: "#EFEFEF",
    borderBottomWidth: 1,
  },
  headerContainer: {
    // marginBottom: 30
  },
  heading: {
    marginBottom: 4,
  },
  belowHeadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  belowHeading: {
    color: "#7B8793",
    fontSize: 14,

    lineHeight: 18,
    marginRight: 6,
  },
  balance: {
    color: "#1E1623",
    fontSize: 14,
  },
  addingFundsTextContainer: {
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF9EE",
    paddingVertical: 12,
    justifyContent: "center",
    marginBottom: 30,
  },
  addingFundsText: {
    marginRight: 5,

    fontSize: 13,
    lineHeight: 24,
    color: "#7B8793",
  },
  addingFundsTextBlackHighlight: {
    color: "#1E1623",

    fontSize: 14,
    lineHeight: 18,
  },
  addingFundsTextHighlight: {
    color: "#7947BF",

    fontSize: 14,
    lineHeight: 18,
  },
  skeletonContainerMain: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  skeletonContainer: {
    paddingHorizontal: 20,
    borderBottomColor: "#DBDBDBCC",
    borderBottomWidth: 1,
  },
  headingSkeleton: {
    width: 100,
    height: 13,
    borderRadius: 19,
    marginTop: 30,
    marginBottom: 10,
    backgroundColor: "green",
  },
  contentSkeleton: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 30,
    borderBottomColor: "#DBDBDBCC",
    borderBottomWidth: 1,
  },
  iconSkeletion: {
    width: 28,
    height: 13,
    borderRadius: 19,
    marginRight: 18,
  },
  methodSkeletion: {
    width: 200,
    height: 13,
    borderRadius: 19,
  },
});

export default styles;
