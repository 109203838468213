import React from "react";
import { Checkbox } from "@mui/material";
import colors from "../../../styles/colors";

const MCheckBox = ({ isChecked, onClick, size = "26" }) => {
  return (
    <Checkbox
      sx={{ paddingX: 0, paddingY: 0, bgcolor: colors.Background.secondary }}
      checked={isChecked}
      onChange={onClick}
      size={size}
      disableRipple
    />
  );
};

export default MCheckBox;
