import PendingIcon from "assets/svgs/onrampdelayed.svg";
import FailedIcon from "assets/svgs/onrampfailed.svg";
import ProcessingIcon from "assets/svgs/onrampprocessing.svg";
import SuccessIcon from "assets/svgs/onrampsuccess.svg";
import UTRBottomsheet from "components/onRamp/UTRBottomsheet";
import { TransactionStatus } from "components/profile/transactions/transaction-detail";
import BaseLayout from "components/shared/BaseLayout";
import MLink from "components/shared/Link";
import { ConfigContext } from "context/ConfigContext";
import { useOnrampContext } from "context/OnRamp";
import moment from "moment";
import { ConfirmCancelBottomSheet } from "pages/onRamp/UpiIntent/cancelBottomSheet";
import { useContext, useEffect, useState } from "react";
import MButton from "../../shared/MButton";
import { usePolling } from "hooks/usePolling";
import { ONRAMP_HOME } from "constants/constants";
const defaultMessageMap = {
  processing: {
    title: "Processing your payment...",
    time: "This may take upto 5 min ",
    subtitle: "We will let you know once completed",
  },
  onRampCompleted: {
    title: "We have received your funds",
    time: "This may take upto 5 min ",
    subtitle: "You purchase is in progress",
  },
  delayed: {
    title: "Processing your payment",
    time: "This may take upto 5 min ",
    subtitle: "It is taking longer than expected",
  },
  failed: {
    title: "Payment failed",
    time: "This may take upto 5 min ",
    subtitle: "We were not able to find your payment",
  },
  completed: {
    title: "Payment successful",
    time: "This may take upto 5 min ",
    subtitle: "You have purchased the reward",
  },
};

const OffRampSuccess = ({
  orderDetails,
  paymentMethod,
  amount,
  createdAt,
  status,
  getTransaction,
  external,
  item,
  redirectUrl,
}) => {
  const configContext = useContext(ConfigContext);

  const { fiatCurrencyState } = useOnrampContext()
  const selectedCurrency = fiatCurrencyState.fiatCurrency?.value

  let sla = configContext?.config?.sla;
  let support = configContext?.config?.messages?.support;
  let headerDisabled =
    configContext?.config?.features?.success_screen?.header?.disabled;
  let continueButtonDisabled =
    configContext?.config?.features?.success_screen?.continue_button?.disabled;

  const [paymentState, setPaymentState] = useState("processing");
  const [methodSla, setMethodSla] = useState(600);
  const [showBottomsheet, setShowBottomsheet] = useState(false);

  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const imageMap = {
    processing: <img style={{ height: 40, margin: 'auto' }} src={ProcessingIcon} />,
    onRampCompleted: <img style={{ height: 40, margin: 'auto' }} src={ProcessingIcon} />,
    delayed: <img style={{ height: 40, margin: 'auto' }} src={PendingIcon} />,
    failed: <img style={{ height: 40, margin: 'auto' }} src={FailedIcon} />,
    completed: <img style={{ height: 40, margin: 'auto' }} src={SuccessIcon} />,
  };

  const onSuccess = () => {
    window.parent.postMessage(
      {
        event: "success",
        params: {
          crypto_amount: orderDetails?.crypto_amount,
          transaction_id: orderDetails?.id,
          status: orderDetails?.status,
        },
      },
      "*"
    );
    if (redirectUrl) {
      if(redirectUrl === "home"){
        window.location.reload()
      }else {
        window.location.href = redirectUrl
      }
    } else {
      window.location.href = `/success?transaction_id=${orderDetails?.id}&crypto_amount=${orderDetails?.crypto_amount}&status=${orderDetails?.status}`;
    }
  };

  useEffect(() => {
    switch (status) {
      case "ONRAMP_INITIATED":
        Date.now() > createdAt + methodSla * 1000
          ? setPaymentState("delayed")
          : setPaymentState("processing");
        break;
      case "PROCESSING":
        setPaymentState("onRampCompleted");
        break;
      case "FAILED":
        setPaymentState("failed");
        break;
      case "COMPLETED":
        setPaymentState("completed");
        break;
    }
  }, [paymentMethod, amount, createdAt, status]);


  usePolling({
    interval: 5_000,  // 5 seconds
    maxInterval: 120_000, // 2 minutes
    onPoll: async () => {
      await getTransaction();
    },
    onSuccess: (result) => console.log('Completed:', result),
  });

  let messageMap = configContext?.config?.messages?.onramp
    ? configContext?.config?.messages?.onramp
    : defaultMessageMap;

  useEffect(() => {
    if (configContext?.config?.message?.onramp) {
      messageMap = configContext?.config?.message?.onramp;
    }
  }, []);

  const paymentMethodMap = {
    upi_transfer: "UPI",
    upi_intent: "UPI",
    bank_transfer: "Bank Transfer",
  };
  useEffect(() => {
    switch (paymentMethod) {
      case "upi_transfer":
        sla?.["upi_transfer"] && setMethodSla(sla?.["upi_transfer"]);

        break;
      case "upi_intent":
        sla?.["upi_intent"] && setMethodSla(sla?.["upi_intent"]);
        break;
      case "bank_transfer":
        sla?.["bank_transfer"] && setMethodSla(sla?.["bank_transfer"]);
        break;
    }
  }, [paymentMethod]);

  return (
    <BaseLayout
      theme={configContext?.config?.theme?.palette ? "primary" : "success"}
      headerVisible={!headerDisabled}
      body={
        <TransactionStatus
          paymentState={paymentState}
          message={messageMap[paymentState]}
          createdAt={
            external
              ? moment
                .unix(orderDetails?.created_at / 1000)
                .format("MMM Do YY, h:mm:ss a")
              : orderDetails?.created_at
          }
          amount={amount}
          currencySymbol={selectedCurrency?.currencySymbol}
          paymentMethod={paymentMethodMap?.[paymentMethod]}
          support={support}
          image={imageMap[paymentState]}
          item={item}
          state={paymentState}
          onClickCancel={() => setShowCancelDialog(true)}
        />
      }
      footer={
        <div>
          <div style={{ marginBottom: 16 }}>
            {selectedCurrency.symbol === "INR" && paymentState != "failed" && (
              <MLink
                text="Forgot to add note? claim deposit"
                onClick={() => {
                  setShowBottomsheet(true);
                }}
              />
            )}
          </div>
          {!continueButtonDisabled && (
            <MButton
              onPress={onSuccess}
              text={"Continue"}
              isPurpleButton
              size="medium"
            />
          )}
        </div>
      }
      bottomSheet={
        <>
          <UTRBottomsheet
            isVisible={showBottomsheet}
            onClose={() => setShowBottomsheet(false)}
            createOrder={async () => {
              return orderDetails?.id;
            }}
          />
          <ConfirmCancelBottomSheet
            visible={showCancelDialog}
            onClose={() => setShowCancelDialog(false)}
            transactionId={orderDetails?.id}
            onCancelled={() => getTransaction()}
          />
        </>
      }
    />
  );
};

export default OffRampSuccess;
