export const initializeHyververge = (hv_token) => {
  window.HyperSnapSDK.init(hv_token, window.HyperSnapParams.Region.India);
};

export const startUserSession = (user_id) => {
  window.HyperSnapSDK.startUserSession(user_id);
  return true;
};

export const setUpDocumentCaptureConfig = (side) => {
  var hvDocConfig = new window.HVDocConfig();
  hvDocConfig.docTextConfig.setDocCaptureTitle("Capture Aadhaar Card");
  // hvDocConfig.setEnableDocumentUpload(true);
  hvDocConfig.docTextConfig.setDocCaptureDescription(
    `Place the ${side} side of your Aadhaar card inside the box`
  );
  // hvDocConfig.setShouldShowReviewScreen(true); // First create a hvdoc config object
  hvDocConfig.setOCRDetails(
    "https://ind-docs.hyperverge.co/v2.0/readAadhaar",
    side === "front"
      ? hvDocConfig.DocumentSide.FRONT
      : hvDocConfig.DocumentSide.BACK,
    {
      ...(side === "front" && { faceCheck: "yes" }),
    },
    {}
  );
  hvDocConfig.setShouldShowDocReviewScreen(true);

  return hvDocConfig;

  //   RNHVDocsCapture.setDocumentType(RNHyperSnapParams.DocumentTypeCard);
  //   RNHVDocsCapture.setDocCaptureSubText('Aadhar Card');
  //   RNHVDocsCapture.setEnableDocumentUpload(true);
  //   RNHVDocsCapture.setDocCaptureDescription(
  //     `Place the ${side} side of your Aadhar card inside the box`,
  //   );
  //   RNHVDocsCapture.setShouldShowReviewScreen(true);
  //   RNHVDocsCapture.setOCRAPIDetails(
  //     'https://ind-docs.hyperverge.co/v2.0/readAadhaar',
  //     side === 'front'
  //       ? RNHyperSnapParams.DocumentFront
  //       : RNHyperSnapParams.DocumentBack,
  //     {
  //       ...(side === 'front' && {faceCheck: 'yes'}),
  //     },
  //     {},
  //   );
};

export const startDocumentCapture = (hvDocConfig, callback) => {
  window.HVDocsModule.start(hvDocConfig, callback); // Start the HVDocsModule. Callback is the function that receives the data once the api call finishes.It gets two objects HVError and HVResponse.
};

export const setUpConfigAndStartFaceCapture = (callback) => {
  var hvFaceConfig = new window.HVFaceConfig(); // First create a hvdoc config object
  hvFaceConfig.setShouldShowInstructionPage(true);

  window.HVFaceModule.start(hvFaceConfig, callback); // Start the HVFaceModule. Callback is the function that receives the data once the api call finishes.It gets two objects HVError and HVResponse.
};
