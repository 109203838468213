import { ThemeProvider } from "@mui/material/styles";
import { UserContextProvider } from "context/UserContext";
import { AuthContextProvider } from "context/auth/issuer-context";
import Caas from "pages/caas";
import { useSearchParams } from "react-router-dom";
import themeOptions from "styles/theme";
import { EventLevel, sendEvent } from "utils/events/handler";

const Kyc = ({ }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const signature = searchParams.get("secret")
  const timestamp = searchParams.get("timestamp")
  const userId = searchParams.get("user_id")
  const clientId = searchParams.get("client_id")
  const mode = "DEV"

  let init

  if (signature && timestamp && userId && clientId && mode) {
    init = async () => {
      return { signature, timestamp, userId, clientId, mode };
    };
  }

  const version = searchParams.get("version") ?? "0"
  window.sessionStorage.setItem("client_version", version);

  return (
    <>
      <AuthContextProvider
        init={init}
        clientId={searchParams.get("client_id")}
      >
        <UserContextProvider init={init}>
          <ThemeProvider theme={themeOptions}>
            <Caas
              init={init}
              sdkConfiguration={{
                steps: ["kyc"],
                kycEnabled: true,
                bankEnabled: true,
              }}
              onKYCSuccessCallback={() => {
                sendEvent("KYC_STATUS", EventLevel.INFO, { status: "COMPLETED" });
              }}
              onRampSuccessCallback={() => { }}
              offRampSuccessCallback={() => { }}
              onExceptionCallback={() => {
                window.location.reload();
              }}
              exceptionCTA={"Try again"}
              startCustomKYCCallback={() => { }}
              onBack={() => { }}
              presetKycCountry={searchParams.get("kyc_country") ?? "IND"}
              fixedPresetKycCountry={!(searchParams.get("show_kyc_country_picker") ?? false)}
              redirectUrl={searchParams.get("redirect_url")}
              externalDigilocker={searchParams.get("ext_digilocker")}
            />
          </ThemeProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default Kyc;
