import React from "react";
import { Alert, Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import styles from "./styles";
import { Done } from "@mui/icons-material";
import BaseLayout from "components/shared/BaseLayout";
import gs from "styles/styles";

const CaptureAadhar = ({
  aadharImageError,
  startAadharCapture,
  isLoading,
  isScreen = false,
}) => {
  const classes = styles();
  const globalStyles = gs();
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div className={isScreen ? null : globalStyles.dialogContainer}>
          <div>
            <Typography variant="h10">
              {"Take a picture of your aadhaar card"}
              <Typography variant="h10">
                <br /> <strong>{"front and back side"}</strong>
              </Typography>
            </Typography>
            <div style={{ marginTop: 20 }}>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Guidelines
              </Typography>
            </div>
            <div className={classes.detailsContainer}>
              <div className={classes.detailRow}>
                <Done
                  color="success"
                  fontSize="small"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {"Place it within the box"}
                </Typography>
              </div>
              <div className={classes.detailRow}>
                <Done
                  color="success"
                  fontSize="small"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {"Do not place outside"}
                </Typography>
              </div>
              <div className={classes.detailRow}>
                <Done
                  color="success"
                  fontSize="small"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {"Avoid glare"}
                </Typography>
              </div>
            </div>
            {aadharImageError && (
              <div style={{ marginBottom: 10 }}>
                <Alert severity="error">
                  <Typography variant="alert">{aadharImageError}</Typography>
                </Alert>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <MButton
          onPress={() => startAadharCapture()}
          loading={isLoading}
          disabled={isLoading}
          text={"Continue"}
          isPurpleButton
          size="medium"
        />
      }
    />
  );
};

export default CaptureAadhar;
