export function sentenceCase(text: string): string {
    return text[0].toUpperCase() + text.substring(1).toLowerCase()
}

export function sanitised(text?: string | null, def: string = ""): string {
    if (text === undefined || text === null || text.trim().length === 0) {
        return def
    }

    return text.trim()
}