import { Expose, Type } from "class-transformer";
import useNetworkRequest, { Response } from "hooks/networking/useNetworkRequest";
import { CryptoNetwork } from "types/CryptoNetwork";


class CoinDetails {
    @Expose({ name: "name" })
    name!: string;

    @Expose({ name: "symbol" })
    symbol!: string;

    @Expose({ name: "network_list" })
    @Type(() => CryptoNetwork)
    networkList!: CryptoNetwork[];
}

export default function useGetCryptoNetworks(coin: string): Response<Array<CoinDetails>> {
    const cryptoNetworks = useNetworkRequest({
        url: "broker/coin/info/withdraw",
        method: "GET",
        service: "wallet",
        params: { coin },
        data: null,
    },
        [], true, false, CoinDetails
    );
    // @ts-ignore
    return cryptoNetworks;
}

/**
 * {
    "success": true,
    "data": [
        {
            "name": "TetherUS",
            "symbol": "USDT",
            "network_list": [
                {
                    "network": "BSC",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "BNB Smart Chain (BEP20)",
                    "processing_time": "Typically takes 15 mins"
                },
                {
                    "network": "EOS",
                    "memo_regex": "^[0-9A-Za-z\\-_,]{1,120}$",
                    "address_regex": "^[1-5a-z\\.]{1,12}$",
                    "name": "EOS",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "NEAR",
                    "memo_regex": "",
                    "address_regex": "^(?!0x)(?!bc1)(?!bnb1)[a-z0-9_-]{1}[a-z0-9_.-]{0,62}[a-z0-9_-]{1}$",
                    "name": "NEAR Protocol",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "AVAXC",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "AVAX C-Chain",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "ARBITRUM",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "Arbitrum One",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "STATEMINT",
                    "memo_regex": "",
                    "address_regex": "^(1)[0-9a-z-A-Z]{44,50}$",
                    "name": "Asset Hub (Polkadot)",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "BNB",
                    "memo_regex": "^[0-9A-Za-z\\-_]{1,120}$",
                    "address_regex": "^(bnb1)[0-9a-z]{38}$",
                    "name": "BNB Beacon Chain (BEP2)",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "ETH",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "Ethereum (ERC20)",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "KAVAEVM",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "KAVAEVM",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "OPTIMISM",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "Optimism",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "MATIC",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "Polygon",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "SOL",
                    "memo_regex": "",
                    "address_regex": "^[1-9A-HJ-NP-Za-km-z]{32,44}$",
                    "name": "Solana",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "XTZ",
                    "memo_regex": "",
                    "address_regex": "^(tz[1,2,3])[a-zA-Z0-9]{33}$",
                    "name": "Tezos",
                    "processing_time": "Typically takes 30 mins"
                },
                {
                    "network": "TRX",
                    "memo_regex": "",
                    "address_regex": "^T[1-9A-HJ-NP-Za-km-z]{33}$",
                    "name": "Tron (TRC20)",
                    "processing_time": "Typically takes 5 mins"
                },
                {
                    "network": "OPBNB",
                    "memo_regex": "",
                    "address_regex": "^(0x)[0-9A-Fa-f]{40}$",
                    "name": "opBNB",
                    "processing_time": "Typically takes 30 mins"
                }
            ]
        }
    ]
}
 */

/*
export function useGetConversionOptions(): Response<Array<FiatConversionWithCountry>> {
    const requestConfig: any = {
        url: 'v1/conversion/fiat',
        method: "GET",
        service: "wallet",
        params: {
            type: "buy",
        },
        data: null,
    };
    // @ts-ignore
    return useNetworkRequest(requestConfig, [], true, false, FiatConversionWithCountry)
}
 */
