import { Expose, Type } from 'class-transformer';

export class PaymentMethodConfig {
    @Expose()
    @Type(() => PaymentMethodNote)
    notes: PaymentMethodNote[] = [];


}

export class PaymentMethodNote {
    @Expose()
    text: string = "";
}