import { createUseStyles } from "react-jss";

const commonValues = {
  includeFontPadding: false,
};

export const FontStyles = createUseStyles({
  // Title
  title100SemiBold: {
    ...commonValues,
    fontSize: 100,
    fontWeight: "700",
  },
  title52SemiBold: {
    ...commonValues,
    fontSize: 52,
    fontWeight: "600",
  },
  title48SemiBold: {
    ...commonValues,

    fontSize: 48,
    fontWeight: "600",
  },
  title38SemiBold: {
    ...commonValues,

    fontSize: 38,
    fontWeight: "600",
  },
  title36SemiBold: {
    ...commonValues,

    fontSize: 36,
    fontWeight: "600",
  },
  title32SemiBold: {
    ...commonValues,

    fontSize: 32,
    fontWeight: "600",
  },
  title28SemiBold: {
    ...commonValues,

    fontSize: 28,
    fontWeight: "600",
  },
  title24Medium: {
    ...commonValues,

    fontSize: 24,
    fontWeight: "500",
  },
  title24SemiBold: {
    ...commonValues,

    fontSize: 24,
    fontWeight: "600",
  },
  title20SemiBold: {
    ...commonValues,

    fontSize: 20,
    fontWeight: "600",
  },
  title16SemiBold: {
    ...commonValues,

    fontSize: 16,

    fontWeight: "600",
  },
  title18SemiBold: {
    ...commonValues,

    fontSize: 18,

    fontWeight: "600",
  },
  title20Medium: {
    ...commonValues,
    fontSize: 20,

    fontWeight: "300",
  },
  title20Bold: {
    ...commonValues,
    fontSize: 20,

    fontWeight: "500",
  },
  //inuse
  title14SemiBold: {
    ...commonValues,
    fontSize: 14,
    fontWeight: "600",
  },

  // Subtitle
  subtitle18Medium: {
    ...commonValues,
    fontSize: 18,

    fontWeight: "500",
  },
  subtitle16SemiBold: {
    fontSize: 16,

    fontWeight: "600",

    ...commonValues,
  },
  subtitle16Medium: {
    ...commonValues,
    fontSize: 16,

    fontWeight: "500",
  },
  subtitle16Regular: {
    ...commonValues,
    fontSize: 16,

    fontWeight: "400",
  },
  subtitle14SemiBold: {
    ...commonValues,

    fontSize: 14,

    fontWeight: "600",
  },
  subtitle12SemiBold: {
    ...commonValues,
    fontSize: 12,
    fontWeight: "600",
  },
  subtitle14Medium: {
    ...commonValues,

    fontSize: 14,
    fontWeight: "500",
  },

  // Caption
  caption13Medium: {
    ...commonValues,

    fontSize: 13,
    fontWeight: "500",
  },
  caption13Regular: {
    ...commonValues,

    fontSize: 13,
    fontWeight: "400",
  },
  caption13SemiBold: {
    ...commonValues,

    fontSize: 13,
    fontWeight: "600",
  },
  caption12SemiBold: {
    ...commonValues,

    fontSize: 12,
    fontWeight: "600",
  },
  caption12Regular: {
    ...commonValues,

    fontSize: 12,

    fontWeight: "400",
  },
  caption12Medium: {
    ...commonValues,

    fontSize: 12,

    fontWeight: "500",
  },
  caption11Regular: {
    ...commonValues,

    fontSize: 11,

    fontWeight: "400",
  },
  caption11Medium: {
    ...commonValues,

    fontSize: 11,

    fontWeight: "500",
  },
  caption10Regular: {
    ...commonValues,

    fontSize: 10,

    fontWeight: "500",
  },
  // Body
  body14Regular: {
    ...commonValues,

    fontSize: 14,
    fontWeight: "400",
  },
  body13Regular: {
    ...commonValues,

    fontSize: 13,
    fontWeight: "400",
  },

  //Button
  button16Medium: {
    ...commonValues,
    fontSize: 16,

    fontWeight: "500",
  },
  button13Medium: {
    ...commonValues,

    fontSize: 13,
    fontWeight: "500",
  },
  button14Medium: {
    ...commonValues,

    fontSize: 14,
    fontWeight: "500",
  },

  //Input
  input16Medium: {
    ...commonValues,
    fontSize: 16,

    fontWeight: "500",

    marginLeft: 2,
  },

  headingV2: {
    fontSize: 20,
    lineHeight: 32,
    color: "#1E1623",
  },
});
