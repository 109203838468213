import { Expose } from "class-transformer"

export class CryptoNetwork {
    @Expose({ name: "network" })
    id: string = ""

    @Expose({ name: "memo_regex" })
    memoRegex: string = ""

    @Expose({ name: "address_regex" })
    addressRegex: string = ""

    @Expose({ name: "name" })
    name: string = ""

    @Expose({ name: "processing_time" })
    processingTime: string = ""

    constructor(
        id: string = "",
        memoRegex: string = "",
        addressRegex: string = "",
        name: string = "",
        processingTime: string = ""
    ) {
        this.id = id
        this.memoRegex = memoRegex
        this.addressRegex = addressRegex
        this.name = name
        this.processingTime = processingTime
    }
}