import {
    AuthProvider as LibAuthProvider,
} from "@mudrex/auth";

import {
    CategoryEnum,
    ChannelEnum,
    FactorsEnum,
    ScopeEnum
} from "@mudrex/auth";

import { AuthConfig } from "@mudrex/auth/src/auth/auth.types";
import { Organization } from "@mudrex/auth/src/types/organization";
import { Failure, Result, Success } from "hooks/networking/response";

export type LoginInitData = {
    challengeId: string
    isNewUser: boolean
    nextCodeAvailableAt: string
}

export type LoginCompleteData = {
    accessToken: string
}

export default class AuthProvider {
    private static authProvider: AuthProvider;
    private static libAuthProvider?: LibAuthProvider;

    private constructor() { }

    public static init(orgId: string) {
        if (!this.authProvider) {
            this.authProvider = new AuthProvider()
        }

        const organization: Organization = orgId.length > 0 ? { id: orgId } : { domain: 'saber.money' }

        const config: AuthConfig = {
            env: process.env.REACT_APP_STAGE as string,
            organization
        };
        this.libAuthProvider = LibAuthProvider.getInstance(config)
    }

    public static async login(phone: string): Promise<Result<LoginInitData>> {
        try {
            const result = await AuthProvider.libAuthProvider!.initialize_login_signup(
                ChannelEnum.Phone,
                CategoryEnum.User,
                FactorsEnum.OTP,
                { phone: phone },
                ScopeEnum.CreateSesion
            )

            if (!result?.success) {
                return new Failure(result.errors?.[0].text)
            }

            const data = result?.data;
            const successData = {
                challengeId: data?.id!,
                isNewUser: data?.is_new_user ?? false,
                nextCodeAvailableAt: data?.expiry?.next_available_at!,
            };
            return new Success(successData)
        } catch (error) {
            console.error(error)
            return new Failure("Something went wrong! Please try again!")
        }
    }

    public static async submitOtp(phone: string, challengeId: string, otp: string, isNewUser: boolean): Promise<Result<LoginCompleteData>> {
        console.log("submitOtp ~ challengeId=>", challengeId)
        try {
            const result = await AuthProvider.libAuthProvider!.get_mfa_token(
                isNewUser ? ScopeEnum.CreateAccount : ScopeEnum.CreateSesion,
                FactorsEnum.OTP,
                { id: challengeId, code: otp },
                { phone },
                ChannelEnum.Phone,
                CategoryEnum.User
            );

            if (result && result.success) {
                return await this.loginSignup(phone, isNewUser, (result.data! as any).access_token!)
            } else {
                return new Failure(result?.errors?.[0]?.text);
            }
        } catch (error) {
            console.error(error)
            return new Failure("Something went wrong! Please try again!");
        }
    }

    private static async loginSignup(phone: string, isNewUser: boolean, mfaToken: string): Promise<Result<LoginCompleteData>> {
        const result = await AuthProvider.libAuthProvider!.login_signup(
            ChannelEnum.Phone,
            CategoryEnum.User,
            FactorsEnum.OTP,
            { phone },
            isNewUser,
            mfaToken,
            {}
        );

        if (result && result?.success) {
            return new Success({ accessToken: (result.data! as any).access_token })
        } else {
            return new Failure(result?.errors?.[0]?.text);
        }
    }

    public static async resendOtp(phone: string, challengeId: string, isNewUser: boolean): Promise<Result<LoginInitData>> {
        try {
            const result = await AuthProvider.libAuthProvider!.reinitialize_login_signup(
                ChannelEnum.Phone,
                CategoryEnum.User,
                FactorsEnum.OTP,
                challengeId,
                { phone },
                isNewUser ? ScopeEnum.CreateAccount : ScopeEnum.CreateSesion,
            );

            if (!result?.success) {
                return new Failure(result.errors?.[0].text)
            }

            const data = result?.data;
            const successData = {
                challengeId: data?.id!,
                isNewUser: data?.is_new_user ?? false,
                nextCodeAvailableAt: data?.expiry?.next_available_at!,
            };
            return new Success(successData)
        } catch (error) {
            console.error(error)
            return new Failure("Something went wrong! Please try again!")
        }
    }
}
