import React from "react";
import PanMessage from "components/shared/PanMessage";
import BaseLayout from "components/shared/BaseLayout";
import MHeading from "components/shared/MHeading";
import MButton from "components/shared/MButton";
import { Typography } from "@mui/material";
import colors from "styles/colors";
import PanIcon from "assets/svgs/pan.svg";
import BankIcon from "assets/svgs/bank.svg";
import AadharIcon from "assets/svgs/aadhar.svg";
import SelfieIcon from "assets/svgs/selfie.svg";
import Clock from "assets/svgs/clock.svg";
import LineGrey from "assets/svgs/line-grey.svg";
import LineGreen from "assets/svgs/line-green.svg";
import tick from "assets/svgs/stick.svg";

const StepsScreen = ({ onStepCompleted, config, finalStep }) => {
  const messageMap = (state) => {
    switch (state) {
      case "PAN":
        return "PAN details";
      case "BANK":
        return "Bank details";
      case "AADHAR":
        return "Aadhaar details";
      case "SELFIE":
        return "Liveliness Check";
    }
  };

  const ImageMap = (state) => {
    switch (state) {
      case "PAN":
        return PanIcon;
      case "BANK":
        return BankIcon;
      case "AADHAR":
        return AadharIcon;
      case "SELFIE":
        return SelfieIcon;
    }
  };

  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div id="body">
          <MHeading heading="Verify your identity" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <img src={Clock} />
            <Typography
              variant="caption"
              style={{
                color: colors.Grey.title,
                display: "flex",
                alignItems: "center",
                paddingLeft: 5,
              }}
            >
              {`Usually takes less than 2 minutes`}
            </Typography>
          </div>
          <Typography variant="caption" style={{ color: colors.Grey.title }}>
            <strong>steps to complete your verification</strong>
          </Typography>
          <div style={{ marginTop: 15, marginBottom: 10 }}>
            {Object.entries(config).map((t, k) => (
              <div key={k}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 7.5,
                  }}
                >
                  <img
                    style={{ height: 30, width: 30 }}
                    src={
                      config[k].is_verified ? tick : ImageMap(config[k].step)
                    }
                  />
                  <Typography style={{ marginLeft: 10 }} variant="b1">
                    {messageMap(config[k].step)}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 7.5,
                  }}
                >
                  {k !== finalStep && (
                    <div
                      style={{
                        width: 30,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ height: 10 }}
                        src={config[k].is_verified ? LineGreen : LineGrey}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <div id="footer">
          <PanMessage
            text={
              "Please ensure all the documents provided belong to the same person."
            }
          />
          <MButton
            onPress={onStepCompleted}
            text={"Continue"}
            isPurpleButton
            size="medium"
          />
        </div>
      }
    />
  );
};

export default StepsScreen;
