import PhoneVerification from "components/kyc/PhoneVerification";
import { PhoneCountry } from "constants/country_isd";
import { AuthContextType, SignInInit } from "context/auth/issuer/jwt-context";
import { useAuth } from "context/auth/use-auth";
import { Failure, Success } from "hooks/networking/response";
import { useState } from "react";
import { useCountry } from "state/auth/country";
import useDebounce from "utils/useDebounce";

const checkValidPhone = (country: PhoneCountry, phone: string) => {
    if (country.iso3 == "IND") return String(phone)
        .toLowerCase()
        .match(/^(\d{10})$/);

    return phone.length > 5
};

const getPhoneInputError = (country: PhoneCountry, input: string) => {
    if (input == "") return ""
    return checkValidPhone(country, input) ? null : "Please enter a valid phone number"
}

export const PhoneVerificationContainer = ({ }) => {

    const { state, initSignIn, submitOtp, resendOtp, reset } = useAuth<AuthContextType>()
    const { selectedCountry, countries, setCountry } = useCountry()

    const isBottomsheetVisible = state instanceof SignInInit
    const [phoneInput, setPhoneInput] = useState("");
    const phoneInputDebounced = useDebounce(phoneInput, 600, "")
    const phoneInputError = getPhoneInputError(selectedCountry, phoneInputDebounced || "")
    const [error, setError] = useState<String | null>(null);
    const [loading, setLoading] = useState(false);
    const [bottomsheetLoading, setBottomsheetLoading] = useState(false);
    const [otpInput, setOtpInput] = useState("");
    const otpInputDebounced = useDebounce(otpInput, 600, "")
    const otpValidationError = otpInputDebounced == "" ? "" : otpInputDebounced?.length != 6 ? "Please enter a valid OTP" : null
    const [otpError, setOtpError] = useState<string | null>(null);
    const [otpSuccess, setOtpSucces] = useState<String | null>(null);
    const [resendLoading, setResendLoading] = useState(false);
    const [otpTimer, setOtpTimer] = useState(false);

    const onSubmitPhone = async () => {
        setLoading(true);
        const validation = checkValidPhone(selectedCountry, phoneInput);
        if (!validation) {
            setError("You have entered an invalid phone number!");
            throw "Invalid Phone";
        }
        const result = await initSignIn("+" + selectedCountry.isdcode + phoneInput);
        if (result instanceof Success) {
            setError(null);
            setOtpTimer(true);
        } else {
            setError((result as Failure<any>).error!);
        }
        setLoading(false);
    };


    const onSubmitPhoneOtp = async () => {
        setBottomsheetLoading(true);
        const result = await submitOtp(otpInput);
        if (result instanceof Success) {
            setError(null);
        } else {
            setOtpError((result as Failure<any>).error!);
        }

        setBottomsheetLoading(false);
    };

    const onResendOtp = async () => {
        setResendLoading(true);
        setOtpInput("");
        setOtpError(null);
        setOtpSucces(null);
        const result = await resendOtp()
        if (result instanceof Success) {
            setOtpSucces("Resent successfully!");
            setOtpError(null);
        } else {
            setOtpSucces(null);
            setError((result as Failure<any>).error!);
        }
        setResendLoading(false);
        setOtpTimer(true);
    };

    const onReenterPhone = async () => {
        reset()
        setPhoneInput("");
        setOtpInput("");
        setBottomsheetLoading(false);
        setLoading(false);
        setOtpSucces(null);
        setOtpError(null);
    };

    return (
        <PhoneVerification
            phone={phoneInput}
            setPhone={setPhoneInput}
            phoneInputError={phoneInputError}
            isBottomsheetVisible={isBottomsheetVisible}
            setIsBottomsheetVisible={() => { }}
            onSubmitPhone={onSubmitPhone}
            error={error}
            setError={setError}
            loading={loading}
            bottomsheetLoading={bottomsheetLoading}
            otp={otpInput}
            setOtp={setOtpInput}
            onSubmitPhoneOtp={onSubmitPhoneOtp}
            onReenterPhone={onReenterPhone}
            onResendOtp={onResendOtp}
            otpError={otpValidationError || otpError}
            otpSuccess={otpSuccess}
            setOtpError={setOtpError}
            setOtpSucces={setOtpSucces}
            resendLoading={resendLoading}
            otpTimer={otpTimer}
            setOtpTimer={setOtpTimer}
            selectedCountry={selectedCountry}
            countries={countries}
            setCountry={setCountry}
        />
    );
};