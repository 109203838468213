import { Box, Divider, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import IcBuy from 'assets/svgs/buy-coin';
import moment from 'moment';
import { FC } from 'react';
import colors from "styles/colors";
import { Transaction, TransactionStatus } from 'types/transaction';

interface TransactionListItemProps {
    transaction: Transaction
    onClick: (transaction: Transaction) => void
}

const TransactionListItem: FC<TransactionListItemProps> = (props) => {
    const { transaction, onClick } = props;
    const theme = useTheme();

    let color
    if (transaction.status == TransactionStatus.COMPLETED) {
        color = theme.palette.success.light
    } else if (transaction.status == TransactionStatus.FAILED) {
        color = theme.palette.error.light
    } else {
        color = theme.palette.info.light
    }

    return (
        <div>
            <Stack
                direction="column"
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    cursor: 'pointer'
                }}
                onClick={() => { onClick(transaction) }}
            >
                <Stack spacing={1} direction="row" sx={{
                    display: "flex",
                    alignItems: 'center',
                }} >
                    <Box sx={{
                        height: 40, width: 40, padding: "8px",
                        borderRadius: "8px",
                        background: alpha(color, 0.1)
                    }}>
                        <IcBuy color={color} />
                    </Box>
                    <Stack sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }} color={colors.Dark}>
                            {transaction.cryptoSymbol} Purchase
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color={colors.Grey.title}>
                            {moment(transaction.createdAt).format("MMM DD, yyyy • hh:mm")}
                        </Typography>
                    </Stack>
                    <Stack direction='column' sx={{ alignItems: 'end' }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: "400" }} color={colors.Grey.title}>
                            {transaction?.cryptoAmount?.toString()}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color={colors.Grey.title}>
                            {transaction.getStatus()}
                        </Typography>
                    </Stack>
                </Stack>
                {transaction.status != TransactionStatus.COMPLETED && transaction.failureDesc ? <Box padding={1} marginTop={2}
                    sx={{ background: alpha(color, 0.1), borderRadius: "8px", }}>
                    <Typography variant='caption' sx={{ fontSize: "12px" }} color={colors.Grey.title}>
                        {transaction.failureDesc}
                    </Typography>
                </Box> : <div></div>}
            </Stack>
            <Divider />
        </div>
    );
};

export default TransactionListItem