import { CallParams } from "hooks/networking/useNetworkRequest";
import PaymentMethodResponse from "types/payment-methods";
import useCancellableNetworkRequest from "hooks/networking/useCancellableNetworkRequest";

type Props = {
    cryptoSymbol: string | undefined;
    currencyCode: string | undefined;
    kycCountry: string | undefined;
};

type Params = { 
    type: string | undefined; 
    crypto: string | undefined; 
    country: string | undefined; 
    fiat: string | undefined;
};

export function useGetPaymentMethods({ cryptoSymbol, currencyCode, kycCountry }: Props) {
    const requestConfig: CallParams<null, Params> = {
        url: 'v1/conversion/fiat/methods',
        method: "GET",
        service: "wallet",
        params: {
            type: "buy",
            crypto: cryptoSymbol,
            country: kycCountry,
            fiat: currencyCode,
        },
        data: null,
    };

    return useCancellableNetworkRequest(
        requestConfig,
        [cryptoSymbol, currencyCode, kycCountry],
        !!cryptoSymbol && !!currencyCode && !!kycCountry,
        PaymentMethodResponse
    );
}
