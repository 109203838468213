let exceptionCtaText = "Go Back";
let onException = () => {};

const setExceptionCTA = ({ ctaText }) => {
  exceptionCtaText = ctaText;
};

const setOnException = ({ callback }) => {
  onException = callback;
};

const getExceptionCTA = () => {
  return exceptionCtaText;
};
const getOnException = () => {
  return onException;
};
const ExceptionHandler = {
  getExceptionCTA,
  setExceptionCTA,
  getOnException,
  setOnException,
};

export { ExceptionHandler };
