import InsideDrawerBottomSheet from 'components/shared/InsideDrawerBottomSheet';
import { useOnrampContext } from 'context/OnRamp';
import React from 'react';

type InvalidNetworkBottomSheetProps = {
    showDrawer: boolean;
};

const InvalidPaymentMethods: React.FC<InvalidNetworkBottomSheetProps> = ({ showDrawer }) => {
    const { paymentMethodValidation } = useOnrampContext();

    let header = "";
    let desc = "";

    if (!paymentMethodValidation?.isValid) {
        header = "Payment method invalid";
        desc = "This does not seem to be a valid payment method. Kindly ensure that you are passing the correct payment method.";
    } else if (!paymentMethodValidation?.isEnabled) {
        header = "Payment method unavailable";
        desc = "Uh oh! This payment method is currently unavailable. Kindly use a different payment method and try again.";
    }

    return (
        <InsideDrawerBottomSheet 
            showDrawer={showDrawer} 
            heading={header}
            description={desc} 
        />
    );
};

export default InvalidPaymentMethods;
