import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getConfig } from "api/kycHooks";
import { KycAutoClose } from "components/kyc/kyc-auto-close";
import LoadingScreen from "components/shared/LoadingScreen";
import { BackStackContextProvider } from "context/BackStackContext";
import { ConfigContext } from "context/ConfigContext";
import { KycChecker } from "context/KycChecker";
import { NavContext, sdkSteps } from "context/NavContext";
import { ToastContextProvider } from "context/ToastContext";
import { Authenticated } from "context/auth/auth-context";
import { AuthConsumer, useAuth } from "context/auth/use-auth";
import ExceptionContainer from "pages/Exception";
import OffRamp from "pages/offRamp";
import OnRamp from "pages/onRamp";
import { TransactionList } from "pages/profile/transactions";
import { useEffect, useState } from "react";
import themeOptions from "styles/theme";
import { Flow } from "types/flow";
import { getPlatform } from "utils/platforms";

const Caas = ({
  init,
  sdkConfiguration,
  onKYCSuccessCallback,
  onRampSuccessCallback,
  offRampSuccessCallback,
  onExceptionCallback,
  exceptionCTA,
  fiatAmount,
  startCustomKYCCallback,
  onBack,
  withdrawAddress,
  transactionId,
  orderId,
  withdrawNetwork,
  cryptoAmount,
  cryptoSymbol,
  paymentMethod,
  expiresAt,
  withdraw,
  redirectUrl,
  presetKycCountry,
  fixedPresetKycCountry,
  externalDigilocker
}) => {
  const [currentState, setCurrentState] = useState({
    theme: "primary",
    header: {
      title: "",
      visible: true,
    },
  });

  const [currentStep, setCurrentStep] = useState(6);
  const [config, setConfig] = useState({});
  const [item, setItem] = useState(null);
  const kycSuccessCallbackMap = {
    kyc: () => {
      onKYCSuccessCallback();
    },
    onramp: () => {
      setCurrentStep(2);
    },
    offramp: () => {
      setCurrentStep(3);
    },
    loading: () => {
      setCurrentStep(0);
    },
  };

  const startKYC = () => {
    sdkConfiguration?.kycEnabled ? setCurrentStep(1) : startCustomKYCCallback();
  };

  const onRetry = () => {
    window.location.reload();
  };

  const initializeSDK = async () => {
    try {
      const result = await getConfig(getPlatform(), window.sessionStorage.getItem("client_version") ?? "0");
      // result.data.theme = null;
      setConfig(result?.data);
    } catch (e) { }
  };

  const auth = useAuth()

  useEffect(() => {
    if (auth.state instanceof Authenticated) {
      initializeSDK();
      window.addEventListener("message", (event) => {
        let receivedData;
        try {
          receivedData = event.data;
          setItem(receivedData.item);
        } catch (error) {
          console.error("Error parsing received JSON:", error);
          return;
        }
      });
    }
  }, [auth.state]);

  useEffect(() => {
    if (config) {
      let currentStep = sdkConfiguration?.steps?.[0];
      setCurrentStep(sdkSteps[currentStep] || 4);
    }
  }, [config]);

  return (
    <ConfigContext.Provider
      value={{
        init: init,
        config: config,
        ...(expiresAt && { expiresAt: expiresAt }),
        themeState: {
          currentState,
          setCurrentState,
        },
      }}
    >
      <ToastContextProvider>
        {config ? (
          <ThemeProvider
            theme={config?.theme ? createTheme(config?.theme) : themeOptions}
          >
            <NavContext.Provider value={{ currentStep, setCurrentStep }}>
              {currentStep === 1 ? (
                <AuthConsumer>
                  <BackStackContextProvider flow={"kyc"}>
                    <KycChecker flow={Flow.KYC}
                      presetKycCountry={presetKycCountry}
                      fixedPresetKycCountry={fixedPresetKycCountry}
                      externalDigilocker={externalDigilocker}
                    >
                      <KycAutoClose 
                      redirectUrl={redirectUrl}
                      delayInSeconds={2} 
                      />
                    </KycChecker>
                  </BackStackContextProvider>
                </AuthConsumer>
              ) : currentStep === 2 ? (
                <BackStackContextProvider flow={"onramp"}>
                  <OnRamp
                    init={init}
                    fiatAmount={Math.abs(fiatAmount)}
                    startKYC={startKYC}
                    onBack={onBack}
                    onSuccess={onRampSuccessCallback}
                    external={withdraw}
                    externalAddress={withdrawAddress}
                    externalNetwork={withdrawNetwork}
                    createdOrderId={orderId}
                    transactionId={transactionId}
                    cryptoAmount={Math.abs(cryptoAmount)}
                    cryptoSymbol={cryptoSymbol ?? "USDT"}
                    paymentMethod={paymentMethod}
                    item={item}
                    redirectUrl={redirectUrl}
                    presetKycCountry={presetKycCountry}
                    fixedPresetKycCountry={fixedPresetKycCountry}
                    externalDigilocker={externalDigilocker}
                  />
                </BackStackContextProvider>
              ) : currentStep === 3 ? (
                <AuthConsumer>
                  <OffRamp
                    init={init}
                    enteredInputAmount={fiatAmount}
                    startKYC={startKYC}
                    onBack={onBack}
                    onSuccess={offRampSuccessCallback}
                  />
                </AuthConsumer>
              ) : currentStep === 4 ? (
                <>
                  <AuthConsumer>
                    <ExceptionContainer
                      onRetry={onRetry}
                      showBottomSheet={true}
                    />
                  </AuthConsumer>
                </>
              ) : (
                currentStep === 6 && <AuthConsumer><TransactionList /></AuthConsumer>
              )}
            </NavContext.Provider>
          </ThemeProvider>
        ) : (
          <LoadingScreen headerVisible={false} mudrexLoader={false} />
        )}
      </ToastContextProvider>
    </ConfigContext.Provider>
  );
};

export default Caas;
