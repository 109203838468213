import { Typography } from "@mui/material";
import MBottomSheet from "components/shared/BottomSheet";
import MButton from "components/shared/MButton";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { CancelPayment } from "../handlers/cancelPayment";

type ConfirmCancelBottomSheetProps = {
    transactionId: string
    visible: boolean
    onClose: () => void
    onCancelled: () => void
}

export const ConfirmCancelBottomSheet: FC<ConfirmCancelBottomSheetProps> = ({ transactionId, visible, onClose, onCancelled }) => {

    const toast = useToast();
    const [cancellingPayment, setCancellingPayment] = useState(false);

    const onClickCancel = async () => {

        setCancellingPayment(true)
        const request = await CancelPayment(transactionId)
        if (request.success) {
            setCancellingPayment(false)
            onClose()
            onCancelled()
        }
        else {
            const message = request.errors?.[0].text
            toast.error(message)
        }
    }

    return <MBottomSheet
        title={"Cancel Payment"}
        isVisible={visible}
        close={onClose}
    >
        <div>
            <Typography
                sx={{ fontSize: 16 }}
                style={{ fontWeight: 700, paddingTop: 5 }}
            >
                Are you sure you want to cancel the payment?
            </Typography>
            <MButton
                text={"Yes, Cancel"}
                onPress={onClickCancel}
                outlineButton={true}
                loading={cancellingPayment}
            />
            <MButton
                text={"Close"}
                onPress={onClose}
                outlineButton={true}
                disabled={cancellingPayment}
            />
        </div>
    </MBottomSheet>
}