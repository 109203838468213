import { createUseStyles } from "react-jss";
import { FontStyles } from "styles/fonts";
import colors from "styles/colors";

const gs = createUseStyles({
  container: {
    flexDirection: "row",
    width: "100%",
    height: "60%",
    flex: 1,
    justifyContent: "center",
    paddingTop: 30,
    overflow: "scroll",
  },
  confirmText: {
    fontSize: 14,
    color: colors.Grey.title,
  },
  dialogContainer: {
    flexDirection: "row",
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  containerCenter: {
    height: "90%",
    width: "100%",
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  bottomSheetHeader: {
    ...FontStyles.title20Bold,
    color: colors.Dark.title,
  },
  divGrid: {
    display: "grid",
  },
  backgroundColor: { backgroundColor: "#ECF0F3" },
  title: {
    fontSize: 24,
    fontWeight: "600",
    marginBottom: 20,
    marginTop: 30,
    color: colors.Dark.title,
  },
  textStyle: {
    color: "black",
    fontSize: 16,
    fontWeight: "400",
  },
  disabledButton: {
    backgroundColor: "#BCBCBC",
    borderColor: "#EFEFEF",
    radius: 4,
    color: "white",
    fontSize: 16,
    fontWeight: "400",
  },
});

export default gs;
