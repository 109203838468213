import { Alert, Button, Typography } from "@mui/material";
import CopyToClipboard from "assets/svgs/copytoclipboard.svg";
import BaseLayout from "components/shared/BaseLayout";
import KycTextInput from "components/shared/KycTextInput";
import MLink from "components/shared/Link";
import MButton from "components/shared/MButton";
import MRow from "components/shared/MRow";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import colors from "styles/colors";
import { useThemeHook } from "styles/themeMudrex";
import FindUTRBottomSheet from "../FindUtrBottomSheet";
import BankPaymentMethodNotes from "./BankPaymentMethodNotes";
import styles from "./styles";
const BankTransferMethod = ({
  amount,
  handleCopy,
  handleTransfer,
  beneficiaryDetails,
  UTR,
  loading,
  error,
  setUTR,
  setError,
  bankPaymentMethod,
  selectedCurrency,
  accountNumber,
  bankName,
  paymentMethodNotes
}) => {

  const isSepa = bankPaymentMethod === "sepa_transfer"
  const isVan = bankPaymentMethod === "van_transfer"

  const classes = styles();
  const toast = useToast();
  const [nameCopied, setNameCopied] = useState(false);
  const [numberCopied, setNumberCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  const [theme] = useThemeHook();
  const [showUtrBottomsheet, setShowUtrBottomSheet] = useState(false);
  const handleUTR = (value) => {
    const numericRegex = /^([0-9]{1,100})+$/;
    const alphaNumericRegex = /^\w+$/;
    const checkMethod =
      bankPaymentMethod !== "sepa_transfer" ? numericRegex : alphaNumericRegex;
    if (checkMethod.test(value) || value === "") {
      setUTR(value);
    }
    setError(null);
  };

  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={!isSepa ? "IMPS" : "" + "Bank Transfer"}
      headerVisible={true}
      body={
        <div >
          <div id="top-container">
            <div
              style={{
                position: "relative",
              }}
            >
              {!isSepa && < BankPaymentMethodNotes accountNumber={accountNumber} bankName={bankName} notes={paymentMethodNotes} />}
              <Typography
                style={{
                  marginTop: 16,
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Transfer to
              </Typography>
              <div style={theme.gradientBackgroundGrey}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {"Account Holder Name"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {beneficiaryDetails?.account_name}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "Account name Copied. Enter it in the app during payment."
                      );
                      setNameCopied(true);
                      handleCopy("bank_name");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={nameCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 16,
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {"Account Number"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {beneficiaryDetails?.account_number}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "Account number Copied. Enter it in the app during payment."
                      );
                      setNumberCopied(true);
                      handleCopy("account_number");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={numberCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 16,
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {isSepa ? "SWIFT/BIC Code" : "IFSC Code"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {beneficiaryDetails?.ifsc_code}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "IFSC code Copied. Enter it in the app during payment."
                      );
                      setCodeCopied(true);
                      handleCopy("ifsc_code");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={codeCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
              </div>
              {!isVan && (<><div style={{ marginTop: 20 }}>
                <MRow
                  leftElement={
                    <Typography variant="caption" style={{ fontSize: 14 }}>
                      <strong>{!isSepa ? "Enter UTR" : "Enter IBAN/Bank Account Number"}</strong>
                    </Typography>
                  }
                  rightElement={
                    !isSepa ?
                      <MLink
                        direction="flex-end"
                        text={"How to get UTR?"}
                        // open in new tab in browser
                        onClick={() => { setShowUtrBottomSheet(true) }}
                      /> :
                      <></>

                  }
                />
              </div>
                <KycTextInput
                  placeholder={
                    bankPaymentMethod !== "sepa_transfer"
                      ? "Ex: 488939434989"
                      : "Ex: NL12324123412341234"
                  }
                  text={UTR}
                  setText={handleUTR}
                  keyboardType={
                    bankPaymentMethod !== "sepa_transfer" ? "numeric" : "default"
                  }
                  style={styles.UTRInput}
                />
              </>
              )}
            </div>
            {error && (
              <div style={{ marginBottom: 10 }}>
                <Alert severity="error">
                  <Typography variant="alert">{error}</Typography>
                </Alert>
              </div>
            )}
            {isSepa ? (
              <div style={{ padding: 8 }}>
                <Typography sx={{ fontSize: 12 }}>
                  {
                    "Once the transfer is complete, enter your IBAN/Bank Account Number above which you used to transfer funds."
                  }
                </Typography>
              </div>
            ) : null}
          </div>
        </div >
      }
      footer={
        < div className={classes.transferredButtonContainer} >
          <MButton
            onPress={() => {
              handleTransfer()
            }}
            text={"I have transferred"}
            isPurpleButton
            size="medium"
            loading={loading}
            disabled={loading}
          />
        </div >
      }
      bottomSheet={
        < FindUTRBottomSheet
          isVisible={showUtrBottomsheet}
          onClose={() => setShowUtrBottomSheet(false)}
        />
      }
    />
  );
};

export default BankTransferMethod;
