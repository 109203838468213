import React, { useState, useEffect } from "react";
import styles from "./styles";
import gs from "../../../styles/styles";
import BankAccountIcon from "../../../assets/svgs/bank-account.svg";
import KycTextInput from "../../shared/KycTextInput";
import { Typography, CircularProgress, Alert } from "@mui/material";
import MButton from "../../shared/MButton";
import ConfirmWithdrawDetailsSheet from "../ConfirmWithdrawDetailsSheet";
import colors from "../../../styles/colors";
import MLink from "../../shared/Link";

const WithdrawFunds = ({
  fadeAnimHeader,
  fadeAnimPage,
  fadeInPage,
  fadeOutPage,
  fadeInHeader,
  fadeOutHeader,
  getWalletBalance,
  isKeyboardVisible,
  inputAmount,
  setInputAmount,
  user,
  showCrashError,
  setShowCrashError,
  setIsKeyboardVisible,
  showMinMaxError,
  setShowMinMaxError,
  showAmountInfoSheet,
  setShowAmountInfoSheet,
  areConversionDetailsLoading,
  conversionDetails,
  selectedCurrency,
  checkingLimits,
  isLimitsBottomSheetVisible,
  setIsLimitsBottomSheetVisible,
  LimitsError,
  checkWithdrawLimits,
  showWithdrawOtpSheet,
  setShowWithdrawOtpSheet,
  handleResendOTP,
  resendButtonTimer,
  startTimer,
  withdrawEmailOtp,
  withdrawTfaOtp,
  setWithdrawEmailOtp,
  setWithdrawTfaOtp,
  isTFAEnabled,
  showConfirmWithdrawSheet,
  setShowConfirmWithdrawSheet,
  methodName,
  finalAmountYouGet,
  transactionRequest,
  isTransactionLoading,
  kycCountry,
  validatedBankDetails,
  incorrectOtpError,
  setIncorrectOtpError,
  sellRequestError,
  setSellRequestError,
  isLoadingMethods,
  userBalance,
}) => {
  const withdrawableBalance = getWalletBalance?.available_balance_usd;

  useEffect(() => {
    setShowMinMaxError(parseFloat(inputAmount) > parseFloat(userBalance));
  }, [inputAmount]);

  const classes = styles();
  const globalStyles = gs();
  const isBankAccount =
    kycCountry?.iso_3 === "IND" || validatedBankDetails?.iban || false;

  return (
    <>
      <div className={globalStyles.container} behavior="padding">
        <div>
          <div className={classes.headingContainer}>
            <Typography variant="h6">
              <strong>{"Withdraw Funds"}</strong>
            </Typography>
            {userBalance && (
              <Typography
                variant="caption"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  color: colors.Grey.title,
                }}
              >
                {`Withdrawable Funds: $${parseFloat(userBalance).toFixed(2)}`}
              </Typography>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" className={classes.enterAmountText}>
              {"Enter Amount"}
            </Typography>
            <div className={classes.inputContainer}>
              <div className={classes.inputValue}>
                <KycTextInput
                  textAlignVertical={"center"}
                  keyboardType={"decimal-pad"}
                  placeholder={"Enter Amount"}
                  placeholderTextColor={"#BCBCBC"}
                  text={inputAmount ? inputAmount : ""}
                  returnKeyType="done"
                  setText={(val) => {
                    setInputAmount(val);
                    setShowCrashError(false);
                    setShowMinMaxError(
                      parseFloat(val) > parseFloat(userBalance)
                    );
                  }}
                  leading={
                    <Typography
                      style={{ backgroundColor: colors?.Background?.secondary }}
                      className={classes.currencySymbolInput}
                    >
                      $
                    </Typography>
                  }
                  trailing={
                    <MLink
                      text={"Max"}
                      onClick={() => {
                        setInputAmount(`${withdrawableBalance}`);
                        setShowCrashError(false);
                        setShowMinMaxError(
                          parseFloat(inputAmount) > parseFloat(userBalance)
                        );
                      }}
                    />
                  }
                  onBlur={() => {
                    // Keyboard.dismiss();
                  }}
                />
              </div>
            </div>
            {(showMinMaxError || showCrashError) && inputAmount ? (
              <div style={{ marginBottom: 10 }}>
                <Typography variant="alert">
                  <Alert severity="error">
                    {showCrashError
                      ? "Please try again after some time"
                      : parseFloat(inputAmount) > parseFloat(userBalance)
                      ? "Cannot enter amount more than withdrawable balance"
                      : null}
                  </Alert>
                </Typography>
              </div>
            ) : null}
            {parseFloat(inputAmount) > 0 && !showCrashError ? (
              <div className={classes.amountYouGetContainer}>
                {areConversionDetailsLoading && finalAmountYouGet === "NaN" ? (
                  <div className={classes.loadingStateContainer}>
                    <div className={classes.lottieLoaderContainer}>
                      <CircularProgress size={15} />
                    </div>
                    <Typography
                      variant="caption"
                      className={classes.amountYouGetText}
                    >
                      {" will be transferred to you"}
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    variant="caption"
                    className={classes.amountYouGetText}
                  >
                    {selectedCurrency?.currencySymbol}
                    {finalAmountYouGet}
                    {" will be transferred to you"}
                  </Typography>
                )}
                {/* <InfoIcon /> */}
              </div>
            ) : null}
          </div>
        </div>

        {/* <AmountYouGetBottomSheet
          isVisible={showAmountInfoSheet && !areConversionDetailsLoading}
          closeBottomSheet={setShowAmountInfoSheet}
          selectedCurrency={selectedCurrency}
          inputAmount={inputAmount}
          conversionDetails={conversionDetails}
          buttonCta={isBankAccount ? 'Withdraw' : 'Add bank account'}
          onButtonClick={
            isBankAccount
              ? () => {
                  setShowAmountInfoSheet(false);
                  setShowConfirmWithdrawSheet(true);
                }
              : () => {
                  setShowAmountInfoSheet(false);
                }
          }
          isCtaDisabled={
            (parseFloat(inputAmount) &&
              parseFloat(inputAmount) <
                parseFloat(selectedCurrency?.min_amount)) ||
            parseFloat(inputAmount) >
              parseFloat(selectedCurrency?.max_amount) ||
            parseFloat(inputAmount) > parseFloat(withdrawableBalance) ||
            showCrashError ||
            areConversionDetailsLoading ||
            checkingLimits
          }
          ctaLoader={checkingLimits}
          isOfframp={true}
        /> */}
        {/* <WithdrawLimitsBottomSheet
          isVisible={isLimitsBottomSheetVisible}
          close={() => setIsLimitsBottomSheetVisible(false)}
          body={LimitsError}
        /> */}
        {/* <WithdrawOtpSheet
          isVisible={showWithdrawOtpSheet}
          closeSheet={() => setShowWithdrawOtpSheet(false)}
          finalAmountYouGet={finalAmountYouGet}
          selectedCurrency={selectedCurrency}
          user={user}
          handleResendOTP={handleResendOTP}
          resendButtonTimer={resendButtonTimer}
          startTimer={startTimer}
          withdrawEmailOtp={withdrawEmailOtp}
          withdrawTfaOtp={withdrawTfaOtp}
          setWithdrawEmailOtp={setWithdrawEmailOtp}
          setWithdrawTfaOtp={setWithdrawTfaOtp}
          isTFAEnabled={isTFAEnabled}
          transactionRequest={transactionRequest}
          isTransactionLoading={isTransactionLoading}
          incorrectOtpError={incorrectOtpError}
          setIncorrectOtpError={setIncorrectOtpError}
          sellRequestError={sellRequestError}
          setSellRequestError={setSellRequestError}
        /> */}
      </div>
      <ConfirmWithdrawDetailsSheet
        isVisible={showConfirmWithdrawSheet}
        closeSheet={() => setShowConfirmWithdrawSheet(false)}
        inputAmount={inputAmount}
        finalAmountYouGet={finalAmountYouGet}
        selectedCurrency={selectedCurrency}
        checkWithdrawLimits={checkWithdrawLimits}
        checkingLimits={checkingLimits}
        kycCountry={kycCountry}
        user={user}
        validatedBankDetails={validatedBankDetails}
      />
      <div className={classes.bankAccountAndButtonContainer}>
        {!isBankAccount ? (
          <div className={classes.bankAccountDetailsContainer}>
            <Typography className={classes.bankDetailsHeading}>
              {"Withdraw method"}
            </Typography>
            <div className={classes.bankDetailsContainer}>
              <div className={classes.bankAccountIconContainer}>
                <img className={{ height: "1.5rem" }} src={BankAccountIcon} />
              </div>
              <div className={classes.bankDetails}>
                {methodName ? (
                  <Typography className={classes.bankNameDetails}>
                    <strong>{`${methodName}`}</strong>
                  </Typography>
                ) : null}
                <Typography className={classes.noBankAccountHeading}>
                  {"No bank account added"}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.bankAccountDetailsContainer}>
            <div className={classes.bankDetailsHeading}>
              <Typography variant="h10">
                <strong>{"Withdraw to"}</strong>
              </Typography>
            </div>

            <div className={classes.bankDetailsContainer}>
              <div className={classes.bankAccountIconContainer}>
                <img className={{ height: "1.5rem" }} src={BankAccountIcon} />
              </div>
              {!isLoadingMethods ? (
                <div className={classes.bankDetails}>
                  {kycCountry?.iso_3 === "IND" &&
                  user?.status !== "UNINITIATED" ? (
                    <Typography
                      variant="caption"
                      className={classes.bankNameHeading}
                    >
                      <strong>
                        {user?.ifsc_code?.slice(0, 4)} {user?.account_number}
                      </strong>
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      className={classes.bankNameHeading}
                    >
                      {("damslda", validatedBankDetails?.iban)}
                    </Typography>
                  )}

                  {methodName ? (
                    <Typography
                      variant="h10"
                      className={classes.bankNameDetails}
                    >
                      <br />
                      {`${methodName}`}
                    </Typography>
                  ) : null}
                </div>
              ) : (
                <CircularProgress size={"large"} />
              )}
            </div>
          </div>
        )}
      </div>
      <MButton
        onPress={() => setShowConfirmWithdrawSheet(true)}
        text={"Withdraw"}
        isPurpleButton
        loading={checkingLimits}
        disabled={
          !inputAmount ||
          // parseFloat(inputAmount) < parseFloat(selectedCurrency?.min_amount) ||
          parseFloat(inputAmount) > parseFloat(selectedCurrency?.max_amount) ||
          parseFloat(inputAmount) > parseFloat(withdrawableBalance) ||
          showCrashError ||
          showMinMaxError ||
          areConversionDetailsLoading ||
          checkingLimits ||
          !methodName
        }
      />
    </>
  );
};

export default WithdrawFunds;
