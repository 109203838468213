import { AuthContextProvider } from "context/auth/issuer-context";
import OnrampAndWithdrawStatus from "onramp/OnrampAndWithdrawStatus";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import Kyc from "./routes/kyc";
import OffRamp from "./routes/offramp";
import OnRamp from "./routes/onramp";
import Success from "./routes/success";
import Digilocker from "routes/kyc/digilocker";
import { init } from "utils/authInit";

const AuthenticatedRoutes = () => {
  const [searchParams] = useSearchParams();

  return (
    <AuthContextProvider init={init} clientId={searchParams.get("client_id")}>
      <Routes>
        <Route path="/kyc" element={<Kyc />} />
        <Route
          path="/kyc/digilocker/:secret?/:timestamp?/:user_id?/:client_id?"
          element={<Digilocker />}
        />

        <Route path="/offramp" element={<OffRamp />} />
        <Route path="/onramp" element={<OnRamp />} />
        <Route path="/success" element={<Success />} />
        <Route
          path="/transaction/w/buy/:transaction_id"
          element={<OnrampAndWithdrawStatus />}
        />
      </Routes>
    </AuthContextProvider>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthenticatedRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
