import useGetPaymentMethodConfig from "api/OnRamp/useGetPaymentMethodConfig";
import { getBankNameFromIFSC } from "api/getBankName";
import BankTransferMethod from "components/onRamp/BankTransferMethod";
import { useOnrampContext } from "context/OnRamp";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { EventLevel, sendEvent } from "utils/events/handler";
import {
  fetchBankAccountDetails,
  makeOnRampWithdrawTransaction,
  makeTransaction,
  updateOnRampWithdrawTransaction,
} from "../../../api/onRampHooks";
import LoadingScreen from "../../../components/shared/LoadingScreen";
import StateEnum from "../states";

const BankTransferContainer = ({
  init,
  selectedCurrency,
  inputAmount,
  finalAmountYouGet,
  paymentMethods,
  bankId,
  accountNumber,
  external,
  externalNetwork,
  walletAddress,
  orderId,
  cryptoAmount,
  orderDetails,
  setCurrentState,
  setPaymentDetails,
  transactionId,
  selectedPaymentMethod,
  userBankIFSC
}) => {

  const { cryptoCurrencyState } = useOnrampContext()
  const cryptoCurrency = cryptoCurrencyState.value

  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [error, setError] = useState(null);
  const [UTR, setUTR] = useState("");
  const [loading, setLoading] = useState(false);
  const paymentMethodConfig = useGetPaymentMethodConfig(selectedPaymentMethod)
  console.log("paymentMethodConfig=>", paymentMethodConfig)

  useEffect(() => {
    setLoading(true);
    const fetchBankDetails = async () => {
      const accountDetailsRequest = await fetchBankAccountDetails({
        init: init,
        bankPaymentMethod: selectedPaymentMethod,
        fiat_symbol: selectedCurrency?.symbol || orderDetails?.fiat_symbol,
        cryptoSymbol: cryptoCurrency?.symbol || orderDetails?.crypto_symbol,
      });

      if (accountDetailsRequest.success) {
        setBeneficiaryDetails(accountDetailsRequest.data);
      } else {
        setError("Please try again after some time.");
      }
      setLoading(false);
    };

    try {
      fetchBankDetails();
    } catch (err) {
      setError("Please try again after some time.");
      setLoading(false);
    }
  }, []);

  const handleCopy = (type) => {
    copy(beneficiaryDetails[type]);
  };

  const handleTransfer = async () => {

    const isSepa = selectedPaymentMethod === "sepa_transfer"
    const isVan = selectedPaymentMethod === "van_transfer"
    const timestamp = Date.now();

    const isUTRInvalid =
      UTR.length < 5 ||
      UTR === "12345" ||
      UTR === "54321" ||
      UTR === "98765" ||
      UTR === "56789";
    if ((UTR && !isUTRInvalid) || isVan) {
      if (!loading) {
        setError("");
        setLoading(true);

        let bankTransactionId = UTR
        if (isSepa) {
          bankTransactionId = `${UTR}_${timestamp}`
        } else if (isVan) {
          bankTransactionId = undefined
        }
        let sourceId = bankId
        if (isSepa) {
          sourceId = UTR
        } else if (isVan) {
          sourceId = beneficiaryDetails?.id
        }

        const data = external
          ? {
            bank_transaction_id: bankTransactionId,
            source_id: sourceId,
            from_currency: selectedCurrency?.symbol,
            to_currency: cryptoCurrency?.symbol,
            from_amount: inputAmount,
            to_amount: cryptoAmount,
            payment_method: selectedPaymentMethod,
            network: externalNetwork,
            crypto_wallet_address: walletAddress,
            ...(transactionId && {
              uuid: transactionId,
            }),
          }
          : {
            bank_transaction_id: bankTransactionId,
            source_id: sourceId,
            fiat_symbol: selectedCurrency?.symbol,
            crypto_symbol: cryptoCurrency?.symbol,
            fiat_amount: inputAmount,
            crypto_amount: finalAmountYouGet,
            payment_method: selectedPaymentMethod,
            ...(transactionId && {
              uuid: transactionId,
            }),
          };

        try {
          const bankTransferBuyRequest = orderId
            ? await updateOnRampWithdrawTransaction({
              init,
              orderId: orderId,
              utr: UTR,
            })
            : external
              ? await makeOnRampWithdrawTransaction({
                init: init,
                data: data,
              })
              : await makeTransaction({
                init: init,
                data: data,
              });
          setCurrentState(StateEnum.SUCCESS_SCREEN, true);

          const eventName = orderId ? "ORDER_UPDATED" : "ORDER_CREATED"
          sendEvent(eventName, EventLevel.INFO, { order_id: orderId ?? bankTransferBuyRequest.data?.id })

          setPaymentDetails(bankTransferBuyRequest.data);
          setLoading(false);
        } catch (err) {
          setError("Please try again after some time.");
          setLoading(false);
        }
      }
    } else {
      if (!UTR) {
        setError("This field cannot be empty.");
      } else if (isUTRInvalid) {
        setError("Invalid details entered, Please try again.");
      }
    }
  };

  return inputAmount && beneficiaryDetails && selectedPaymentMethod ? (
    <BankTransferMethod
      error={error}
      UTR={UTR}
      amount={orderDetails ? orderDetails?.fiat_amount : inputAmount}
      loading={loading}
      beneficiaryDetails={beneficiaryDetails}
      handleTransfer={handleTransfer}
      setUTR={setUTR}
      handleCopy={handleCopy}
      setError={setError}
      bankPaymentMethod={selectedPaymentMethod}
      selectedCurrency={selectedCurrency}
      accountNumber={accountNumber}
      bankName={getBankNameFromIFSC(userBankIFSC)}
      paymentMethodNotes={paymentMethodConfig?.data?.notes}
    />
  ) : (
    <LoadingScreen headerVisible={false} />
  );
};

export default BankTransferContainer;
