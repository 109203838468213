import BaseLayout from "components/shared/BaseLayout";
import KycTextInput from "components/shared/KycTextInput";
import MButton from "components/shared/MButton";
import MHeading from "components/shared/MHeading";
import PanMessage from "components/shared/PanMessage";
import { useOnrampContext } from "context/OnRamp";

const WalletScreen = ({ walletAddress, setWalletAddress, onSubmit }) => {

  const { withdrawNetworkState, cryptoCurrencyState } = useOnrampContext()
  const selectedNetwork = withdrawNetworkState?.selectedNetwork?.value
  const cryptoCurrency = cryptoCurrencyState.value


  const regexError = walletAddress && !String(walletAddress).match(selectedNetwork.addressRegex)
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Buy"}
      headerVisible={true}
      body={
        <div>
          <MHeading heading={"Enter Wallet Address"} />
          <KycTextInput
            style={{
              paddingBottom: 20,
              paddingTop: 20,
            }}
            textStyle={{ fontSize: 16, fontWeight: 600 }}
            text={walletAddress ?? ""}
            setText={setWalletAddress}
            autoCapitalize={false}
            placeholder={"Starts with 0x...."}
            errorText={regexError ? "Invalid Address" : ""}
          />
          {/* <MLink direction="flex-start" text={"What is this?"} /> */}
        </div>
      }
      footer={
        <div>
          <PanMessage
            icon={false}
            text={
              `Ensure that the address supports ${selectedNetwork?.name} and ${cryptoCurrency?.symbol} Transfer`
            }
          />
          <MButton
            disabled={!walletAddress || regexError}
            text={"Proceed to Pay"}
            onPress={onSubmit}
          />
        </div>
      }
    />
  );
};

export default WalletScreen;
