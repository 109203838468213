import { Typography } from "@mui/material";
import upiMethodGooglePay from "assets/images/gpay.png";
import upiMethodPaytm from "assets/images/paytm.png";
import upiMethodPhonePe from "assets/images/phonepe.png";
import upiMethod from "assets/images/upi-method.png";
import { AsyncLinkHandler } from "components/shared/AsyncLinkHandler";
import { FC, useEffect } from "react";
import StateEnum from "../states";

type MobileIntentProps = {
    inputAmount: number
    selectedPaymentApp: string
    url: string
    proceedToSuccess: () => void
}

const payment_icons = (methodName: string) => {
    switch (methodName) {
        case "GOOGLEPAY":
            return upiMethodGooglePay;
        case "PAYTM":
            return upiMethodPaytm;
        case "PHONEPE":
            return upiMethodPhonePe;
        default:
            return upiMethod;
    }
};

export const MobileIntent: FC<MobileIntentProps> = (props) => {

    function postTrigger() {
        setTimeout(() => {
            props.proceedToSuccess()
        }, 1000)
    }

    return <div
        style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            height: "100%",
        }}
    >
        <AsyncLinkHandler url={props.url} postTrigger={postTrigger}/>
        <div
            style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "100%",
            }}
        >
            <div style={{ textAlign: "center" }}>
                <img
                    alt="payment-method"
                    style={{
                        margin: 'auto',
                        height: 35,
                        borderWidth: 1,
                        //   borderColor: colors.Grey.borderGrey,
                        borderRadius: 6,
                    }}
                    src={payment_icons(props.selectedPaymentApp)}
                />
                <Typography
                    sx={{ fontSize: 16 }}
                    style={{ fontWeight: 700, paddingTop: 5 }}
                >
                    Launching App for Payment
                </Typography>
                <Typography
                    sx={{ fontSize: 14 }}
                    style={{ fontWeight: 400, paddingTop: 3, paddingBottom: 3 }}
                >
                    {`Make payment of ${props.inputAmount}`}
                </Typography>
            </div>
        </div>
    </div>
}

export default MobileIntent