import { ClassConstructor, plainToInstance } from "class-transformer";
import { useEffect, useState } from "react";
import networkCallWrapper from "./networkCallWrapper";

export type CallParams<D, P> = {
    url: string
    method: string
    service: string
    params: P
    data: D | null
}

export interface Response<D> {
    refresh: () => void
    errors: Array<Error> | null,
    isFetching: boolean
    data: D | null
    isRevalidating: boolean
}

export interface NetworkState<D> {
    isFetching: boolean,
    data: D | null,
    errors: Array<Error> | null,
    refreshData: number,
    isRevalidating: boolean,
}

export type Error = {
    code: number,
    text: string,
}

function useNetworkRequest<D, P>(
    callParams: CallParams<D, P>,
    dependencyList: any[],
    dependencyCondition: Boolean,
    forceUpdate: Boolean,
    type: ClassConstructor<D>
): Response<D> {

    const [networkState, setNetworkState] = useState<NetworkState<D>>({
        isFetching: true,
        isRevalidating: false,
        data: null,
        errors: null,
        refreshData: 0,
    });

    const refresh = () => {
        setNetworkState((prev) => ({
            ...prev,
            refreshData: prev.refreshData + 1,
            isRevalidating: true,
        }));
    };

    useEffect(() => {
        const func = async () => {
            setNetworkState((prev) => ({
                ...prev,
                isFetching: prev.isRevalidating ? false : true,
            }));
            const url = callParams?.url;
            const method = callParams?.method;
            const service = callParams?.service;
            const params = callParams?.params;
            const data = callParams?.data;

            const request = await networkCallWrapper(
                url,
                method,
                service,
                params,
                data
            );

            if (request.success) {

                const instanceData = plainToInstance(type, request.data, {
                    excludeExtraneousValues: true,
                });
                setNetworkState((prev) => ({
                    ...prev,
                    data: instanceData,
                    isFetching: false,
                    isRevalidating: false,
                }))
            } else {
                setNetworkState((prev) => ({
                    ...prev,
                    errors: request?.errors,
                    isFetching: false,
                    isRevalidating: false,
                }));
            }
        };

        if (networkState.data && !networkState?.isRevalidating && !forceUpdate) {
            // do nothing
        } else {
            if (dependencyCondition) {
                func();
            }
        }
    }, [networkState?.refreshData, ...dependencyList, dependencyCondition]);

    return {
        refresh: refresh,
        errors: networkState.errors,
        isFetching: networkState.isFetching,
        data: networkState.data,
        isRevalidating: networkState.isRevalidating,
    };

}

export default useNetworkRequest;
