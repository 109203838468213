import React, { SVGProps } from "react"
const BuyCoin = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M2.931 12A9.068 9.068 0 0 1 12 2.93a.75.75 0 0 0 0-1.5C6.163 1.431 1.431 6.163 1.431 12c0 5.837 4.732 10.568 10.568 10.568 5.837 0 10.568-4.731 10.568-10.568a.75.75 0 0 0-1.5 0 9.068 9.068 0 1 1-18.136 0Zm3.274 0c0-3.202 2.595-5.796 5.796-5.796a.75.75 0 0 0 0-1.5 7.295 7.295 0 1 0 7.295 7.295.75.75 0 0 0-1.5 0 5.795 5.795 0 0 1-11.59 0ZM18.75 2.25a.75.75 0 0 1 .75.75v1.5H21A.75.75 0 0 1 21 6h-1.5v1.5a.75.75 0 0 1-1.5 0V6h-1.5a.75.75 0 0 1 0-1.5H18V3a.75.75 0 0 1 .75-.75Z"
            clipRule="evenodd"
        />
    </svg>
)
export default BuyCoin
