import axios from "../../utils/axiosInstance";

const networkCallWrapper = async (
  url,
  method,
  service,
  params = null,
  data = null,
  authToken,
  headers = {},
  config = {}
) => {
  try {
    let requestObject = {
      url: url,
      method: method,
      service: service,
      headers: headers,
      ...config,
    };

    if (params) {
      requestObject.params = params;
    }

    if (data) {
      requestObject.data = data;
    }

    if (authToken) {
      requestObject.authToken = authToken;
    }
    const request = await axios(requestObject);

    if (request?.status === 204) {
      return {
        success: true,
      };
    }
    return request.data;
  } catch (err) {
    if (err?.response?.status >= 500) {
      return {
        success: false,
        errors: [
          {
            code: 500,
            text: "Something went wrong! Please try again after some time.",
          },
        ],
      };
    }
    return err?.response?.data;
  }
};

export default networkCallWrapper;
