import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  container: {
    flexDirection: "row",
    width: "100%",
    height: "60%",
    flex: 1,
    justifyContent: "center",
    paddingTop: 30,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-end",
    paddingBottom: 20,
  },
  shield: {
    height: 16,
    width: 16,
  },
  gridItem: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    height: "100%",
    alignContent: "center",
  },
});

export default styles;
