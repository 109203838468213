import { createUseStyles } from "react-jss";
import colors from "../../../styles/colors";

const styles = createUseStyles({
  withdrawFundsContainer: {
    height: "100%",
  },
  withdrawFundsContainer: {
    height: "100%",
    paddingBottom: 32,
  },
  withdrawFundsContainerWithKeyboard: {
    height: "100%",
    paddingBottom: 0,
  },
  headingContainer: {
    paddingHorizontal: 20,
    paddingBottom: 16,
    marginTop: -20,
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    lineHeight: 32,
    color: "#1E1623",
    marginBottom: 4,
  },
  belowHeadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  belowHeading: {
    color: "#7B8793",
    fontSize: 14,
    lineHeight: 18,
    marginRight: 6,
  },
  balance: {
    color: "#1E1623",
    fontSize: 14,
  },
  heading: {
    fontSize: 20,
    color: "#1E1623",
    lineHeight: 32,
  },
  belowHeading: {
    color: "#7B8793",
    fontSize: 13,
    lineHeight: 18,
  },
  balance: {
    color: "#1E1623",
    fontSize: 14,
  },
  enterAmountText: {
    color: "#7B8793",
  },
  currencyContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    borderLeftColor: "#efefef",
    borderLeftWidth: 1,
    height: 48,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 6,
    alignItems: "center",
    paddingHorizontal: 20,
  },
  errorContainer: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  errorIcon: {
    marginTop: 2,
  },
  errorText: {
    marginLeft: 4,
    color: colors.Error.primary,
  },
  inputValue: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  currencyText: {
    fontSize: 14,
    lineHeight: 18,
    color: "#7B8793",
    marginLeft: 8,
    marginRight: 8,
  },
  textInput: {
    height: 48,
    fontSize: 20,
    color: "#1E1623",
    paddingRight: 14,
    paddingLeft: 6,
    flex: 1,
  },
  textInputAndroid: {
    height: 48,
    fontSize: 20,
    color: "#1E1623",
    paddingRight: 14,
    paddingLeft: 6,
    flex: 1,
    paddingTop: 5,
    paddingBottom: 0,
  },
  currencySymbolInput: {
    fontSize: 20,
    color: "#1E1623",
  },
  amountYouGetContainer: {
    display: "flex",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF9EE",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 10,
    height: 30,
  },
  amountYouGetText: {
    marginRight: 7,
    lineHeight: 24,
    fontSize: 13,
    color: "#7B8793",
  },
  amountYouGetTextLoading: {
    marginRight: 0,
    lineHeight: 24,
    fontSize: 13,
    color: "#7B8793",
  },
  amountYouGetTextHighlight: {
    color: "#1E1623",
    fontSize: 16,
  },
  amountShortcutContainer: {
    flex: 1,
    marginHorizontal: 8,
  },
  amountAddShortcutsContainer: {
    flexDirection: "row",
    marginTop: 44,
    width: "100%",
  },
  amountShortcut: {
    borderWidth: 1,
    borderColor: "#efefef",
    borderRadius: 20,
    paddingHorizontal: 2,
    paddingVertical: 8,
  },
  amountShortcutValue: {
    color: "#7B8793",
    fontSize: 14,
    textAlign: "center",
  },
  flag: {
    width: 20,
    height: 20,
    borderRadius: 50,
    overflow: "hidden",
  },
  buttonContainer: {
    paddingHorizontal: 20,
  },
  addShortcutBackground: {
    backgroundColor: "#4B2880",
  },
  changeShortcutTextColor: {
    color: "#FDFDFD",
  },
  disableShortcut: {
    backgroundColor: "#BCBCBC",
  },
  disableShortcutText: {
    color: "#FDFDFD",
  },
  lottieLoaderContainer: {},
  lottieLoader: {
    width: 40,
    height: 22,
  },
  loadingStateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bottomMargin: {
    marginBottom: 30,
  },
  scrollViewContainer: {
    height: "100%",
  },
  container: {
    justifyContent: "space-between",
    height: "100%",
  },
  keyboardOpenContainer: {
    height: "88%",
  },
  noBankAccountAddedContainer: {
    paddingHorizontal: 20,
    marginBottom: 14,
    alignItems: "center",
  },
  noBankAccountAddedText: {
    fontSize: 12,
    color: "#7B8793",
  },
  maxInputCta: {
    fontSize: 14,
    color: "#4B2880",
  },
  bankAccountDetailsContainer: {
    paddingHorizontal: 20,
    marginBottom: 30,
  },
  bankDetailsHeading: {
    fontSize: 14,
    color: "#1E1623",
    paddingBottom: 10,
  },
  bankDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bankAccountIconContainer: {
    marginRight: 16,
  },
  bankDetailsWithKeyboard: {
    paddingHorizontal: 20,
    alignSelf: "center",
    marginBottom: 14,
    color: "#7B8793",
    fontSize: 12,
  },
  noBankAccountHeading: {
    color: "#7B8793",
    fontSize: 12,
  },
  bankNameDetails: {
    color: "#1E1623",
    fontSize: 14,
  },
  textSkeleton: {
    width: 250,
    height: 40,
    borderRadius: 4,
  },
});

export default styles;
