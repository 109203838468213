import { Typography } from "@mui/material";
import { useGetTransactions } from "api/profile/useGetTransactions";
import emptyHistory from "assets/images/empty-history.png";
import { TransactionDetail } from "components/profile/transactions/transaction-detail";
import TransactionListItem from "components/profile/transactions/transaction-list-item";
import BaseLayout from "components/shared/BaseLayout";
import { LoadingView } from "components/shared/LoadingScreen";
import { FC, useState } from "react";
import { Transaction } from "types/transaction";

export const TransactionList: FC = () => {
  const { transactions, isFetching } = useGetTransactions();
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const handleClick = (txn: Transaction | null) => setTransaction(txn);
  const handleClose = () => setTransaction(null);

  const renderBody = () => {
    if (transaction) {
      return (
        <TransactionDetail
          transactionId={transaction.id}
          external={["EXTERNAL_BUY", "ONRAMP_AND_WITHDRAW"].includes(
            transaction.type
          )}
          onClose={handleClose}
        />
      );
    }

    if (isFetching) return <LoadingView />;

    if (transactions?.length) {
      return transactions.map((txn) => (
        <TransactionListItem
          key={txn.id}
          transaction={txn}
          onClick={handleClick}
        />
      ));
    }

    return (
      <div style={emptyStateStyles}>
        <img
          alt="no transactions found"
          src={emptyHistory}
          style={emptyImageStyles}
        />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          No transactions found!
        </Typography>
      </div>
    );
  };

  return (
    <BaseLayout
      headerTitle="Transactions"
      headerVisible
      body={<div id='body' style={{ height: "100%", width: "100%" }}>{renderBody()}</div>}
      footer={undefined}
      bottomSheet={undefined}
    />
  );
};

const emptyStateStyles = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const emptyImageStyles = {
  width: 100,
  height: 100,
  borderRadius: 6,
  borderWidth: 1,
};
