import React from "react";
import MButton from "components/shared/MButton";
import styles from "./styles";
import { Typography } from "@mui/material";
import gs from "styles/styles";
import colors from "styles/colors";
import MLink from "components/shared/Link";

const ConfirmPAN = ({
  onReenterPanClick,
  onUserConsent,
  name,
  panNumber,
  isLoading,
}) => {
  const globalStyles = gs();
  const classes = styles();
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={globalStyles.dialogContainer}>
        <Typography
          variant="h10"
          sx={{ paddingX: 0, paddingY: 0 }}
          className={globalStyles.confirmText}
        >
          {"Please confirm your name shown below"}
        </Typography>
        <div>
          <Typography
            sx={{ paddingY: 1 }}
            className={globalStyles.confirmText}
            style={{ fontSize: 12 }}
          >
            {"Name as per government records for PAN "}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
              marginTop: 5,
            }}
          >
            <Typography
              style={{ color: colors?.Dark?.title, fontSize: 12 }}
              className={classes.confirmText}
            >
              <strong>{name}</strong>
            </Typography>
            <Typography
              className={classes.confirmText}
              style={{ fontSize: 12 }}
            >
              <strong>{panNumber}</strong>
            </Typography>
          </div>
        </div>
      </div>
      <MButton
        onPress={onUserConsent}
        text={"Continue"}
        isPurpleButton
        size="medium"
        loading={isLoading}
      />
      <MLink text="Not your name? Re-enter PAN" onClick={onReenterPanClick} />
    </div>
  );
};

export default ConfirmPAN;
