import { Typography } from "@mui/material";
import { updateOnRampWithdrawTransaction } from "api/onRampHooks";
import MBottomSheet from "components/shared/BottomSheet";
import KycTextInput from "components/shared/KycTextInput";
import MLink from "components/shared/Link";
import MButton from "components/shared/MButton";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import colors from "styles/colors.js";
import { EventLevel, sendEvent } from "utils/events/handler";
import UTRTabs from "../UTRTabView";

const UTRBottomsheet = ({ init, isVisible, onClose, createOrder }) => {
  const [utr, setUtr] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState("utr");
  const toast = useToast();
  const bottomSheetMap = {
    utr: (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 10,
          width: "100%",
        }}
      >
        <Typography fontSize={14}>
          {"Look for Transaction ID/UTR in your transaction confirmation page."}
        </Typography>
        <div style={{ width: "100%" }}>
          <KycTextInput
            placeholder={"Enter your UTR number"}
            text={utr}
            maxLength={50}
            setText={(text) => {
              setUtr(text);
            }}
            autoCapitalize={false}
          // errorText={error}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            width: "100%",
            marginTop: -5,
            marginBottom: 10,
          }}
        >
          <Typography variant="caption" color={colors.Grey.title}>
            It starts with 4 and is of 12 characters: e.g. 4XXXXXXXXXXX
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            width: "100%",
            marginBottom: 15,
          }}
        >
          <MLink
            text={"How to find UTR?"}
            direction="flex-start"
            onClick={() => {
              setCurrentView("findUtr");
            }}
          />
        </div>
        <MButton
          onPress={async () => {
            setLoading(true);
            try {
              let orderId = await createOrder();
              let response = await updateOnRampWithdrawTransaction({
                init,
                orderId: orderId,
                utr: utr,
              });

              sendEvent("ORDER_UPDATED", EventLevel.INFO, { order_id: orderId })

              toast.success("Order updated successfully");
              onClose();
            } catch (e) {
              let error =
                e?.errors?.[0].text ?? "Something went wrong. Please try again";
              toast.error(error);
            }
            setLoading(false);
          }}
          loading={loading}
          text={`Continue`}
          disabled={utr === "" || loading}
        />
      </div>
    ),
    findUtr: (
      <div>
        <div>
          <UTRTabs />
        </div>
        <MButton
          onPress={() => {
            setCurrentView("utr");
          }}
          text={`Got it!`}
        />
      </div>
    ),
  };
  return (
    <MBottomSheet
      isVisible={isVisible}
      close={() => {
        onClose();
        setCurrentView("utr");
      }}
      title={
        currentView === "utr"
          ? "Submit Transaction ID/UTR"
          : "How to find Transaction ID/UTR"
      }
      sticky={false}
    >
      {bottomSheetMap[currentView]}
    </MBottomSheet>
  );
};

export default UTRBottomsheet;
