import { addAccount, addAccountDeprecated, deleteAccount, getAccountStatus } from "api/kycHooks";
import BankDetails from "components/kyc/BankVerification";
import { useInterval } from "hooks/useInterval";
import { useRef, useState } from "react";

const BankDetailsContainer = ({ onStepCompleted, isOldFlow }) => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [account, setAccount] = useState("");
  const [account2, setAccount2] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountInfo, setAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bankErrors, setBankErrors] = useState("");
  const shouldGetBankDetails = useRef(true);
  const [isErrorEditable, setIsErrorEditable] = useState("true");
  const [error, setError] = useState(false);
  const [isBankEditable, setIsBankEditable] = useState(true);
  const [userSubmitted, setUserSubmitted] = useState(false);

  const onError = async () => {
    setError(true);
  };

  const onRetry = async () => {
    setError(false);
  };

  const bankPolling = async () => {
    try {
      const result = await getAccountStatus();
      if (
        result?.is_name_verified &&
        result?.validation_status === "APPROVED"
      ) {
        checkBankProperties(result);
      } else if (
        result?.is_name_verified === false &&
        result?.validation_status !== "DELETED" &&
        result?.validation_status !== "PENDING" &&
        isErrorEditable &&
        userSubmitted
      ) {
        setIsErrorEditable(false);
        setIsBankEditable(true);
        setIsLoading(false);
        setBankErrors(result?.message);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const checkBankProperties = async (result) => {
    if (shouldGetBankDetails.current) {
      shouldGetBankDetails.current = false;
    } else {
      return;
    }

    try {
      const {
        account_number,
        account_holder_name,
        ifsc_code,
        validation_status,
      } = result || {};

      if (!validation_status || validation_status !== "APPROVED") {
        throw "Bank account rejected";
      }

      setShowBottomSheet(true);
      setAccountInfo({
        name: account_holder_name,
        number: account_number,
        ifscCode: ifsc_code,
      });
      setUserSubmitted(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      onError();
      showErrorState(err);
    }
  };

  const onContinueClick = async () => {
    setIsLoading(true);
    setIsBankEditable(false);
    setUserSubmitted(true);
    shouldGetBankDetails.current = true;
    setIsErrorEditable(true);
    try {
      const addAccountFunc = isOldFlow ? addAccountDeprecated : addAccount
      await addAccountFunc(
        {
          action: "add",
          bank_account_details: {
            account_number: account,
            ifsc_code: ifscCode,
            type: "SAVINGS",
          },
        },
      );
    } catch (err) {
      setIsBankEditable(true);
      shouldGetBankDetails.current = false;
      showErrorState(err);
      setIsLoading(false);
    }
  };

  const showErrorState = (err) => {
    const errorObj = err?.response?.data?.errors;
    const errorText = errorObj?.length
      ? errorObj[0]?.text
      : "We are facing some technical issues while verifying your information. Please try again";
    setBankErrors(errorText);
  };

  const resetBankDetails = () => {
    setAccount("");
    setAccount2("");
    setIfscCode("");
  };

  const validateIFSC = () => {
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);

    if (regex.test(ifscCode) === true) {
      return "true";
    } else {
      return "false";
    }
  };

  const onReenterClick = async () => {
    const result = await getAccountStatus();
    const bank_id = result?.uuid;
    const ifsc_code = result?.ifsc_code;
    setIsLoading(true);
    try {
      await deleteAccount({
        action: "delete",
        bank_account_details: {
          bank_id: bank_id,
          ifsc_code: ifsc_code,
        },
      });
      setIsBankEditable(true);
    } catch (err) {
      onError();
    }
    resetBankDetails();
    setIsLoading(false);
    setShowBottomSheet(false);
  };

  const onConfirmClick = async () => {
    onStepCompleted();
  };

  useInterval(bankPolling, 5000);

  return (
    <BankDetails
      onContinueClick={onContinueClick}
      showBottomSheet={showBottomSheet}
      setShowBottomSheet={setShowBottomSheet}
      account={account}
      setAccount={setAccount}
      account2={account2}
      setAccount2={setAccount2}
      ifscCode={ifscCode}
      setIfscCode={setIfscCode}
      onReenterClick={onReenterClick}
      onConfirmClick={onConfirmClick}
      isLoading={isLoading}
      accountInfo={accountInfo}
      bankErrors={bankErrors}
      setBankErrors={setBankErrors}
      onError={onError}
      onRetry={onRetry}
      error={error}
      isBankEditable={isBankEditable}
      validateIFSC={validateIFSC}
    />
  );
};

export default BankDetailsContainer;
