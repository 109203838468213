import networkCallWrapper from "hooks/networking/networkCallWrapperDeprecated";

export const CancelPayment = async (transactionId: string) => {

    const requestData: any = {
        transaction_id: transactionId,
        transaction_type: "ONRAMP_AND_WITHDRAW",
        failure_code: "PAYMENT_CANCELLED",
        failure_desc: "User cancelled the payment"
    }

    const request = await networkCallWrapper(
        `wallet/transaction/cancel`,
        "PUT",
        "wallet",
        null,
        requestData,
        null
    );

    return request;
};