import { Expose } from "class-transformer";

class Crypto {
    @Expose({ name: "crypto_symbol" }) cryptoSymbol: string = "";
    @Expose({ name: "is_recommended" }) isRecommended: number = 0;
}

export class FiatConversionWithCountry {
    @Expose({ name: "fiat_symbol" }) symbol: string = ""; // USD
    @Expose({ name: "cryptos" }) cryptos: Crypto[] = [];
    @Expose({ name: "status" }) status: number = 0;
    @Expose({ name: "countries" }) countries: string[] = [];
    @Expose({ name: "country_name" }) countryName: string = "";
    @Expose({ name: "currency_name" }) currencyName: string = "";
    @Expose({ name: "currency_symbol" }) currencySymbol: string = ""; // $
    @Expose({ name: "default_amount" }) defaultAmount: number = 0;
    @Expose({ name: "max_amount" }) maxAmount: number = 0;
    @Expose({ name: "min_amount" }) minAmount: number = 0;
    @Expose({ name: "recommended_amounts" }) recommendedAmounts: number[] = [];
    iconId?: string;
}

export class Country {
    @Expose({ name: "name" }) name: string = "";
    @Expose({ name: "iso_2" }) iso2: string = "";
    @Expose({ name: "iso_3" }) iso3: string = "";
}
