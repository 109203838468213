import { Alert, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import colors from "../../../styles/colors";

const PanMessage = ({ text, icon = true }) => {
  return (
    <Alert
      style={{ marginBottom: 10, marginTop: 10 }}
      severity="warning"
      icon={
        icon && (
          <InfoOutlinedIcon fontSize="small" sx={{ width: 15, height: 15 }} />
        )
      }
      sx={{ paddingY: 0.1, display: "flex", alignItems: "center" }}
    >
      <Typography variant="alert" color={colors.Grey.title}>
        {text}
      </Typography>
    </Alert>
  );
};

export default PanMessage;
