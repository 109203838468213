import { Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import { NavContext, STEP_KYC } from 'context/NavContext';
import * as React from 'react';
import { useContext } from 'react';
import colors from "styles/colors";
import { User } from 'types/user';
import { sanitised } from 'utils/string';
import { StringAvatar } from './string-avatar';

type ProfileBannerProps = {
    user: User
}

export const ProfileBanner: React.FC<ProfileBannerProps> = (props) => {
    const { user } = props
    const { email, phone } = user
    const { setCurrentStep } = useContext(NavContext)
    const theme = useTheme();

    const onClick = () => {
        if (!user.isKycVerified()) {
            setCurrentStep(STEP_KYC)
        }
    }

    const noUserData = !user || (!user.isKycVerified() && sanitised(user.email) === "" && sanitised(user.phone) === "")

    return (
        <div>
            {noUserData ? <></> : <Stack
                padding={2}
                direction={'row'}
                alignItems={'center'}
                onClick={onClick}
                style={{
                    cursor: user.isKycVerified() ? 'default' : 'pointer'
                }}
            >
                <StringAvatar text={email} />
                <div style={{ margin: 8 }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "400", width: 200 }} noWrap color={colors.Dark}>
                        {email}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: "400", width: 200 }} noWrap color={colors.Grey.title}>
                        {phone}
                    </Typography>
                </div>
            </Stack>}
            {user && user.isKycVerified() ? <Box
                padding={0.5}
                sx={{
                    background: alpha(theme.palette.success.light, 0.1),
                    display: 'flex', justifyContent: 'center'
                }}>
                <Typography textAlign='center' align="center" variant='caption' sx={{ fontSize: "12px", width: "100%" }} color={theme.palette.success.light}>
                    Verified
                </Typography>
            </Box>
                : <Box
                    padding={0.5}
                    sx={{
                        background: 'rgba(255, 195, 124, 0.10)',
                        display: 'flex', justifyContent: 'center'
                    }}>
                    <Typography textAlign='center' align="center" variant='caption' sx={{ fontSize: "12px", width: "100%" }} color='#FFA553'>
                        Verification Pending
                    </Typography>
                </Box>}
        </div >
    );
};