import BottomSheet from "../../shared/BottomSheet";
import React from "react";
import { Typography } from "@mui/material";
import styles from "./styles";
import MButton from "../../shared/MButton";
const ConfirmWithdrawDetailsSheet = ({
  isVisible,
  closeSheet,
  inputAmount,
  finalAmountYouGet,
  selectedCurrency,
  checkWithdrawLimits,
  checkingLimits,
  kycCountry,
  user,
  validatedBankDetails,
}) => {
  const classes = styles();
  return (
    <BottomSheet
      isVisible={isVisible}
      closeOnBackdropPress={closeSheet}
      close={closeSheet}
      onClose={closeSheet}
      title="Confirm withdrawal"
    >
      <div className={classes.sheetContainer}>
        <div className={classes.sheetDetailsContainer}>
          <div className={classes.sheetDetail}>
            <Typography variant="caption">
              <strong>{"Amount to withdraw"}</strong>
            </Typography>
            <br />
            <Typography variant="caption">
              ${inputAmount} ({selectedCurrency?.currency_symbol}
              {finalAmountYouGet})
            </Typography>
          </div>
          <div className={classes.sheetDetail}>
            <Typography variant="caption">
              <strong>
                {kycCountry?.iso_3 === "IND" ? "Account number" : "IBAN"}
              </strong>
            </Typography>
            <br />
            <Typography variant="caption">
              {kycCountry?.iso_3 === "IND"
                ? `${user?.ifsc_code?.slice(0, 4)} ${user?.account_number}`
                : validatedBankDetails?.iban}
            </Typography>
          </div>
          {kycCountry?.iso_3 !== "IND" ? (
            <div className={classes.sheetDetail}>
              <strong>
                <Typography variant="caption">{"Branch"}</Typography>
              </strong>
              <br />
              <Typography variant="caption">
                {validatedBankDetails?.bank}
              </Typography>
            </div>
          ) : null}
        </div>
        <div className={classes.buttonContainer}>
          <div>
            <MButton
              onPress={checkWithdrawLimits}
              text={"Confirm"}
              isPurpleButton
              size="medium"
              loading={checkingLimits}
              disabled={checkingLimits}
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            <MButton
              onPress={closeSheet}
              text={"Cancel"}
              outlineButton={true}
              isPurpleButton
              size="medium"
              loading={checkingLimits}
              disabled={checkingLimits}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ConfirmWithdrawDetailsSheet;
