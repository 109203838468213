import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { getSignature } from 'utils/signatureUtils';
import axios from "../../../utils/axiosInstance";
import { Authenticated, State, Unauthenticated } from "../auth-context";

export type AuthContextType = {
    state: State
    headers: Object
    clientId?: string
}
export const SABER_HMAC_HEADERS = "saber_hmac_headers"

export const HmacIssuerContext = createContext<AuthContextType | undefined>(undefined);

type HmacIssuerContextProviderProps = {
    children: ReactNode
    init: any
}

export const HmacIssuerContextProvider: FC<HmacIssuerContextProviderProps> = ({ children, init }) => {

    const [state, setState] = useState<State>(new Unauthenticated())
    const [headers, setHeaders] = useState({})

    const setHeadersAndCache = (headers: any) => {
        window.sessionStorage.setItem(SABER_HMAC_HEADERS, JSON.stringify(headers))
        setHeaders(headers)
    }

    useEffect(() => {

        const requestInterceptor = axios.interceptors.request.use(async (config) => {

            if (init) {
                const signature = await getSignature(init);
                config.headers["X-Client-Id"] = signature?.clientId;
                config.headers["X-Secret-Key"] = signature?.signature;
                config.headers["X-Timestamp"] = signature?.timestamp;
                config.headers["X-User-Id"] = signature?.userId;

                setHeadersAndCache({
                    "X-Client-Id": signature?.clientId,
                    "X-Secret-Key": signature?.signature,
                    "X-Timestamp": signature?.timestamp,
                    "X-User-Id": signature?.userId,
                })
            } else {
                const storedHeaders = window.sessionStorage.getItem(SABER_HMAC_HEADERS)
                if (storedHeaders && storedHeaders != "") {
                    const headers = JSON.parse(storedHeaders)
                    setHeaders(headers)
                    config.headers = { ...config.headers, ...headers }
                }
            }

            return config
        });

        setState(new Authenticated())

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

    return (
        <HmacIssuerContext.Provider value={{ state, headers }} > {
            state instanceof Authenticated ? children : <></>
        } </HmacIssuerContext.Provider>
    )
};
