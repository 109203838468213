import { Box, Typography, alpha } from '@mui/material';
import IcSearch from 'assets/svgs/search';
import { InsideDrawer } from 'components/shared/Drawer/InsideDrawer';
import KycTextInput from 'components/shared/KycTextInput';
import MHeading from 'components/shared/MHeading';
import { PhoneCountry } from 'constants/country_isd';
import { useOnrampContext } from 'context/OnRamp';
import FuzzySearch from 'fuzzy-search';
import React, { useEffect, useMemo, useState } from 'react';
import colors from 'styles/colors';
import CountryItem from './CountryItem';

type CountryPickerBottomSheetProps = {
    showDrawer: boolean;
    toggleDrawer: (show: boolean) => void;
    selectedCountry: PhoneCountry
    countries: Array<PhoneCountry>
    setCountry: (country: PhoneCountry) => void
};

const CountryPickerBS: React.FC<CountryPickerBottomSheetProps> = (
    { showDrawer, toggleDrawer, countries, selectedCountry, setCountry }
) => {

    const { fiatCurrencyState } = useOnrampContext()
    const selectedCurrencyCountries = fiatCurrencyState.fiatCurrency?.value?.countries

    const countriesByFiatCurrency = useMemo(() => countries.filter((country) => selectedCurrencyCountries?.includes(country.iso3)), [countries, selectedCurrencyCountries])

    const [searchQuery, setSearchQuery] = useState('');
    const searcher = new FuzzySearch(countriesByFiatCurrency, ['name', 'isdcode'], { sort: true });

    const filterResult: Array<PhoneCountry> = searcher.search(searchQuery);

    useEffect(() => {
        if (countriesByFiatCurrency.length > 0) {
            setCountry(countriesByFiatCurrency[0])
        }
    }, [countriesByFiatCurrency])

    const closeDrawer = () => {
        toggleDrawer(false)
    }

    const setSelected = (country: PhoneCountry) => {
        setCountry(country);
        setSearchQuery('');
        closeDrawer();
    }

    return (
        <InsideDrawer
            anchor={"bottom"}
            open={showDrawer}
            onClose={closeDrawer}
            PaperProps={{
                square: false,
                sx: {
                    height: 'calc(100% - 64px)',
                    top: 64,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                },
            }}
        >
            <Box
                component="section"
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        // borderBottom: '1px solid #E0E0E0',
                        zIndex: 1,
                    }}
                >
                    <MHeading heading={"Choose Country"} subHeading={undefined} />
                    {/*@ts-ignore */}
                    <KycTextInput
                        placeholder={""}
                        text={searchQuery}
                        setText={setSearchQuery}
                        leading={<IcSearch width={24} height={24} />}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1
                    }}
                >
                                        <Typography sx={{ paddingLeft: 2, paddingRight: 2 }}>

                        Selected Country
                    </Typography>
                    <CountryItem
                        key={selectedCountry.isdcode}
                        country={selectedCountry}
                        selected={true}
                        setCountry={setSelected}
                    />

                    <div style={{ height: 8, width: "100%", background: alpha(colors.Primary.background, 0.1) }} />
                    <Typography sx={{ marginTop: 2, paddingLeft: 2, paddingRight: 2 }}>
                        Other Country
                    </Typography>
                    {filterResult && filterResult.map((country) => (
                        <CountryItem
                            key={country.iso3}
                            country={country}
                            selected={selectedCountry === country}
                            setCountry={setSelected}
                        />
                    ))}
                </Box>
            </Box>
        </InsideDrawer>
    );
};

export default CountryPickerBS;