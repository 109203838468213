import { createUseStyles } from "react-jss";
import colors from "styles/colors";
import { FontStyles } from "styles/fonts";

const styles = createUseStyles({
  container: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 20,
  },
  screen: {
    flex: 1,
    marginVertical: 30,
  },
  image: {
    height: 100,
    resizeMode: "contain",
    marginBottom: 24,
  },
  title: {
    marginTop: 20,
    ...FontStyles.title16SemiBold,
  },
  subTitle: {
    marginTop: 20,
    ...FontStyles.title14SemiBold,
  },
  text: {
    color: colors.Dark.title,
  },
  detailsContainer: {
    paddingBottom: 20,
    borderRadius: 12,
    marginTop: 12,
    marginBottom: 20,
  },
  detailRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  subtitle: {
    marginLeft: 13,
  },
  errorText: {
    textAlign: "center",
    marginBottom: 10,
    color: colors.Error.primary,
  },
});

export default styles;
