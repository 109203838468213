import { WithAuthentication } from 'context/auth/use-auth';
import React from 'react';
import AuthorizedDigilocker from "pages/digilocker/digilocker";
import AutocloseDigilocker from "pages/digilocker/autoclose-digilocker";

const Digilocker: React.FC = () => {
    return (
        <WithAuthentication
            unauthenticatedChildren={<AutocloseDigilocker />}
        ><AuthorizedDigilocker />
        </WithAuthentication>
    );
};

export default Digilocker;