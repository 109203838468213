import { plainToInstance } from "class-transformer";
import useNetworkRequest, { Response} from "hooks/networking/useNetworkRequest";
import { User } from "types/user";
import axios from "utils/axiosInstance";



export function useGetUser(isAuthenticated: boolean): Response<User> {
    const requestConfig: any = {
        url: 'client_user',
        method: "GET",
        service: "userCaas",
      params: {},
      data: null,
    };
    return useNetworkRequest(requestConfig, [], isAuthenticated, false, User);
  }
  