import { createUseStyles } from "react-jss";
import colors from "../../../styles/colors";

const styles = createUseStyles({
  sheetContainer: {
    paddingTop: 16,
    paddingBottom: 32,
    paddingHorizontal: 20,
    backgroundColor: colors.Background.secondary,
  },
  headerBar: {
    height: 4,
    width: 60,
    borderRadius: 9,
    alignSelf: "center",
    marginBottom: 32,
  },
  sheetHeading: {
    fontSize: 18,
    color: "#1E1623",
    marginBottom: 14,
  },
  sheetDetailsContainer: {
    marginBottom: 12,
  },
  sheetDetail: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  value: {
    fontSize: 14,
    color: "#1E1623",
  },
  title: {
    fontSize: 14,
    color: "#1E1623",
  },
});

export default styles;
