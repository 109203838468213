import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ScrollView = styled(Box)(() => ({
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}));