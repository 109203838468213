const isPanCardValid = (text) => {
  let regex = /^([a-zA-Z]){5}\d{4}([a-zA-Z])$/;

  return regex.test(text);
};

const isTextNull = (value) => {
  return !value;
};

const isValidBankDetails = ({ account, account2, ifscCode }) => {
  return account.length > 8 && account === account2 && ifscCode?.length === 11;
};

const hypervergeErrToIgnoreArr = ["2", "3", "31", "15", "5", "6", "013", "401"];

const isEmpty = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }
  return true;
};

const kycStatusValues = {
  PARTIAL_VERIFICATION: "partial_verification",
  FULL_VERIFICATION: "full_verification",
  BANK_ERROR: "bank_error",
  ERROR: "error",
  LIVE_IMAGE_ERROR: "live_image_error",
};

const base64ToBlob = (image, sliceSize = 512) => {
  const b64Data = image.split(",")[1];
  const contentType = image.split(":")[1].split(";")[0];
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const isEmailValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function syncTimeout(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

function getTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export {
  base64ToBlob, getTimestampInSeconds, hypervergeErrToIgnoreArr, isEmailValid, isEmpty, isPanCardValid,
  isTextNull,
  isValidBankDetails, kycStatusValues, syncTimeout
};

export function isPlatformMobile() {
  var iOS = /iPad|iPhone|iPod/.test(navigator?.userAgent);
  var Android = /Android/.test(navigator?.userAgent);

  return iOS || Android
}