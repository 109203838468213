import React, { useState, useEffect } from "react";
import WithdrawFunds from "components/offRamp/WithdrawFunds";
import {
  fetchCurrenciesList,
  fetchAmountYouGetDetails,
  fetchLimits,
  sellFiat,
  fetchConversionMethods,
  fetchBalance,
} from "api/offRampHooks";
import { getAccountStatus, getUser } from "api/kycHooks";
import LoadingScreen from "components/shared/LoadingScreen";
import KycPending from "pages/KycPending";
import OffRampSuccess from "components/offRamp/OffRampSuccess";

const OffRamp = ({ init, enteredInputAmount, startKYC, onBack, onSuccess }) => {
  const isComingFromIbanFlow = false;
  const validatedBankDetails = null;
  const [showAmountInfoSheet, setShowAmountInfoSheet] = useState(false);
  const [inputAmount, setInputAmount] = useState(enteredInputAmount);
  const [showMinMaxError, setShowMinMaxError] = useState(false);
  const [showCrashError, setShowCrashError] = useState(false);
  const [areConversionDetailsLoading, setAreConversionDetailsLoading] =
    useState(false);
  const [isLoadingMethods, setIsLoadingMethods] = useState(false);
  const [checkingLimits, setCheckingLimits] = useState(false);
  const [conversionDetails, setConversionDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isLimitsBottomSheetVisible, setIsLimitsBottomSheetVisible] =
    useState(false);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [methodTypeName, setMethodTypeName] = useState("");
  const [LimitsError, setLimitsError] = useState({});
  const [currenciesList, setCurrenciesList] = useState(null);
  const [showConfirmWithdrawSheet, setShowConfirmWithdrawSheet] =
    useState(isComingFromIbanFlow);
  const [resendButtonTimer, setResendButtonTimer] = useState(59);
  const [withdrawEmailOtp, setWithdrawEmailOtp] = useState("");
  const [incorrectOtpError, setIncorrectOtpError] = useState("");
  const [methodName, setMethodName] = useState("");
  const [withdrawTfaOtp, setWithdrawTfaOtp] = useState("");
  const [startTimer, setStartTimer] = useState(false);
  const [showWithdrawOtpSheet, setShowWithdrawOtpSheet] = useState(false);
  const [sellRequestError, setSellRequestError] = useState("");
  const [user, setUser] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isKycPending, setIsKycPending] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [failureMessage, setFailureMessage] = useState(null);

  const fetchUserBalance = async () => {
    try {
      const result = await fetchBalance({ init });
      setUserBalance(result?.coin_info?.USDT?.available_balance);
      const user = await getAccountStatus(init);
      setUser(user);
    } catch (err) {}
  };

  const fetchUserKYC = async () => {
    const data = await getUser(init);
    if (data?.kyc_status !== "Verified") {
      setIsKycPending(true);
    }
  };

  useEffect(() => {
    const loadAlldata = async () => {
      await fetchUserBalance();
      await fetchUserKYC();
      setLoading(false);
    };
    loadAlldata();
  }, []);

  useEffect(() => {
    const getCurrenciesList = async () => {
      try {
        const currenciesListRequest = await fetchCurrenciesList({ init: init });
        setCurrenciesList(currenciesListRequest);
        setShowCrashError(false);
        if (currenciesListRequest.data?.length === 0) {
          setShowCrashError(true);
        }
      } catch (e) {
        setShowCrashError(true);
      }
    };

    try {
      getCurrenciesList();
    } catch (err) {
      setShowCrashError(true);
    }
  }, []);

  useEffect(() => {
    setAreConversionDetailsLoading(true);
    const kycCountry = "IND";
    const getDefaultCountry =
      currenciesList?.length &&
      currenciesList.filter(
        (curr) => curr?.countries?.indexOf(kycCountry) !== -1
      );
    setSelectedCurrency(
      getDefaultCountry && getDefaultCountry?.length
        ? getDefaultCountry[0]
        : currenciesList?.length && currenciesList[0]
    );
    setAreConversionDetailsLoading(false);
  }, [currenciesList?.length]);

  useEffect(() => {
    !enteredInputAmount && setInputAmount(selectedCurrency?.default_amount);
  }, [selectedCurrency]);

  useEffect(() => {
    setAreConversionDetailsLoading(true);
    const getAmountYouGetDetails = async () => {
      try {
        const conversionRequest = await fetchAmountYouGetDetails({
          init: init,
          fiatSymbol: selectedCurrency?.symbol,
        });
        setConversionDetails(conversionRequest);
        setAreConversionDetailsLoading(false);
        setShowCrashError(false);
      } catch (e) {
        setAreConversionDetailsLoading(false);
        setShowCrashError(true);
      }
    };

    try {
      if (selectedCurrency?.symbol) {
        getAmountYouGetDetails();
      }
    } catch (err) {
      setShowCrashError(true);
    }
  }, [selectedCurrency?.symbol]);

  useEffect(() => {
    setIsLoadingMethods(true);
    const fetchMethods = async () => {
      const kycCountry = "IND";

      try {
        const methodsRequest = await fetchConversionMethods({
          init: init,
          fiatSymbol: selectedCurrency?.symbol,
          kycCountry: kycCountry,
        });
        setMethodName(
          methodsRequest?.BANK_TRANSFER?.options?.[0]?.display_name
        );
        setMethodTypeName(methodsRequest?.BANK_TRANSFER?.options?.[0]?.name);
        setIsLoadingMethods(false);
      } catch (e) {
        setIsLoadingMethods(false);
      }
    };

    try {
      if (selectedCurrency) {
        fetchMethods();
      }
    } catch (err) {
      setIsLoadingMethods(false);
      setShowCrashError(true);
    }
  }, [selectedCurrency && selectedCurrency?.symbol]);

  const checkWithdrawLimits = async () => {
    setCheckingLimits(true);
    try {
      const request = await fetchLimits({
        init: init,
        inputAmount: inputAmount,
      });
      sendWithdrawOtp();
    } catch (err) {
      setShowConfirmWithdrawSheet(false);
      setIsLimitsBottomSheetVisible(true);
      setWithdrawEmailOtp("");
      setWithdrawTfaOtp("");
      setLimitsError({
        header: "Amount exceeds the per transaction limit",
        body: "",
      });
    }
  };

  const sendWithdrawOtp = async () => {
    setCheckingLimits(false);
    try {
      transactionRequest();
    } catch (e) {
      setShowConfirmWithdrawSheet(false);
      setShowCrashError(true);
      setCheckingLimits(false);
    }
  };

  const resendOtp = async () => {
    try {
      const request = { success: true };

      if (request.success) {
        setCheckingLimits(false);
      }
    } catch (err) {
      setCheckingLimits(false);
    }
  };

  const handleResendOTP = () => {
    setStartTimer(true);
    resendOtp();
  };

  const amountYouWillGet =
    inputAmount > 0
      ? parseFloat((inputAmount / conversionDetails?.base_rate)?.toFixed(2))
      : 0;

  const mudrexFees = parseFloat(
    (amountYouWillGet * conversionDetails?.mudrex_fee_factor)?.toFixed(2)
  );
  const tax = parseFloat(
    (amountYouWillGet * conversionDetails?.tax_factor)?.toFixed(2)
  );

  const finalAmountYouGet = (amountYouWillGet - mudrexFees - tax)?.toFixed(2);

  const transactionRequest = async () => {
    setIsTransactionLoading(true);
    const data = {
      source_id: user?.uuid,
      fiat_symbol: selectedCurrency?.fiat_symbol,
      crypto_symbol: "USDT",
      fiat_amount: finalAmountYouGet,
      crypto_amount: inputAmount,
      payment_method: methodTypeName,
      source_type: "",
    };

    try {
      const txnRequest = await sellFiat({ init: init, data: data });
      if (txnRequest?.success) {
        setIsSuccess(true);
      } else {
        setIsFailed(true);
        setFailureMessage(txnRequest?.errors?.[0]?.text);
      }

      setIsTransactionLoading(false);
      setShowConfirmWithdrawSheet(false);
      setCheckingLimits(false);
      setShowSuccessScreen(true);
    } catch (err) {
      setIsTransactionLoading(false);
      const req = err.response.data;
      setSellRequestError(req?.errors?.[0]?.text);
    }
  };

  return showSuccessScreen ? (
    <OffRampSuccess
      isStatusFailed={isFailed}
      isStatusPending={isSuccess}
      onRetry={() => {
        setShowSuccessScreen(false);
      }}
      message={failureMessage}
      onBack={onBack}
      onSuccess={onSuccess}
    />
  ) : loading ? (
    <LoadingScreen />
  ) : isKycPending ? (
    <KycPending
      onStartKYC={startKYC}
      onExit={onBack}
      showBottomSheet={isKycPending}
    />
  ) : (
    <WithdrawFunds
      inputAmount={inputAmount}
      setInputAmount={setInputAmount}
      showCrashError={showCrashError}
      setShowCrashError={setShowCrashError}
      showMinMaxError={showMinMaxError}
      setShowMinMaxError={setShowMinMaxError}
      showAmountInfoSheet={showAmountInfoSheet}
      setShowAmountInfoSheet={setShowAmountInfoSheet}
      areConversionDetailsLoading={areConversionDetailsLoading}
      setAreConversionDetailsLoading={setAreConversionDetailsLoading}
      conversionDetails={conversionDetails}
      selectedCurrency={selectedCurrency}
      checkingLimits={checkingLimits}
      setCheckingLimits={setCheckingLimits}
      isLimitsBottomSheetVisible={isLimitsBottomSheetVisible}
      setIsLimitsBottomSheetVisible={setIsLimitsBottomSheetVisible}
      LimitsError={LimitsError}
      checkWithdrawLimits={checkWithdrawLimits}
      showWithdrawOtpSheet={showWithdrawOtpSheet}
      setShowWithdrawOtpSheet={setShowWithdrawOtpSheet}
      handleResendOTP={handleResendOTP}
      resendButtonTimer={resendButtonTimer}
      startTimer={startTimer}
      withdrawEmailOtp={withdrawEmailOtp}
      withdrawTfaOtp={withdrawTfaOtp}
      setWithdrawEmailOtp={setWithdrawEmailOtp}
      setWithdrawTfaOtp={setWithdrawTfaOtp}
      showConfirmWithdrawSheet={showConfirmWithdrawSheet}
      setShowConfirmWithdrawSheet={setShowConfirmWithdrawSheet}
      amountYouWillGet={amountYouWillGet}
      mudrexFees={mudrexFees}
      tax={tax}
      finalAmountYouGet={finalAmountYouGet}
      transactionRequest={transactionRequest}
      isTransactionLoading={isTransactionLoading}
      isComingFromIbanFlow={isComingFromIbanFlow}
      validatedBankDetails={validatedBankDetails}
      methodName={methodName}
      kycCountry={{ iso_3: "IND" }}
      user={user}
      incorrectOtpError={incorrectOtpError}
      setIncorrectOtpError={setIncorrectOtpError}
      sellRequestError={sellRequestError}
      setSellRequestError={setSellRequestError}
      isLoadingMethods={isLoadingMethods}
      userBalance={userBalance}
    />
  );
};

export default OffRamp;
