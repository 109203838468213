import networkCallWrapper from "hooks/networking/networkCallWrapper";
import { getPlatform } from "utils/platforms";

export async function PingDigilocker(refId: string, status: string): Promise<boolean> {

    const url = "module/aadhaar/digilocker/update-status"
    const method = "POST"
    const service = "kycv2"

    const platform = getPlatform().toString()
    const deviceId = platform + "-user"

    const request = await networkCallWrapper(
        url,
        method,
        service,
        null,
        {
            reference_id: refId,
            status
        },
        undefined,
        {
            'X-Platform': platform,
            'X-Device-Id': deviceId,
            'X-Source': 'sdk',
        }
    );

    return request.success
}