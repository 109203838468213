import React from "react";
import { Typography } from "@mui/material";
import MButton from "components/shared/MButton";
import styles from "./styles";
import MBottomSheet from "components/shared/BottomSheet";
import { ExceptionHandler } from "utils/errorHandler";
import BaseLayout from "components/shared/BaseLayout";
const ExceptionContainer = ({
  onRetry,
  showBottomSheet,
  errorMessage = "Sorry, something went wrong. Please try again later.",
}) => {
  return (
    <BaseLayout
      theme={"primary"}
      brandingVisible={false}
      headerVisible={true}
      headerTitle={""}
      bottomSheet={
        <MBottomSheet
          isVisible={showBottomSheet}
          closeOnBackdropPress={false}
          dismissable={false}
          dragComponent={
            <div
              style={{
                alignItems: "center",
                paddingBottom: 30,
                paddingTop: 18,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  height: 3,
                  width: 60,
                  borderRadius: 10,
                  backgroundColor: "#D9D9D9",
                }}
              />
            </div>
          }
        >
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            {/* <ErrorIcon height={100} width={100} /> */}
            <Typography style={{ paddingHorizontal: 20, marginTop: 20 }}>
              {errorMessage}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                paddingHorizontal: 40,
              }}
            >
              <div style={{ paddingRight: 5, flex: 1 }}>
                <MButton
                  outlineButton={true}
                  onPress={ExceptionHandler.getOnException()}
                  text={ExceptionHandler.getExceptionCTA()}
                  isPurpleButton
                  size="medium"
                />
              </div>
              <div style={{ paddingLeft: 5, flex: 1 }}>
                <MButton
                  onPress={onRetry}
                  text={"Retry"}
                  isPurpleButton={false}
                  size="medium"
                />
              </div>
            </div>
          </div>
        </MBottomSheet>
      }
    />
  );
};

export default ExceptionContainer;
